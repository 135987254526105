import { css } from 'lit';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {
	CardinalDir,
	CustomMessageDto,
	GraphicDto,
	GroupDto,
	RouteDto,
	SignDto,
} from '../../../typings/api';
import { locationInfoToKey } from '../../config/ConfigDMS';
import { DmsSignFilterTypes } from '../../constants';
import { groupSchema, signSchema } from '../../schemas';
import { RootState } from '../redux-store';
import { ComboBoxOption } from '../../components/common/combo-box';
import { getObjectKeyByValue } from '../../utils/utils';
import { MinMaxTuple } from '../../../typings/shared-types';

export const groupsSelector = (state: RootState): GroupDto[] =>
	denormalize(state.dms.groupIds, [groupSchema], state.dms.entities) as GroupDto[];

export const signsSelector = (state: RootState): SignDto[] =>
	denormalize(state.dms.signIds, [signSchema], state.dms.entities) as SignDto[];

export const selectGroupsToSignDTOs = createSelector(
	[signsSelector, groupsSelector],
	(signs, groups) => {
		const newSigns = [...signs];
		if (!groups) {
			return newSigns;
		}
		// TODO find a cleaner/faster way get signs from groups, and add those groups to signs in state
		// ...it might be that a triple for loop is necessary but hopefully not
		groups.forEach((group) => {
			// eslint-disable-next-line no-unused-expressions
			group.signs?.forEach((signInGroup) => {
				newSigns.forEach((sign, index, signsArr) => {
					if (sign.groups) {
						if (signInGroup.id === sign.id) {
							signsArr[index] = { ...signsArr[index] };
							if (!sign.groups.some((g) => g.id === group.id)) {
								signsArr[index].groups.push(group);
							}
						}
					} else {
						sign.groups = [];
					}
				});
			});
		});

		return newSigns;
	},
);

export const signsFiltered = createSelector(
	({ state }: { state: RootState }) => state.dms.signIds,
	({ state }: { state: RootState }) => state.dms.entities,
	(props: { filterTypes: string[] }) => props.filterTypes,
	(signsIds, entities, filters) => {
		const signs = denormalize(signsIds, [signSchema], entities) as SignDto[];
		if (!filters?.length) {
			return signs;
		}
		const filterFullMatrix = filters.includes(
			getObjectKeyByValue(DmsSignFilterTypes, DmsSignFilterTypes.DMSF) ?? '',
		);
		const filterLineMatrix = filters.includes(DmsSignFilterTypes.DMS);
		const filtersNoDms = filters.filter((filter) => filter !== DmsSignFilterTypes.DMS);

		return signs.filter((sign) => {
			if (filterFullMatrix && sign.type === 'DMS' && sign.fullMatrix) {
				return true;
			}
			if (filterLineMatrix && sign.type === 'DMS' && !sign.fullMatrix) {
				return true;
			}
			return filtersNoDms.includes(sign.type);
		});
	},
);

const routesSelector = (state: RootState): RouteDto[] => state.dms.routes;

export const selectRouteNames = createSelector(routesSelector, (routes) => {
	return routes.map((route) => {
		return { value: route.routeIdentifier };
	}) as ComboBoxOption[] | undefined;
});

export const selectSignRouteMileRanges = (state: RootState, route: string): MinMaxTuple[] =>
	state.dms.routes
		?.find((routeDTO) => routeDTO.routeIdentifier === route)
		?.mileRanges?.map((mileRange) => [mileRange[0], mileRange[1]]);

export const selectRouteByName = (state: RootState, routeName: string): RouteDto =>
	state.dms.routes?.find((route) => route.routeIdentifier === routeName);

export const selectCustomMessageById = (state: RootState, messageID: number): CustomMessageDto =>
	state.dms.customMessages.find((message) => message.id === messageID);

export const selectLaneDirectionsByLocation = (
	state: RootState,
	route: string,
	startMileMarker: number,
	endMileMarker: number,
): Array<CardinalDir> => {
	const locationsDict = state.dms.locationDetailsDict;

	if (locationsDict) {
		const locationDetails = locationsDict[locationInfoToKey(route, startMileMarker, endMileMarker)];

		if (locationDetails) {
			if (
				locationDetails.lanes?.positive.cardinalDir &&
				locationDetails.lanes?.negative.cardinalDir
			) {
				return [
					locationDetails.lanes.positive.cardinalDir as CardinalDir,
					locationDetails.lanes.negative.cardinalDir as CardinalDir,
				];
			}
		}
	}

	return [];
};

export const getGroup = (state: RootState, groupId: number): GroupDto | undefined =>
	denormalize(groupId, groupSchema, state.dms.entities) as GroupDto | undefined;

export const selectIsSignNumberUnique = (state: RootState, num: number): boolean => {
	return Object.values(state.dms.entities.signs).find((sign) => sign.number === num) === undefined;
};

export const selectGraphicById = (state: RootState, graphicId: number): GraphicDto =>
	state.dms.signGraphics.find((graphic) => graphic.id === graphicId);

export const selectRouteExtentByLocation = (
	state: RootState,
	route: string,
	start: number,
	end: number,
): Array<Array<number>> => {
	const { routeExtentDict } = state.dms;

	if (routeExtentDict) {
		const extent = routeExtentDict[locationInfoToKey(route, start, end)];

		return extent
			? extent.map((value: { lat: number; lon: number }) => [value.lat, value.lon])
			: null;
	}

	return null;
};

export const selectSignById = (state: RootState, signId: number): SignDto =>
	state.dms.entities.signs[signId];

export const selectGroupsBySign = (state: RootState, signId: number): Set<GroupDto> =>
	new Set(groupsSelector(state).filter((group) => group.signs.some((sign) => sign.id === signId)));
