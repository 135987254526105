import { css } from 'lit';
import { CardinalDir } from '../typings/api';

export const MobileBreakpoint = 820; //	px

export const LOCALE = 'en-US';

export enum Breakpoint {
	desktop = 'desktop',
	mobile = 'mobile',
}

export enum View {
	map = 'map',
	table = 'table',
}

export const EventPriorityMin = 1;

export const EventPriorityMax = 10;

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = 60 * MILLISECONDS_PER_SECOND;
export const MILLISECONDS_PER_HOUR = 60 * MILLISECONDS_PER_MINUTE;
export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;
export const MILLISECONDS_PER_WEEK = 7 * MILLISECONDS_PER_DAY;

export const DIFFERENT_COLORS = [
	'#03e3fc',
	'#803E75',
	'#fc0362',
	'#A6BDD7',
	'#C10020',
	'#fc03d3',
	'#817066',
	'#007D34',
	'#F6768E',
	'#00538A',
	'#FF7A5C',
	'#53377A',
	'#3003fc',
	'#B32851',
	'#03fc52',
	'#7F180D',
	'#93AA00',
	'#593315',
	'#F13A13',
	'#232C16',
];

export enum IRISxAPIHost {
	'LOCALHOST' = 'http://localhost:3000', //	local json-server for api response mocking
	'DEV' = 'https://chaos.irisx.org/api/',
	'TEST' = 'https://test.irisx.org/api/',
}

export enum RelativeDateTime {
	IMMEDIATELY = 'IMMEDIATELY',
	TOMORROW = 'TOMORROW',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	CUSTOM = 'CUSTOM',
}

export enum RelativeDuration {
	INDEFINITELY = 'INDEFINITELY',
	HOUR = 'HOUR',
	DAY = 'DAY',
	WEEK = 'WEEK',
	CUSTOM = 'CUSTOM',
}

export enum LaneImpact { // TODO: rename to 'LaneImpactLabel' and update references where used
	NA = 'N/A',
	Slow = 'Slow',
	Closed = 'Closed',
}

export const FullCardinalByKey = {
	// irisx-rem-event-affected-lanes-interface
	[CardinalDir.N]: 'NB',
	[CardinalDir.W]: 'WB',
	[CardinalDir.S]: 'SB',
	[CardinalDir.E]: 'EB',
};

export const CardinalNameByKey = {
	[CardinalDir.N]: 'North',
	[CardinalDir.W]: 'West',
	[CardinalDir.S]: 'South',
	[CardinalDir.E]: 'East',
};

export const FullCardinalNameByKey = {
	[CardinalDir.N]: 'Northbound',
	[CardinalDir.W]: 'Westbound',
	[CardinalDir.S]: 'Southbound',
	[CardinalDir.E]: 'Eastbound',
};

export enum Direction {
	POSITIVE = 'positive',
	NEGATIVE = 'negative',
}

export enum REMSectionKey {
	'VEHICLES' = 'VEHICLES',
	'DMS' = 'DMS',
	'RESPONDING_UNITS' = 'RESPONDING_UNITS',
}

export enum CCTVView {
	map = 'map',
	table = 'table',
}

export enum DMSView {
	map = 'map',
	table = 'table',
	group = 'group',
	sign = 'sign',
	'sign-queue' = 'sign-queue',
	'image-library' = 'image-library',
	'custom-messages' = 'custom-messages',
	'custom-message' = 'custom-message',
	'new-sign' = 'new-sign',
}

export enum REMView {
	map = 'map',
	table = 'table',
	event = 'event',
}

export enum HHView {
	table = 'table',
	helper = 'helper',
}

export enum AGGridSort {
	asc = 'asc',
	desc = 'desc',
}

export enum SignsLoaderState {
	fetch = 'fetch',
	groupFetch = 'groupFetch',
	customFetch = 'customFetch',
	groupSave = 'groupSave',
	groupDelete = 'groupDelete',
	queue = 'queue',
}

export enum DmsSignTypes {
	TTS = 'Travel Time',
	DMS = 'DMS Line Matrix',
	TPIMS = 'TPIMS',
	VSL = 'Variable Speed Limit',
	PDMS = 'Portable Message Sign',
}

export enum DmsSignAddlTypes {
	DMSF = 'DMS Full Color Matrix',
}
export const DmsSignFilterTypes = {
	...DmsSignTypes,
	...DmsSignAddlTypes,
};
export type DmsSignFilterTypes = typeof DmsSignFilterTypes;

export enum AppSection {
	LOGIN = 'login',
	CCTV = 'cctv',
	DMS = 'dms',
	REM = 'rem',
	HH = 'highway-helper',
}

export enum ModalSection {
	GROUP_MESSAGE = 'group-message',
	GROUP = 'group',
}

export enum FrameTypes {
	long = 'Long message',
	short = 'Short message',
	graphic = 'Graphic message',
}

export enum Dimensions {
	topbarHeight = 84,
	headerHeight = 74,
}

export const remEventColumns = <const>[
	'type',
	'id',
	'route',
	'milemarker',
	'direction',
	'county',
	'district',
	'dateCreated',
	'respondingUnits',
	'attributes',
	'lastUpdated',
	'schedule',
	'lastUser',
	'lastUpdated',
];
