import { css } from 'lit';
import { APIConfig } from '../config/ConfigIRISx';
import Authorization from '../rest/Authorization';

export enum APIError {
	FetchFailed = 'FetchFailed', //	unable to complete fetch request (general)
	FetchTimeout = 'FetchTimeout', //	fetch took too long to complete (network or server)
	ResponseInvalid = 'ResponseInvalid', //	non-ok response (server)
	ResponseUnparseable = 'ResponseUnparseable', //	unparseable reponse (server)
	ResponseEmpty = 'ResponseEmpty', //	empty reponse (server)
	AuthorizationFailed = 'AuthorizationFailed', //	bad or missing JWT (client or server)
	ServerError = 'ServerError', //	server error in response as JSON (server)
}

export type APIRequestReturn = {
	request?: Request;
	response?: Response;
	abortController?: AbortController;
	apiError?: APIError;
};

export const getAPIHeaders = (): HeadersInit => ({
	'Authorization': Authorization.JWT,
	'Accept': 'application/json',
	'Content-Type': 'application/json',
});

/**
 * @function APIRequest
 * @description performs basic error-handling and cache control configuration that most API interaction requires
 * @param {Request} originalRequest configuration of the request to the API
 * @param {number} timeoutMs how long the request hands before being failed
 * @returns {Promise<Object>}  the request made, the response recieved, any errors encountered, and a reference to the abort controller
 */
export const APIRequest = async (
	originalRequest: Request,
	timeoutMs?: number,
): Promise<APIRequestReturn> => {
	const apiRequestReturn: APIRequestReturn = {};
	let fetchTimeout: number;
	apiRequestReturn.abortController = new AbortController();
	apiRequestReturn.request = new Request(originalRequest, {
		signal: apiRequestReturn.abortController.signal,
	});
	if (!apiRequestReturn.request.headers.has('Cache-Control')) {
		apiRequestReturn.request.headers.set('Cache-Control', 'no-store');
	}
	if (timeoutMs) {
		fetchTimeout = window.setTimeout(() => {
			apiRequestReturn.abortController.abort();
		}, timeoutMs);
	}
	try {
		apiRequestReturn.response = await fetch(apiRequestReturn.request);
		if (timeoutMs && fetchTimeout) {
			clearTimeout(fetchTimeout);
		}
		if (!apiRequestReturn.response.ok) {
			switch (apiRequestReturn.response.status) {
				case 401:
					apiRequestReturn.apiError = APIError.AuthorizationFailed;
					if (process.env.NODE_ENV === 'development') {
						console.error(
							`current user is not authorized to access  "${apiRequestReturn.response.url}"`,
						);
					}
					break;
				default:
					apiRequestReturn.apiError = APIError.ResponseInvalid;
					if (process.env.NODE_ENV === 'development') {
						console.error(
							`error fetching "${apiRequestReturn.response.url}" returned http status ${apiRequestReturn.response.status}`,
						);
					}
					break;
			}
		}
		//	any other pre-response-parsing error handling goes here
	} catch (error) {
		if ((error as Error)?.name === 'AbortError') {
			apiRequestReturn.apiError = APIError.FetchTimeout;
			if (process.env.NODE_ENV === 'development') {
				console.error(
					`error fetching "${apiRequestReturn.request.url}" timed out after ${APIConfig.requestTimeoutMs} miliseconds.`,
				);
			}
		}
		apiRequestReturn.apiError = APIError.FetchFailed;
		if (process.env.NODE_ENV === 'development') {
			console.error(`error fetching "${apiRequestReturn.request.url}": ${error}`);
		}
	}
	return apiRequestReturn;
};
export default APIRequest;
