import { css } from 'lit';
import { DraftEvent, RoadEventDto } from '../../typings/api';
import { APIErrorJSON } from '../../typings/shared-types';
import { isValidNumber, isValidString } from './utils';

export const isLatLngLiteral = (target: unknown): target is L.LatLngLiteral => {
	const { lat, lng } = target as L.LatLngLiteral;
	if (lat && lng) {
		return true;
	}
	return false;
};

export const isLatLngBoundsLiteral = (target: unknown): target is L.LatLngBoundsLiteral => {
	const latLngBoundsLiteral = target as L.LatLngBoundsLiteral;
	if (
		latLngBoundsLiteral?.length === 2 &&
		latLngBoundsLiteral?.[0]?.length === 2 &&
		latLngBoundsLiteral?.[1]?.length === 2
	) {
		return true;
	}
	return false;
};

export const isREMEvent = (target: unknown): target is RoadEventDto => {
	const remEvent = target as RoadEventDto;
	if (
		!!remEvent?.id &&
		!!remEvent?.eventType &&
		!!remEvent?.priorityLevel &&
		!!remEvent?.dateStart
	) {
		return true;
	}
	return false;
};

export const isREMDraftEvent = (target: unknown): target is DraftEvent => {
	const remEvent = target as DraftEvent;
	if (
		//	required: event type, route, and start mile marker
		!!remEvent?.eventType &&
		!!remEvent?.eventSource &&
		!!remEvent?.route &&
		isValidNumber(remEvent?.startMileMarker) &&
		//	and either no end, or differing start and end values
		(!isValidNumber(remEvent?.endMileMarker) ||
			remEvent?.startMileMarker !== remEvent?.endMileMarker)
	) {
		return true;
	}
	return false;
};

export const isAPIErrorJSON = (target: unknown): target is APIErrorJSON => {
	const apiErrorJson = target as APIErrorJSON;
	if (
		isValidString(apiErrorJson.error) &&
		isValidString(apiErrorJson.message) &&
		isValidString(apiErrorJson.path) &&
		isValidString(apiErrorJson.timestamp) &&
		isValidNumber(apiErrorJson.status)
	) {
		return true;
	}
	return false;
};
