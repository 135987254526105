import { css } from 'lit';
import {
	AppSection,
	Breakpoint,
	DMSView,
	IRISxAPIHost,
	MILLISECONDS_PER_SECOND,
} from '../constants';

export const ConfigIRISx = {
	AppTitle: 'IRIS-x', //  this will be used to populate the page title, e.g. 'AppTitle - PageTitle'

	Pages: {
		[AppSection.LOGIN]: {
			route: '/login',
			component: './irisx-login/irisx-login.ts',
			pageTitle: 'Login',
		},
		[AppSection.CCTV]: {
			default: true,
			route: '/cctv', //  pattern matching for the route
			defaultRouteSegments: {
				// if you go to cctv/lobby/ - omitting the view value - the app will default
				// to different paths depending on current layout type:
				view: {
					[Breakpoint.desktop]: 'map',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'CCTV', //  concatted with AppTitle and used as the <title> displayed in the browser
		},
		[AppSection.DMS]: {
			route: '/dms',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'DMS',
		},
		[AppSection.REM]: {
			route: '/rem',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'REM',
		},
		[AppSection.HH]: {
			route: '/highway-helper',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'Highway Helper',
		},
	},

	SuperMenu: {
		Logo: {
			//  the logo is the first item of the left of the menu
			icon: 'logo.png',
			alt: 'Indiana Department of Transportation',
			href: '/cctv', //  clicking the link goes here
		},
		Left: [
			//  these links will run left-to-right next to the logo
			{
				pageID: 'cctv', //  the slug used in the URL
				label: 'CCTV',
				icon: 'img/cctv-topbar-icon.svg',
				href: '/cctv', //  the landing page URL, probably just the slug
			},
			{
				pageID: 'dms',
				label: 'DMS',
				icon: 'img/icon-sign-fill-solid.svg',
				subpages: [
					{
						pageID: 'table',
						label: 'Overview',
						href: `/dms/${DMSView.table}`,
					},
					{
						pageID: 'groups',
						label: 'Groups',
						href: `/dms/${DMSView.group}`,
					},
					{
						pageID: 'messages',
						label: 'Message Library',
						href: `/dms/${DMSView['custom-messages']}`,
					},
					{
						pageID: 'images',
						label: 'Image Gallery',
						href: `/dms/${DMSView['image-library']}`,
					},
					{
						pageID: 'admin',
						label: 'DMS Admin Tools',
						href: `/dms/${DMSView.table}`,
					},
					{
						pageID: 'dms/custom-message/new',
						label: 'New Message',
						href: `/dms/${DMSView['custom-message']}/new`,
					},
				],
			},
			{
				pageID: 'rem',
				label: 'REM',
				icon: 'img/icon-rem-fill-solid.svg',
				href: '/rem',
			},
			{
				pageID: 'highway-helper',
				label: 'Highway Helper',
				icon: 'img/icon-rem-fill-solid.svg',
				href: '/highway-helper',
			},
		],
	},
};
export default ConfigIRISx;

//	https://docs.mapbox.com/help/troubleshooting/migrate-legacy-static-tiles-api/
export const leafletConfig = {
	tileUrlTemplate:
		'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
	tileLayerOptions: {
		id: 'mapbox/streets-v11',
		minZoom: 0,
		maxZoom: 22,
		zoomOffset: -1,
		tileSize: 512,
		accessToken:
			'pk.eyJ1IjoiY3JjLWRldiIsImEiOiJjaWY1b3NwOXcwMmtkc2trdDExOTA3Nmh2In0.eVQGwyWVr-zTFF5oyFU5wA',
		attribution:
			'© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
	},
};

export const tableConfig = {
	paginationSizes: [5, 15, 50, 100],
	defaultPageSize: 15,
};

const APIConfig = {
	relativeAPIPath: `${window.location.origin}/api/`,
	endpointURLBase: '/',
	requestTimeoutMs: 120 * MILLISECONDS_PER_SECOND,
};

if (window.location.hostname === 'chaos.irisx.org') {
	//	workaround for api domain rewriting for chaos server
	APIConfig.endpointURLBase = String('https://de').concat('v.irisx.org/api/');
} else {
	APIConfig.endpointURLBase =
		process.env.NODE_ENV === 'production'
			? //	in production, the site targets the api relative to its webroot
			  APIConfig.relativeAPIPath
			: //	in development, the site targets another instance of the backend somewhere (likely chaos.irisx.org or test.irisx.org)
			  IRISxAPIHost.DEV;
}

export { APIConfig };
