import { css } from 'lit';
import { ColDef } from 'ag-grid-community';
import { CustomMessageDto, RoadEventDto } from '../../typings/api';
import ConfigCCTV from './ConfigCCTV';

import formatTimezoneSup from '../utils/format-timezone-sup';
import { DmsSignTypes, LOCALE } from '../constants';

interface ConfigDMS {
	SignPollingRate: number;
	Map: {
		DefaultLat: number;
		DefaultLon: number;
		DefaultZoom: number;
		MinZoom: number;
		MaxZoom: number;
		SpiderifyDistancePx: number;
		SpiderifyLegThicknessPx: number;
		SpiderifyLegColorNormal: string;
		SpiderifyLegColorFocus: string;
		icons: {
			[key in keyof typeof DmsSignTypes]: {
				message: string;
				noMessage: string;
				offline: string;
				selected?: string;
			};
		};
	};
	Table: {
		Pagination: number[];
	};
	location: Record<any, any>;
}

export const ConfigDMS: ConfigDMS = {
	SignPollingRate: ConfigCCTV.CameraPollingRate, //  refresh camera list every X miliseconds

	Map: {
		//  Leaflet map will center on this point, at the provided zoom level
		//  https://leafletjs.com/reference-1.4.0.html#map-setview
		DefaultLat: ConfigCCTV.Map.DefaultLat,
		DefaultLon: ConfigCCTV.Map.DefaultLon,
		DefaultZoom: ConfigCCTV.Map.DefaultZoom,
		MinZoom: ConfigCCTV.Map.MinZoom,
		MaxZoom: ConfigCCTV.Map.MaxZoom,
		SpiderifyDistancePx: ConfigCCTV.Map.SpiderifyDistancePx,
		SpiderifyLegThicknessPx: ConfigCCTV.Map.SpiderifyLegThicknessPx,
		SpiderifyLegColorNormal: ConfigCCTV.Map.SpiderifyLegColorNormal,
		SpiderifyLegColorFocus: ConfigCCTV.Map.SpiderifyLegColorFocus,
		icons: {
			TTS: {
				message: 'img/icon-travel_time-fill-solid-1.svg',
				noMessage: 'img/icon-travel_time-no_message-fill-solid-1.svg',
				offline: 'img/icon-full-travel-time-no_data-fill-solid-1.svg',
			},
			DMS: {
				message: 'img/icon-dms-fill-solid.svg',
				noMessage: 'img/icon-dms-no_message-fill-solid.svg',
				offline: 'img/icon-dms-no_data-fill-solid.svg',
				selected: 'img/icon-dms-selected.svg',
			},
			TPIMS: {
				message: 'img/icon-travel_time-fill-solid.svg',
				noMessage: 'img/icon-travel_time-no_message-fill-solid.svg',
				offline: 'img/icon-full-color-sign-no_data-fill-solid.svg',
			},
			VSL: {
				message: 'img/icon-speed-fill-solid.svg',
				noMessage: 'img/icon-speed-no_message-fill-solid.svg',
				offline: 'img/icon-speed-no_data-fill-solid.svg',
			},
			PDMS: {
				message: 'img/icon-portable-fill-solid.svg',
				noMessage: 'img/icon-portable-no_message-fill-solid.svg',
				offline: 'img/icon-portable-no_data-fill-solid.svg',
				selected: 'img/icon-portable-selected.svg',
			},
		},
	},
	Table: {
		Pagination: [5, 15, 50, 100],
	},
	location: {
		/*
			<input pattern="" /> regex is slightly different than javascript
			equivalent pattern in javascript: /^(?:\d{1,3}(?:\.\d{1})?)$/gm
		*/
		mileMarkerPattern: '[0-9]{1,3}(?:\\.[0-9]{1})?',
		mileMarkerInvalidPattern: 'Value must be formatted "mmm.t"',
		mileMarkerValueFitted: 'Mile marker entry snapped to nearest valid value.',
		mileMarkerEndValueInvalid: 'Invalid end value removed.',
		mileMarkerMissingRoute: 'Please enter a route before entering mile markers',
	},
};

/* eslint-disable @typescript-eslint/camelcase */
export const ConfigDMSApi = {
	SignsEndpoint: 'sign', //  GET
	SignGraphics: 'sign/graphic', //  GET
	GroupsEndpoint: 'sign/groups',
	RoutesEndpoint: 'locations/route',
	SaveSignEndpoint: 'sign/save/sign',
	SaveGroupEndpoint: 'sign/save/group',
	DeleteGroupEndpoint: (groupIds: number[]): string => `sign/delete/groups/${groupIds.join(',')}`,
	UpdateGroupEndpoint: (groupId: number): string => `sign/update/group/${groupId}`,
	locationDetails: (route_designator: string): string => `locations/route/${route_designator}`,
	postAoisBySignId: (signId: number): string => `sign/save/aoi/${signId}`,
	signQueueById: (signId: number): string => `sign/queue/${signId}`,
	promoteMessageByIds: (signId: number, messageId: number): string =>
		`sign/promote/${signId}/${messageId}`,
	customMessageById: (messageId: number): string => `sign/messages/custom/${messageId}`,
	deleteCustomMessagesByIds: (messageIds: number[]): string =>
		`sign/delete/customs/${messageIds.join(',')}`,
	routeExtent: (route: string, startMileMarker: number, endMileMarker: number): string =>
		`sign/route-extent/${route}/${startMileMarker}/${endMileMarker}`,
	deleteSigns: (signIds: number[]): string => `sign/delete/signs/${signIds.join(',')}`,
	CustomMessagesEndpoint: 'sign/messages/custom',
	CustomMessagesPostEndpoint: 'sign/save/custom',
	signSaveGraphic: 'sign/save/graphic',
	signDeleteGraphic: (graphicId: number): string => `sign/delete/graphic/${graphicId}`,
	addCustomMessageToSigns: (customMessageId: number, signIds: number[]): string =>
		`sign/queue/custom-message/${customMessageId}/${signIds.join(',')}`,
	endpointTimeoutMs: 60000,
	dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
	dateOptions: {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	},
};

export const ConfigDMSTable = {
	pagination: [5, 15, 50, 100],
	defaultPageSize: 15,
	defaultColumns: [
		'id',
		'name',
		'status',
		'queue',
		'route',
		'milemarker',
		'direction',
		'group',
		'type',
		'edit',
		'delete',
	],
};

export const ConfigDMSGraphicTable = {
	pagination: [5, 15, 50, 100],
	defaultPageSize: 15,
	dateCellRenderer: ({ data, colDef }: { data: RoadEventDto; colDef: ColDef }): string => {
		let result = '--';
		try {
			const date = new Date(data[colDef.field]);
			result = formatTimezoneSup(date.toLocaleString(LOCALE, { timeZoneName: 'short' }));
		} catch (error) {
			//	it's okay if data is missing
		}
		return result;
	},
};

export const ConfigDMSCustomMessagesTable = {
	pagination: [5, 15, 50, 100],
	defaultPageSize: 15,
	defaultColumns: [
		'frame1',
		'priority',
		'type',
		'createdDate',
		'lastUsed',
		'createdBy',
		'permanent',
		'actions',
	],
	dateCellRenderer: ({ data, colDef }: { data: RoadEventDto; colDef: ColDef }): string => {
		let result = '--';
		try {
			const date = new Date(data[colDef.field]);
			result = formatTimezoneSup(date.toLocaleString(LOCALE, { timeZoneName: 'short' }));
		} catch (error) {
			//	it's okay if data is missing
		}
		return result;
	},
};

export enum MessageIconPath {
	CUSTOM = 'img/icon-custom-message.svg',
}

export const MessageIcons = {
	'CUSTOM MESSAGE': MessageIconPath.CUSTOM,
};

export const locationInfoToKey = (route: string, start: number, end: number): string => {
	if (end) {
		return `${route}${start}${end}`;
	}

	return `${route}${start}`;
};

export interface DMSSelectionUpdate {
	message: CustomMessageDto;
	start: number;
	end: number;
	id: number;
}
