import { css } from 'lit';
import { CSSResultArray, LitElement, PropertyValues, TemplateResult, html } from 'lit';
import { customElement, state as iState, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin';
import store, { RootState } from '../../redux/redux-store';
import scss from '../../utils/scssAsCss';
import BButtonBase from '../css/lib/bbuttonbase.scss';
import CSSReset from '../css/lib/reset.scss';
import SharedStyles from '../css/shared.scss';
import irisxRemEventFormStyles from '../irisx-rem/irisx-rem-event-form-styles';
import { Banner, BannerLocation, NotificationErrorType } from '../../../typings/shared-types';
import { UiState, getBanners, hideNotificationBanner } from '../../redux/redux-ui';

@customElement('notification-banner')
export default class IrisixNotificationBanner extends connect(store)(LitElement) {
	@property({ type: Object }) private banners: UiState['banners'];

	@iState() private errorContent: TemplateResult;

	@iState() private successContent: TemplateResult;

	@iState() private warningContent: TemplateResult;

	@property({ type: Array }) private timeouts: { id: number; timeout: number }[] = [];

	public static override get styles(): CSSResultArray {
		return [
			CSSReset,
			BButtonBase,
			SharedStyles,
			irisxRemEventFormStyles,
			css`
:host {
  background-color: white;
}

#notification-banners, #notification-banners div.ERROR, #notification-banners div.WARNING, #notification-banners div.SUCCESS {
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1.25rem;
  box-sizing: border-box;
  padding: 0;
}
#notification-banners.ERROR, #notification-banners div.ERROR.ERROR, #notification-banners div.WARNING.ERROR, #notification-banners div.SUCCESS.ERROR {
  border: 1px solid rgb(var(--brand-status-danger));
  background-color: rgb(var(--brand-status-danger-background));
  color: rgb(var(--brand-status-danger));
  padding: 1.5rem;
}
#notification-banners.WARNING, #notification-banners div.ERROR.WARNING, #notification-banners div.WARNING.WARNING, #notification-banners div.SUCCESS.WARNING {
  border: 1px solid rgb(var(--brand-status-caution));
  background-color: rgb(var(--brand-status-caution-background));
  padding: 1.5rem;
}
#notification-banners.SUCCESS, #notification-banners div.ERROR.SUCCESS, #notification-banners div.WARNING.SUCCESS, #notification-banners div.SUCCESS.SUCCESS {
  border: 1px solid rgb(var(--brand-status-good));
  background-color: rgb(var(--brand-status-good-background));
  color: rgb(var(--brand-status-good));
  padding: 1.5rem;
}
#notification-banners h3, #notification-banners div.ERROR h3, #notification-banners div.WARNING h3, #notification-banners div.SUCCESS h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
#notification-banners ul, #notification-banners div.ERROR ul, #notification-banners div.WARNING ul, #notification-banners div.SUCCESS ul {
  list-style-type: disc;
  list-style-position: inside;
}
#notification-banners a, #notification-banners div.ERROR a, #notification-banners div.WARNING a, #notification-banners div.SUCCESS a {
  font-weight: bold;
  text-decoration: underline;
}
#notification-banners .message-area div, #notification-banners div.ERROR .message-area div, #notification-banners div.WARNING .message-area div, #notification-banners div.SUCCESS .message-area div {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
}
#notification-banners .message-area div button, #notification-banners div.ERROR .message-area div button, #notification-banners div.WARNING .message-area div button, #notification-banners div.SUCCESS .message-area div button {
  flex-basis: 35px;
  padding: 2px 0 0 0;
  width: 16px;
  height: 16px;
}
#notification-banners .message-area div .right, #notification-banners div.ERROR .message-area div .right, #notification-banners div.WARNING .message-area div .right, #notification-banners div.SUCCESS .message-area div .right {
  margin-left: auto;
}
#notification-banners .message-area div .close-icon, #notification-banners div.ERROR .message-area div .close-icon, #notification-banners div.WARNING .message-area div .close-icon, #notification-banners div.SUCCESS .message-area div .close-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: rgb(var(--brand-grey));
}
#notification-banners .message-area div span, #notification-banners div.ERROR .message-area div span, #notification-banners div.WARNING .message-area div span, #notification-banners div.SUCCESS .message-area div span {
  flex-basis: auto;
}
`,
		];
	}

	public render(): TemplateResult {
		return html`
			<div id="notification-banners">
				${this.errorContent
					? html`<div class="ERROR">
							<div class="message-area">${this.errorContent}</div>
					  </div>`
					: html``}
				${this.successContent
					? html`<div class="SUCCESS">
							<div class="message-area">${this.successContent}</div>
					  </div>`
					: html``}
				${this.warningContent
					? html`<div class="WARNING">
							<div class="message-area">${this.warningContent}</div>
					  </div>`
					: html``}
			</div>
		`;
	}

	public override stateChanged(state: RootState): void {
		this.banners = getBanners(state, BannerLocation.MAIN);
	}

	protected override updated(props: PropertyValues): void {
		if (props.has('banners')) {
			this.processBanners(NotificationErrorType.ERROR, this.banners);
			this.processBanners(NotificationErrorType.SUCCESS, this.banners);
			this.processBanners(NotificationErrorType.WARNING, this.banners);
		}
	}

	private onNotificationTimeout(id: number): void {
		store.dispatch(hideNotificationBanner(id));
	}

	private processBanners(type: string, banners: UiState['banners']): void {
		let templateResults: TemplateResult;
		if (banners[type].length) {
			templateResults = banners[type].map((notification: Banner) => {
				const idIndex = this.timeouts.findIndex((obj) => obj.id === notification.id);
				if (notification.timeout && idIndex === -1) {
					const itemTimeout = window.setTimeout(
						this.onNotificationTimeout,
						notification.timeout,
						notification.id,
					);
					this.timeouts.push({ id: notification.id, timeout: itemTimeout });
				}
				return html` <div id="${notification.id}">
					<span>
						<h3>${notification.messaging.title}</h3>
						${Array.isArray(notification.messaging.messages)
							? html`
									<ul>
										${notification.messaging.messages.map((message) => html`<li>${message}</li>`)}
									</ul>
							  `
							: ``}
					</span>
					<button class="bbuttonbase right" @click=${this.removeNotification}>
						<svg class="close-icon" role="presentation">
							<use href="img/sprites.svg#close-icon"></use>
						</svg>
					</button>
				</div>`;
			});
		}
		switch (type) {
			case NotificationErrorType.ERROR:
				this.errorContent = templateResults;
				break;

			case NotificationErrorType.SUCCESS:
				this.successContent = templateResults;
				break;

			case NotificationErrorType.WARNING:
				this.warningContent = templateResults;
				break;

			default:
				if (process.env.NODE_ENV === 'development') {
					console.warn(`notification-banner: unknown notification type "${type}"`);
				}
		}
	}

	private removeNotification(e: MouseEvent): void {
		const notificationID = parseInt((e.currentTarget as HTMLButtonElement).parentElement.id, 10);
		const idIndex = this.timeouts.findIndex((obj) => obj.id === notificationID);
		if (idIndex !== -1) {
			clearTimeout(this.timeouts[idIndex].timeout);
		}

		store.dispatch(hideNotificationBanner(notificationID));
	}
}
