import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { AuthenticationDto, AuthorityDto } from '../../../typings/api';
import { APIConfig } from '../../config/ConfigIRISx';
import { ConfigLoginApi } from '../../config/ConfigLogin';
import Authorization from '../../rest/Authorization';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { ThunkActionRoot } from '../redux-store';

export enum UserActionType {
	GET_USER = 'GET_USER',
	SET_USER = 'SET_USER',
	CLEAR_USER = 'CLEAR_USER',
	GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS',
	SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS',
}

export type UserState = {
	name?: string;
	authority?: AuthorityDto;
};

export const USER_STATE_INITIAL: UserState = {
	name: undefined,
	authority: undefined,
};

interface GetUser extends Action<typeof UserActionType.GET_USER> {
	name: string;
	password: string;
}

interface SetUser extends Action<typeof UserActionType.SET_USER> {
	name: string;
}

type ClearUser = Action<typeof UserActionType.CLEAR_USER>;

type GetUserPermissions = Action<typeof UserActionType.GET_USER_PERMISSIONS>;

interface SetUserPermissions extends Action<typeof UserActionType.SET_USER_PERMISSIONS> {
	authority: AuthorityDto;
}

export type UserAction = GetUser | SetUser | ClearUser | GetUserPermissions | SetUserPermissions;

export const getUserPermissions = (): ThunkActionRoot<Promise<APIRequestReturn>> => async (
	dispatch,
): Promise<APIRequestReturn> => {
	dispatch({
		type: UserActionType.GET_USER_PERMISSIONS,
	});
	const apiRequestReturn = await APIRequest(
		new Request(new URL(ConfigLoginApi.getUserPermissions(), APIConfig.endpointURLBase).href, {
			method: HTTPMethod.GET,
			headers: new Headers({ ...getAPIHeaders() }),
		}),
	);
	try {
		const authority: AuthorityDto = await apiRequestReturn.response?.json();
		dispatch({
			type: UserActionType.SET_USER_PERMISSIONS,
			authority,
		});
	} catch (error) {
		apiRequestReturn.apiError = APIError.ResponseUnparseable;
		if (process.env.NODE_ENV === 'development') {
			console.error(`error parsing user permissions:`, error);
		}
	}
	return apiRequestReturn;
};

export const setUser = (name: string): SetUser => ({
	type: UserActionType.SET_USER,
	name,
});

export const clearUser = (): ClearUser => ({
	type: UserActionType.CLEAR_USER,
});

export const getUser = (
	name: string,
	password: string,
): ThunkActionRoot<Promise<APIRequestReturn>> => async (dispatch): Promise<APIRequestReturn> => {
	dispatch({
		type: UserActionType.GET_USER,
	});
	const apiRequestReturn = await APIRequest(
		new Request(new URL(ConfigLoginApi.loginUser(), APIConfig.endpointURLBase).href, {
			method: HTTPMethod.POST,
			headers: new Headers({ ...getAPIHeaders() }),
			body: JSON.stringify({
				name,
				password,
			}),
		}),
	);
	try {
		const loginResult: AuthenticationDto = await apiRequestReturn.response?.json();
		if (loginResult.accessToken === null) {
			apiRequestReturn.apiError = APIError.AuthorizationFailed;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error authenticating user "${name}" using data provided`);
			}
		} else {
			Authorization.JWT = `${loginResult.tokenType} ${loginResult.accessToken}`;
			dispatch(setUser(name));
			dispatch(getUserPermissions());
		}
	} catch (error) {
		apiRequestReturn.apiError = APIError.ResponseUnparseable;
		if (process.env.NODE_ENV === 'development') {
			console.error(`error parsing server response while logging in "${name}":`, error);
		}
	}
	return apiRequestReturn;
};

export const UserReducer = (
	state: UserState = USER_STATE_INITIAL,
	action: UserAction,
): UserState => {
	switch (action.type) {
		case UserActionType.SET_USER:
			return {
				...state,
				name: action.name,
			};
		case UserActionType.SET_USER_PERMISSIONS:
			return {
				...state,
				authority: action.authority,
			};
		case UserActionType.GET_USER_PERMISSIONS:
		case UserActionType.GET_USER:
		case UserActionType.CLEAR_USER:
		//	intentional fallthrough for unhandled action
		default:
			return state;
	}
};
