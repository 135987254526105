import { css } from 'lit';
import { CSSResultArray, LitElement, TemplateResult, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import bind from 'bind-decorator';
import { customElement, property, query } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin';
import ConfigIRISx from '../../config/ConfigIRISx';
import './notification-banner';
import scss from '../../utils/scssAsCss';
import bbuttonbaseScss from '../css/lib/bbuttonbase.scss';
import sharedScss from '../css/shared.scss';
import resetScss from '../css/lib/reset.scss';
import store, { ResponsiveIBrowser, RootState } from '../../redux/redux-store';
import { AppSection, Breakpoint, MobileBreakpoint } from '../../constants';
import containsTargetPenetrate from '../../utils/contains-penetrate';
import { navigate } from '../../redux/redux-routing';
import { selectShowReadOnly } from '../../redux/rem/rem-selectors';
import { UserPermissions } from '../../../typings/api';

/**
 * @returns {boolean} whether the menu hide operation should be blocked
 */
function menuHideBlocker(): boolean {
	return window.innerWidth < MobileBreakpoint;
}

@customElement('irisx-page-nav')
export default class extends connect(store)(LitElement) {
	@property({ type: String, reflect: true }) public page?: AppSection;

	@property({ type: Boolean }) private dmsDetailsOpen = false;

	@property({ type: String }) private mediaType?: ResponsiveIBrowser['mediaType'];

	@property({ type: Boolean }) private showReadOnly?: boolean;

	@query('#menu-container') private menuContainer?: HTMLDetailsElement;

	@query('#dmsDropdown') private dmsDetails: HTMLDetailsElement;

	@property({ type: Object }) private permissions?: UserPermissions[];

	public static override get styles(): CSSResultArray {
		return [
			resetScss,
			bbuttonbaseScss,
			sharedScss,
			css`
notification-banner {
  position: fixed;
  top: var(--topbar-height);
  width: 100%;
  z-index: 1025;
}

#topbar {
  width: 100vw;
  height: 84px;
  position: fixed;
  z-index: 1024;
  top: 0px;
  left: 0px;
  font-weight: lighter;
  color: white;
  background-color: rgb(var(--brand-primary));
}
@media (max-width: 819px) {
  #topbar {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 70px;
  }
}
#page-title {
  flex: 1 1 100%;
  width: 100%;
  line-height: 70px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: rgb(var(--brand-secondary));
  display: none;
}
@media (max-width: 819px) {
  #page-title {
    display: block;
  }
}

#logo-link {
  height: 100%;
  float: left;
}
@media (max-width: 819px) {
  #logo-link {
    flex: 0 0 auto;
    float: none;
  }
}
#logo-link a {
  display: block;
  padding: calc(var(--ggutter) / 2) calc(var(--ggutter) * 2);
}
@media (max-width: 819px) {
  #logo-link a {
    padding: 12px;
  }
}
#logo-link a img {
  object-fit: fill;
}
@media (max-width: 819px) {
  #logo-link a img {
    width: 48px;
    height: 48px;
  }
}

#menu-container {
  width: 100%;
}
@media (max-width: 819px) {
  #menu-container {
    flex: 0 0 auto;
    width: auto;
    float: none;
  }
}
#menu-container > summary {
  position: absolute;
  z-index: 1050;
  top: 18px;
  right: 18px;
  display: none;
  width: 36px;
  height: 36px;
  background: url("/img/icon-hamburger-fill.svg") no-repeat center center;
  background-size: 36px;
}
@media (max-width: 819px) {
  #menu-container > summary {
    display: block;
    position: static;
    padding: 0px 18px;
    height: 100%;
  }
}
#menu-container > summary > span {
  display: none;
}
#menu-container[open] > summary {
  background-image: url("/img/icon-hamburger-fill - gold.svg");
}
#menu-container > .details-content {
  z-index: 1050;
  height: inherit;
}
#menu-container > .details-content ul {
  height: 84px;
}
#menu-container > .details-content ul li {
  height: 100%;
}
#menu-container > .details-content ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0px var(--ggutter);
  text-decoration: inherit;
  color: inherit;
}
#menu-container > .details-content ul li a > div {
  flex: 0 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-top: var(--ggutter);
  border-radius: 50%;
  background-color: rgb(var(--brand-grey-dim));
}
#menu-container > .details-content ul li a > div img {
  display: block;
}
#menu-container > .details-content ul li a span {
  flex: 0 1 100%;
  margin-top: 4px;
}
#menu-container > .details-content ul li.active a {
  background-color: rgb(var(--brand-primary-dark));
  color: rgb(var(--brand-secondary));
}
#menu-container > .details-content ul li.active a > div {
  background-color: rgb(var(--brand-secondary));
}
#menu-container > .details-content ul.left-links {
  float: left;
}
#menu-container > .details-content ul.left-links li {
  float: left;
  margin-left: var(--ggutter);
}
#menu-container > .details-content ul.right-links {
  float: right;
}
#menu-container > .details-content ul.right-links li {
  float: right;
  margin-right: var(--ggutter);
}
#signout-link {
  position: relative;
  margin-right: calc(var(--ggutter) * 2);
}
#signout-link > div {
  background-color: rgb(var(--brand-primary-dark)) !important;
  border-radius: 50% !important;
  padding: 0px !important;
}
#signout-link > span {
  color: rgb(var(--brand-secondary));
}

#read-only-tag {
  display: block;
  width: var(--topbar-height);
  transform: rotate(90deg) translateX(-50%) translateX(-1rem) translateY(-1rem);
  position: absolute;
  right: 0;
  text-transform: uppercase;
  font-size: 0.7em;
  text-align: center;
  color: rgb(var(--brand-primary));
  background: rgb(var(--brand-secondary));
}
@media (max-width: 819px) {
  #read-only-tag {
    display: none;
  }
}

@media (max-width: 819px) {
  #menu-container > .details-content {
    position: absolute;
    z-index: 10;
    top: 70px;
    left: 0px;
    width: 100%;
    background-color: rgb(var(--brand-primary));
  }
  #menu-container > .details-content ul {
    height: auto;
  }
  #menu-container > .details-content ul.left-links, #menu-container > .details-content ul.right-links {
    float: none;
    clear: both;
  }
  #menu-container > .details-content ul.left-links li, #menu-container > .details-content ul.right-links li {
    display: block;
    width: 100%;
    float: none;
    clear: both;
    margin: 0px;
    text-align: unset;
  }
  #menu-container > .details-content ul.left-links li a, #menu-container > .details-content ul.right-links li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px var(--ggutter) var(--ggutter);
  }
  #menu-container > .details-content ul.left-links li a > div > img, #menu-container > .details-content ul.right-links li a > div > img {
    display: inline-block;
  }
  #menu-container > .details-content ul.left-links li a span, #menu-container > .details-content ul.right-links li a span {
    margin-top: 10px;
    display: inline-block;
    text-indent: 1em;
  }
}
.left-links > li {
  cursor: pointer;
}
.left-links > li * {
  pointer-events: none;
}
.left-links > li img {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}
.left-links > li p {
  display: inline-block;
  vertical-align: middle;
}

.rotate {
  transform: rotate(90deg);
}

.dropdownLinks {
  display: none;
  flex-direction: column;
  position: absolute;
  align-items: start;
  border: 1px solid rgb(var(--brand-grey-dimmer));
  background-color: white;
  box-shadow: 0px 5px 5px 2px rgb(var(--brand-grey-dim));
  max-width: 18ch;
  z-index: 1025;
}

.dropdownLinks[open] {
  display: flex;
}
.dropdownLinks[open] * {
  pointer-events: all;
}

.dropdownItem {
  color: black;
  margin: 0rem !important;
  width: 100%;
}
.dropdownItem a {
  height: 100%;
  display: block;
  padding: calc(var(--ggutter) / 2) var(--ggutter) !important;
  align-items: flex-start;
  background-color: white !important;
  color: black !important;
}
.dropdownItem a[active] * {
  pointer-events: none;
}

.dropdownItem:last-child {
  border-top: 2px solid rgb(var(--brand-grey));
}

.dropdownItem > a > span {
  align-self: flex-start;
}
`,
		];
	}

	@bind
	private renderMenuItemToTemplate(
		item: ArrayElement<typeof ConfigIRISx.SuperMenu.Left>,
	): TemplateResult {
		return html`
			<li
				class=${classMap({
					active: this.page === item.pageID,
				})}
				@click=${this.onIconClick}
				data-pageID="${item.pageID}"
				data-href="${item.href}"
			>
				<a title="${item.label}">
					<div><img src="${item.icon}" alt="${item.label}" width="24" height="24" /></div>
					<span>
						<p>${item.label}</p>
						${item.pageID === 'dms' && this.page === item.pageID
							? html`<img
									class=${classMap({ rotate: this.dmsDetailsOpen })}
									src="img/arrow-big-right-yellow.svg"
							  />`
							: html``}
						${item.pageID === 'dms' && this.page !== item.pageID
							? html`<img
									class=${classMap({ rotate: this.dmsDetailsOpen })}
									src="img/arrow-big-right-grey.svg"
							  />`
							: html``}
					</span>
				</a>
				${item.pageID === 'dms'
					? html` <details
							?open=${this.dmsDetailsOpen}
							id="${item.pageID}Dropdown"
							class="dropdownLinks"
					  >
							${item.subpages
								? item.subpages.map((page) => {
										if (
											page.pageID === 'groups' &&
											this.permissions.includes(UserPermissions.DMS_CAN_MANAGE_SIGN_GROUPS) ===
												false
										) {
											return html`<!-- user does not have permission to manage sign groups -->`;
										}
										if (
											page.pageID === 'images' &&
											this.permissions.includes(UserPermissions.DMS_CAN_MANAGE_CUSTOM_LIBRARY) ===
												false
										) {
											return html`<!-- user does not have permission to manage custom message library -->`;
										}
										if (
											page.pageID === 'messages' &&
											this.permissions.includes(UserPermissions.DMS_CAN_MANAGE_CUSTOM_LIBRARY) ===
												false
										) {
											return html`<!-- user does not have permission to manage custom message library -->`;
										}

										return html`
											<li class="dropdownItem">
												<a ?active=${this.dmsDetailsOpen} href="${page.href}">
													<span>${page.label}</span>
												</a>
											</li>
										`;
								  })
								: ''}
					  </details>`
					: html``}
			</li>
		`;
	}

	private onIconClick(e: Event): void {
		const targetElement = e.currentTarget as HTMLAnchorElement;
		const pageID = targetElement.dataset.pageid;
		const { href } = targetElement.dataset.href ? targetElement.dataset : targetElement;

		if (pageID !== 'dms' && targetElement.tagName !== 'A') {
			store.dispatch(navigate(href));
		} else {
			this.dmsDetailsOpen = !this.dmsDetailsOpen;
		}
	}

	public render(): TemplateResult {
		return html`
			<header id="topbar">
				<h1 id="logo-link">
					<a href="${ConfigIRISx.SuperMenu.Logo.href}" title="Home"
						><img
							src="${ConfigIRISx.SuperMenu.Logo.icon}"
							alt="${ConfigIRISx.SuperMenu.Logo.alt}"
							width="64"
							height="64"
					/></a>
				</h1>
				<h2 id="page-title">${ConfigIRISx.Pages[this.page].pageTitle}</h2>
				<details
					id="menu-container"
					class="details-mod"
					?open=${this.mediaType === Breakpoint.desktop}
				>
					<summary><span>Menu</span></summary>
					<nav class="details-content">
						<ul class="left-links">
							${ConfigIRISx.SuperMenu.Left.map(this.renderMenuItemToTemplate)}
						</ul>
						<ul class="right-links">
							<li>
								<a href="/logout" title="Sign Out" id="signout-link">
									<div>
										<img
											src="img/icon-profile-fill-solid.svg"
											alt="Sign Out"
											width="36"
											height="36"
										/>
									</div>
									<span>Sign Out</span>
								</a>
								${this.showReadOnly ? html`<span id="read-only-tag">View Only</span>` : undefined}
							</li>
						</ul>
					</nav>
				</details>
			</header>
			<notification-banner> </notification-banner>
		`;
	}

	public override stateChanged(state: RootState): void {
		this.page = state.routing.page;
		this.mediaType = state.responsive.mediaType;
		this.showReadOnly = selectShowReadOnly(state);
		this.permissions = state.user.authority?.permissions;
	}

	@bind
	private onGlobalMouseup(e: MouseEvent): void {
		if (
			menuHideBlocker() &&
			this.menuContainer.open === true &&
			!containsTargetPenetrate(this.menuContainer, e)
		) {
			this.menuContainer.open = false;
			this.menuContainer.blur();
		}

		if (this.dmsDetailsOpen === true && !containsTargetPenetrate(this.menuContainer, e)) {
			this.dmsDetailsOpen = false;
			this.dmsDetails.blur();
		}
	}

	public override connectedCallback(): void {
		window.addEventListener('mouseup', this.onGlobalMouseup);
		super.connectedCallback();
	}

	public override disconnectedCallback(): void {
		window.removeEventListener('mouseup', this.onGlobalMouseup);
		super.disconnectedCallback();
	}
}
