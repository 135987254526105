import { css } from 'lit';
import { ColDef, ColumnState } from 'ag-grid-community';
import {
	CurrentEditorsDto,
	DraftEvent,
	EmailGroupsRecipientsDto,
	EventMessageDto,
	PreviewDto,
	RoadEventDto,
	RoadEventFieldDto,
	RoadEventTimelineDto,
	RouteDto,
	SignDto,
} from '../../../typings/api';
import { AGGridFilterModel } from '../../../typings/shared-types';
import { ConfigREMTable } from '../../config/ConfigREM';
import { REMSectionKey } from '../../constants';
import { APIError } from '../APIRequest';
import { REMAPIAction } from './rem-actions';

export type REMState = {
	events?: RoadEventDto[];
	eventsLastPolled?: number;
	eventFields?: RoadEventFieldDto;
	emailGroupsRecipients?: EmailGroupsRecipientsDto;
	routeFilterParams?: {
		county?: string;
		district?: string;
	};
	filteredRoutes?: RouteDto[];
	eventDMSPreviewMessages?: Record<number, PreviewDto[] | null>;
	eventDMSSignLoadingIds: number[];
	draftEvent?: DraftEvent;
	mileMarkersValidForRoute?: boolean;
	readOnlyMode?: boolean;
	isMobileSearchMenuVisible?: boolean;
	unsavedDraftEvent: boolean;
	currentEditors?: CurrentEditorsDto;
	eventTimeline?: RoadEventTimelineDto;
	errors?: {
		[key in REMAPIAction]?: {
			[key in APIError]?: boolean;
		};
	};
	table?: {
		columns?: ColDef['colId'][];
		search?: string;
		columnState?: ColumnState[];
		filterState?: AGGridFilterModel;
	};
	formSections: {
		[key in REMSectionKey]: boolean;
	};
	currentSign?: SignDto;
	currentSignQueue?: EventMessageDto[];
};
export default REMState;

export const REM_STATE_INITIAL: REMState = {
	events: undefined,
	eventFields: undefined,
	routeFilterParams: undefined,
	filteredRoutes: undefined,
	eventDMSPreviewMessages: undefined,
	eventDMSSignLoadingIds: [],
	draftEvent: undefined,
	readOnlyMode: false,
	isMobileSearchMenuVisible: false,
	unsavedDraftEvent: false,
	currentEditors: undefined,
	mileMarkersValidForRoute: undefined,
	errors: {},
	table: {
		columns: ConfigREMTable.defaultColumns,
		columnState: undefined,
		filterState: undefined,
	},
	formSections: {
		[REMSectionKey.DMS]: false,
		[REMSectionKey.RESPONDING_UNITS]: false,
		[REMSectionKey.VEHICLES]: false,
	},
	currentSign: undefined,
	currentSignQueue: undefined,
};
