import { css } from 'lit';
const ConfigLogin = {
	Logo: {
		icon: 'logo.png',
		alt: 'Indiana Department of Transportation',
		href: '/', //  clicking the link goes here
	},
};

export const ConfigLoginApi = {
	loginUser: (): string => `auth/signin`,
	getUserPermissions: (): string => `auth`,
};

export default ConfigLogin;
