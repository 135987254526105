import { css } from 'lit';
import { ColumnState } from 'ag-grid-community';
import { Action } from 'redux';
import HTTPMethod from 'http-method-enum';
import { normalize } from 'normalizr';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import {
	AreaOfInfluenceDto,
	CustomMessageDto,
	GraphicDto,
	GroupDto,
	LocationDto,
	MessageDto,
	RouteDto,
	SignDto,
	SignDtoNew,
	SignQueueDto,
} from '../../../typings/api';
import { ThunkActionRoot } from '../redux-store';
import { ConfigDMS, ConfigDMSApi } from '../../config/ConfigDMS';
import ConfigIRISx, { APIConfig } from '../../config/ConfigIRISx';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import { AppSection, DMSView, SignsLoaderState } from '../../constants';
import { showMainBanner } from '../redux-ui';
import { groupSchema, signSchema } from '../../schemas';
import { AGGridFilterModel, LatLon, NotificationErrorType } from '../../../typings/shared-types';
import { groupsSelector } from './dms-selectors';
import { DMSState, DmsEntities } from './dms-state';

export enum DMSApiError {
	GetSigns = 'GetSigns',
}

//	ACTION TYPES

export enum DMSActionType {
	SET_SIGNS_ERROR_STATE = 'SET_SIGNS_ERROR_STATE',
	SET_GROUPS_ERROR_STATE = 'SET_GROUPS_ERROR_STATE',
	SAVE_SIGN_ERROR_STATE = 'SAVE_SIGN_ERROR_STATE',

	GET_SIGNS = 'GET_SIGNS',
	SET_SIGNS = 'SET_SIGNS',
	DELETE_SIGNS = 'DELETE_SIGNS',

	FILTER_TYPES = 'FILTER_TYPES',

	GET_GROUPS = 'GET_GROUPS',
	SET_GROUPS = 'SET_GROUPS',

	SET_DMS_TABLE_COLUMNS = 'SET_DMS_TABLE_COLUMNS',
	SET_DMS_TABLE_SEARCH = 'SET_DMS_TABLE_SEARCH',
	SET_DMS_TABLE_COLUMN_STATE = 'SET_DMS_TABLE_COLUMN_STATE',
	SET_DMS_TABLE_FILTER_STATE = 'SET_DMS_TABLE_FILTER_STATE',

	GET_SIGN_QUEUE = 'GET_SIGN_QUEUE',
	SET_SIGN_QUEUE = 'SET_SIGN_QUEUE',

	GET_SIGN_GRAPHICS = 'GET_SIGN_GRAPHICS',
	SET_SIGN_GRAPHICS = 'SET_SIGN_GRAPHICS',

	SET_SIGN_GRAPHICS_TABLE_SEARCH = 'SET_SIGN_GRAPHICS_TABLE_SEARCH',
	SET_SIGN_GRAPHICS_TABLE_COLUMN_STATE = 'SET_SIGN_GRAPHICS_TABLE_COLUMN_STATE',
	SET_SIGN_GRAPHICS_TABLE_FILTER_STATE = 'SET_SIGN_GRAPHICS_TABLE_FILTER_STATE',

	SET_UPLOAD_GRAPHICS_TABLE_SEARCH = 'SET_UPLOAD_GRAPHICS_TABLE_SEARCH',

	DELETE_CUSTOM_MESSAGE = 'DELETE_CUSTOM_MESSAGE',
	SAVE_CUSTOM_MESSAGE = 'SAVE_CUSTOM_MESSAGE',
	SET_CUSTOM_MESSAGE = 'SET_CUSTOM_MESSAGE',
	GET_CUSTOM_MESSAGES = 'GET_CUSTOM_MESSAGES',
	SET_CUSTOM_MESSAGES = 'SET_CUSTOM_MESSAGES',

	SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMNS = 'SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMNS',
	SET_DMS_CUSTOM_MESSAGE_TABLE_SEARCH = 'SET_DMS_CUSTOM_MESSAGE_TABLE_SEARCH',
	SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMN_STATE = 'SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMN_STATE',
	SET_DMS_CUSTOM_MESSAGE_TABLE_FILTER_STATE = 'SET_DMS_CUSTOM_MESSAGE_TABLE_FILTER_STATE',

	ADD_CUSTOM_MESSAGE_TO_GROUP = 'SAVE_CUSTOM_MESSAGE_TO_GROUP',

	SET_DMS_UPLOAD_CUSTOM_MESSAGES_TABLE_SEARCH = 'SET_DMS_UPLOAD_CUSTOM_MESSAGES_TABLE_SEARCH',
	ADD_CUSTOM_MESSAGE_TO_SIGNS = 'ADD_CUSTOM_MESSAGE_TO_SIGNS',

	PROMOTE_QUEUE_MESSAGE = 'PROMOTE_QUEUE_MESSAGE',
	SET_SIGN_ACTIVE_QUEUE = 'SET_SIGN_ACTIVE_QUEUE',
	UPLOAD_DMS_GRAPHIC = 'UPLOAD_DMS_GRAPHIC',
	DELETE_DMS_GRAPHIC = 'DELETE_DMS_GRAPHIC',

	GET_ROUTES = 'GET_ROUTES',
	SET_ROUTES = 'SET_ROUTES',

	SAVE_SIGN = 'SAVE_SIGN',
	SAVE_AOIS_TO_SIGN = 'SAVE_AOIS_TO_SIGN',
	SAVE_SIGN_TO_GROUPS = 'SAVE_SIGN_TO_GROUPS',

	SAVE_GROUP = 'SAVE_GROUP',
	DELETE_GROUPS = 'DELETE_GROUPS',

	GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS',
	SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS',

	GET_ROUTE_EXTENT = 'GET_ROUTE_EXTENT',
	SET_ROUTE_EXTENT = 'SET_ROUTE_EXTENT',
}

interface SetErrorState extends Action<typeof DMSActionType.SET_SIGNS_ERROR_STATE> {
	apiError?: APIError;
}

interface SetGroupsErrorState extends Action<typeof DMSActionType.SET_GROUPS_ERROR_STATE> {
	apiError?: APIError;
}

type GetSigns = Action<DMSActionType.GET_SIGNS>;

interface SetSigns extends Action<DMSActionType.SET_SIGNS> {
	signIds?: number[];
	entities?: DmsEntities;
}

interface SetSignsFilterTypes extends Action<DMSActionType.FILTER_TYPES> {
	filterTypes: string[];
}

type GetGroups = Action<DMSActionType.GET_GROUPS>;

interface SetGroups extends Action<DMSActionType.SET_GROUPS> {
	groupIds?: number[];
	entities?: DmsEntities;
}

interface DeleteGroups extends Action<DMSActionType.DELETE_GROUPS> {
	groupIds: number[];
}
type GetSignGraphics = Action<DMSActionType.GET_SIGN_GRAPHICS>;

interface SetSignGraphics extends Action<DMSActionType.SET_SIGN_GRAPHICS> {
	signGraphics: GraphicDto[];
}

type GetCustomMessages = Action<DMSActionType.GET_CUSTOM_MESSAGES>;

interface SetCustomMessages extends Action<DMSActionType.SET_CUSTOM_MESSAGES> {
	customMessages: CustomMessageDto[];
}
interface SetDMSTableColumns extends Action<typeof DMSActionType.SET_DMS_TABLE_COLUMNS> {
	columns: string[];
}

interface SetDMSTableSearch extends Action<typeof DMSActionType.SET_DMS_TABLE_SEARCH> {
	search: string;
}

interface SetDMSTableColumnState extends Action<typeof DMSActionType.SET_DMS_TABLE_COLUMN_STATE> {
	state: ColumnState[];
}

interface SetDMSTableFilterState extends Action<typeof DMSActionType.SET_DMS_TABLE_FILTER_STATE> {
	state: AGGridFilterModel;
}
interface SetDMSGraphicsTableSearch
	extends Action<typeof DMSActionType.SET_SIGN_GRAPHICS_TABLE_SEARCH> {
	search: string;
}

interface SetDMSGraphicsTableColumnState
	extends Action<typeof DMSActionType.SET_SIGN_GRAPHICS_TABLE_COLUMN_STATE> {
	state: ColumnState[];
}

interface SetDMSGraphicsTableFilterState
	extends Action<typeof DMSActionType.SET_SIGN_GRAPHICS_TABLE_FILTER_STATE> {
	state: AGGridFilterModel;
}
interface SetDmsCustomMessageTableColumns
	extends Action<typeof DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMNS> {
	columns: string[];
}

interface SetDmsCustomMessageTableSearch
	extends Action<typeof DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_SEARCH> {
	search: string;
}

interface SetDMSCustomMessageTableColumnState
	extends Action<typeof DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMN_STATE> {
	state: ColumnState[];
}

interface SetDMSCustomMessageTableFilterState
	extends Action<typeof DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_FILTER_STATE> {
	state: AGGridFilterModel;
}
interface SetSignQueue extends Action<typeof DMSActionType.SET_SIGN_QUEUE> {
	signQueue: SignQueueDto;
}

interface SetSignActiveQueue extends Action<typeof DMSActionType.SET_SIGN_ACTIVE_QUEUE> {
	activeQueue: MessageDto[];
}
interface GetSignQueue extends Action<typeof DMSActionType.GET_SIGN_QUEUE> {
	signId: number;
}

interface PromoteQueueMessage extends Action<typeof DMSActionType.PROMOTE_QUEUE_MESSAGE> {
	signId: number;
	messageId: number;
}

interface SaveCustomMessage extends Action<typeof DMSActionType.SAVE_CUSTOM_MESSAGE> {
	message: CustomMessageDto;
}

type GetRoutes = Action<typeof DMSActionType.GET_ROUTES>;

interface SetRoutes extends Action<typeof DMSActionType.SET_ROUTES> {
	routes: RouteDto[];
}

interface SaveSign extends Action<typeof DMSActionType.SAVE_SIGN> {
	sign: SignDto;
}

interface SaveGroup extends Action<typeof DMSActionType.SAVE_GROUP> {
	groupId?: number;
	entities?: DmsEntities;
}

interface SaveAoisToSign extends Action<typeof DMSActionType.SAVE_AOIS_TO_SIGN> {
	sign: SignDto;
	aois: AreaOfInfluenceDto[];
}

interface SaveSignToGroups extends Action<typeof DMSActionType.SAVE_SIGN_TO_GROUPS> {
	sign: SignDto;
	groups: GroupDto[];
}
interface SetCustomMessage extends Action<DMSActionType.SET_CUSTOM_MESSAGE> {
	customMessage: CustomMessageDto;
}

/* eslint-disable camelcase */
interface SetLocationDetails extends Action<DMSActionType.SET_LOCATION_DETAILS> {
	locationDetails: LocationDto;
	route_designator: string;
	miles_along_route: number;
	end_miles: number;
}
/* eslint-enable camelcase */
/* eslint-disable camelcase */
interface GetLocationDetails extends Action<DMSActionType.GET_LOCATION_DETAILS> {
	route_designator: string;
	miles_along_route: number;
	end_miles: number;
}
/* eslint-enable camelcase */
interface DeleteCustomMessage extends Action<DMSActionType.DELETE_CUSTOM_MESSAGE> {
	messageIds: Array<number>;
}

interface SetUploadGraphicsTableSearch
	extends Action<typeof DMSActionType.SET_UPLOAD_GRAPHICS_TABLE_SEARCH> {
	search: DMSState['uploadGraphicsTable']['search'];
}
interface GetRouteExtent extends Action<DMSActionType.GET_ROUTE_EXTENT> {
	route: string;
	startMileMarker: number;
	endMileMarker: number;
}
interface SetRouteExtent extends Action<DMSActionType.SET_ROUTE_EXTENT> {
	route: string;
	startMileMarker: number;
	endMileMarker: number;
	coords: Array<LatLon>;
}

interface DeleteSigns extends Action<DMSActionType.DELETE_SIGNS> {
	signIds: number[];
}

interface SetDMSUploadCustomMessagesTableSearch
	extends Action<DMSActionType.SET_DMS_UPLOAD_CUSTOM_MESSAGES_TABLE_SEARCH> {
	search: string;
}

interface AddCustomMessageToSigns extends Action<DMSActionType.ADD_CUSTOM_MESSAGE_TO_SIGNS> {
	customMessageId: number;
	signIds: number[];
	startTime: number;
	endTime: number;
}

interface AddCustomMessageToGroup extends Action<DMSActionType.ADD_CUSTOM_MESSAGE_TO_GROUP> {
	customMessageId: number;
	start: number;
	end: number;
	groupId: number;
}

export type DMSAction =
	| SetErrorState
	| SetGroupsErrorState
	| GetSigns
	| SetSigns
	| GetGroups
	| SetGroups
	| DeleteGroups
	| GetCustomMessages
	| SetCustomMessages
	| SetDMSTableColumns
	| SetSignsFilterTypes
	| SetDMSTableSearch
	| SetDMSTableColumnState
	| SetDMSTableFilterState
	| GetSignGraphics
	| SetSignGraphics
	| SetDMSGraphicsTableSearch
	| SetDMSGraphicsTableColumnState
	| SetDMSGraphicsTableFilterState
	| SetSignQueue
	| GetSignQueue
	| SetDmsCustomMessageTableColumns
	| SetDmsCustomMessageTableSearch
	| SetDMSCustomMessageTableColumnState
	| SetDMSCustomMessageTableFilterState
	| SetSignActiveQueue
	| PromoteQueueMessage
	| SaveCustomMessage
	| GetRoutes
	| SetRoutes
	| SaveSign
	| SaveGroup
	| SetCustomMessage
	| SetLocationDetails
	| GetLocationDetails
	| DeleteCustomMessage
	| SetUploadGraphicsTableSearch
	| GetRouteExtent
	| SetRouteExtent
	| SaveAoisToSign
	| SaveSignToGroups
	| DeleteSigns
	| SetDMSUploadCustomMessagesTableSearch
	| AddCustomMessageToSigns
	| AddCustomMessageToGroup;

//	ACTIONS
export const setCustomMessage = (customMessage: CustomMessageDto): SetCustomMessage => ({
	type: DMSActionType.SET_CUSTOM_MESSAGE,
	customMessage,
});

export const setDMSTableColumns = (columns: SetDMSTableColumns['columns']): SetDMSTableColumns => ({
	type: DMSActionType.SET_DMS_TABLE_COLUMNS,
	columns,
});

export const setDMSTableSearch = (search: SetDMSTableSearch['search']): SetDMSTableSearch => ({
	type: DMSActionType.SET_DMS_TABLE_SEARCH,
	search,
});

export const setDMSTableColumnState = (
	state: SetDMSTableColumnState['state'],
): SetDMSTableColumnState => ({
	type: DMSActionType.SET_DMS_TABLE_COLUMN_STATE,
	state,
});

export const setDMSTableFilterState = (state: AGGridFilterModel): SetDMSTableFilterState => ({
	type: DMSActionType.SET_DMS_TABLE_FILTER_STATE,
	state,
});

export const setDMSGraphicsTableSearch = (
	search: SetDMSGraphicsTableSearch['search'],
): SetDMSGraphicsTableSearch => ({
	type: DMSActionType.SET_SIGN_GRAPHICS_TABLE_SEARCH,
	search,
});

export const setDMSGraphicsTableColumnState = (
	state: SetDMSGraphicsTableColumnState['state'],
): SetDMSGraphicsTableColumnState => ({
	type: DMSActionType.SET_SIGN_GRAPHICS_TABLE_COLUMN_STATE,
	state,
});

export const setDMSGraphicsTableFilterState = (
	state: AGGridFilterModel,
): SetDMSGraphicsTableFilterState => ({
	type: DMSActionType.SET_SIGN_GRAPHICS_TABLE_FILTER_STATE,
	state,
});

export const setUploadGraphicsTableSearch = (search: string): SetUploadGraphicsTableSearch => ({
	type: DMSActionType.SET_UPLOAD_GRAPHICS_TABLE_SEARCH,
	search,
});

export const setDmsCustomMessageTableColumns = (
	columns: SetDmsCustomMessageTableColumns['columns'],
): SetDmsCustomMessageTableColumns => ({
	type: DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMNS,
	columns,
});

export const setDmsCustomMessageTableSearch = (
	search: SetDmsCustomMessageTableSearch['search'],
): SetDmsCustomMessageTableSearch => ({
	type: DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_SEARCH,
	search,
});

export const setDMSCustomMessageTableColumnState = (
	state: SetDMSCustomMessageTableColumnState['state'],
): SetDMSCustomMessageTableColumnState => ({
	type: DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_COLUMN_STATE,
	state,
});

export const setDMSCustomMessageTableFilterState = (
	state: AGGridFilterModel,
): SetDMSCustomMessageTableFilterState => ({
	type: DMSActionType.SET_DMS_CUSTOM_MESSAGE_TABLE_FILTER_STATE,
	state,
});

export const setDMSUploadCustomMessagesTableSearch = (
	search: SetDMSUploadCustomMessagesTableSearch['search'],
): SetDMSUploadCustomMessagesTableSearch => ({
	type: DMSActionType.SET_DMS_UPLOAD_CUSTOM_MESSAGES_TABLE_SEARCH,
	search,
});

export const setSignsFilters = (filterTypes: string[]): SetSignsFilterTypes => {
	return {
		type: DMSActionType.FILTER_TYPES,
		filterTypes,
	};
};

export const getSigns =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_SIGNS });

		const url = new URL(ConfigDMSApi.SignsEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.fetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const signs = await apiRequestReturn.response.json();
				const normalized = normalize(signs, [signSchema]);
				dispatch({
					type: DMSActionType.SET_SIGNS,
					signIds: normalized.result,
					entities: normalized.entities,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const getSignGraphics =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_SIGN_GRAPHICS });
		const url = new URL(ConfigDMSApi.SignGraphics, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		try {
			const signGraphics: GraphicDto[] = await apiRequestReturn.response?.json();
			dispatch({ type: DMSActionType.SET_SIGN_GRAPHICS, signGraphics });
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error(`unable to parse response from dms graphics api:`, error);
			}
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
		}
	};

export const getCustomMessages =
	(customIds?: Array<number>): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_CUSTOM_MESSAGES });
		const url = new URL(ConfigDMSApi.CustomMessagesEndpoint, APIConfig.endpointURLBase);
		if (customIds) {
			url.searchParams.append('custom-ids', customIds.toString());
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.customFetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages.login.route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const customMessages = await apiRequestReturn.response.json();

				if (customIds) {
					dispatch({ type: DMSActionType.SET_CUSTOM_MESSAGE, customMessage: customMessages[0] });
				} else {
					dispatch({ type: DMSActionType.SET_CUSTOM_MESSAGES, customMessages });
				}
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const getGroups =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_GROUPS });

		const url = new URL(ConfigDMSApi.GroupsEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		dispatch(removeItem('signs', SignsLoaderState.groupFetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_GROUPS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const groups = await apiRequestReturn.response.json();
				const normalized = normalize<GroupDto, Record<number, GroupDto>, number>(groups, [
					groupSchema,
				]);
				dispatch({
					type: DMSActionType.SET_GROUPS,
					groupIds: normalized.result,
					entities: normalized.entities,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_GROUPS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_GROUPS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const uploadDMSGraphic =
	(graphicDto: GraphicDto): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.UPLOAD_DMS_GRAPHIC });
		dispatch(addItem('image-uploader', 'upload-dms-graphic'));
		const url = new URL(ConfigDMSApi.signSaveGraphic, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(graphicDto),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages.login.route));
		} else if (apiRequestReturn.response?.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: `Upload Successful: "${graphicDto.title}" added to library of DMS graphics.`,
					},
					5000,
				),
			);
		}
		await dispatch(getSignGraphics());
		dispatch(removeItem('image-uploader', 'upload-dms-graphic'));
		return apiRequestReturn;
	};

export const deleteDMSGraphic =
	(graphicId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('image-uploader', 'delete-dms-graphic'));
		dispatch({ type: DMSActionType.DELETE_DMS_GRAPHIC });
		const apiRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigDMSApi.signDeleteGraphic(graphicId), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.DELETE,
					headers: new Headers({
						...getAPIHeaders(),
					}),
				},
			),
			ConfigDMSApi.endpointTimeoutMs,
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages.login.route));
		} else if (apiRequestReturn.response?.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: 'DMS Graphic Deleted Successfully',
					},
					5000,
				),
			);
		}
		await dispatch(getSignGraphics());
		dispatch(removeItem('image-uploader', 'delete-dms-graphic'));
		return apiRequestReturn;
	};

export const getSignQueue =
	(signId: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_SIGN_QUEUE });

		const url = new URL(ConfigDMSApi.signQueueById(signId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.queue));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const signQueue = await apiRequestReturn.response.json();
				dispatch({ type: DMSActionType.SET_SIGN_QUEUE, signQueue });
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const promoteSignMessage =
	(signId: number, messageId: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.PROMOTE_QUEUE_MESSAGE });

		const url = new URL(
			ConfigDMSApi.promoteMessageByIds(signId, messageId),
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages.login.route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const activeQueue = await apiRequestReturn.response.json();
				dispatch({ type: DMSActionType.SET_SIGN_ACTIVE_QUEUE, activeQueue });
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const saveCustomMessage =
	(message: CustomMessageDto): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.SAVE_CUSTOM_MESSAGE });

		const url = new URL(ConfigDMSApi.CustomMessagesPostEndpoint, APIConfig.endpointURLBase);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(message),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response.ok) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Custom message saved successfully!' },
					5000,
				),
			);
			dispatch(navigate(`${ConfigIRISx.Pages.dms.route}/${DMSView['custom-messages']}`));
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Error saving custom message: ${apiRequestReturn.response.text}` },
					5000,
				),
			);
		}
		dispatch(addItem('signs', SignsLoaderState.customFetch));
		dispatch(getCustomMessages());

		return apiRequestReturn;
	};

export const getRoutes =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_ROUTES });

		const url = new URL(ConfigDMSApi.RoutesEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const routes = await apiRequestReturn.response.json();
				dispatch({ type: DMSActionType.SET_ROUTES, routes });
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const saveAoiToSign =
	(sign: SignDto, aois: AreaOfInfluenceDto[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.SAVE_AOIS_TO_SIGN });

		const url = new URL(ConfigDMSApi.postAoisBySignId(sign.id), APIConfig.endpointURLBase);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(aois),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		return apiRequestReturn;
	};

export const saveSignToGroup =
	(sign: SignDto, group: GroupDto): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.SAVE_AOIS_TO_SIGN });

		const url = new URL(ConfigDMSApi.SaveGroupEndpoint, APIConfig.endpointURLBase);

		const newGroup: GroupDto = {
			id: group.id,
			name: group.name,
			signs: [...(group.signs ?? []), sign],
		};

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newGroup),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		return apiRequestReturn;
	};

export const saveSign =
	(
		sign: SignDtoNew,
		aois: AreaOfInfluenceDto[],
		groups: GroupDto[],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.SAVE_SIGN });

		const url = new URL(ConfigDMSApi.SaveSignEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(sign),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		const savedSign: SignDto = await apiRequestReturn.response?.json();

		if (apiRequestReturn.response.ok) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: `${savedSign.name} saved successfully!` },
					5000,
				),
			);
			dispatch(navigate(`${ConfigIRISx.Pages.dms.route}/${DMSView.table}`));
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Error saving ${savedSign.name}: ${apiRequestReturn.apiError}` },
					5000,
				),
			);
		}

		if (aois.length > 0) {
			const aoiResult = await dispatch(saveAoiToSign(savedSign, aois));

			if (!aoiResult.response?.ok) {
				dispatch(
					showMainBanner(
						NotificationErrorType.ERROR,
						{ title: `Error saving areas of influence to ${sign.name}` },
						5000,
					),
				);
			}
		}

		if (groups.length > 0) {
			groups.map((group) => dispatch(saveSignToGroup(savedSign, group)));
		}

		await dispatch(getSigns());

		return apiRequestReturn;
	};

export const saveGroup =
	(
		name: string,
		signIds: number[],
		id?: number | null,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('signs', SignsLoaderState.groupSave));

		const groupReq = { name, signIds };

		const url = new URL(
			id ? ConfigDMSApi.UpdateGroupEndpoint(id) : ConfigDMSApi.SaveGroupEndpoint,
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: id ? HTTPMethod.PUT : HTTPMethod.POST,
				headers: new Headers({ ...getAPIHeaders() }),
				body: JSON.stringify(groupReq),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		const group: GroupDto = {
			id,
			name: groupReq.name,
			signs: groupReq.signIds.map((sId) => ({ id: sId })),
		};

		if (apiRequestReturn.response?.ok && !id) {
			const groupRsp: { id: number } = await apiRequestReturn.response.json();
			group.id = groupRsp.id;
		}
		const normalized = normalize<GroupDto, Record<number, GroupDto>, number>(group, groupSchema);
		dispatch({
			type: DMSActionType.SAVE_GROUP,
			groupId: normalized.result,
			entities: normalized.entities,
		});

		dispatch(removeItem('signs', SignsLoaderState.groupSave));

		return apiRequestReturn;
	};

export const deleteGroups =
	(groupIds: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('signs', SignsLoaderState.groupDelete));

		const url = new URL(ConfigDMSApi.DeleteGroupEndpoint(groupIds), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({ ...getAPIHeaders() }),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.ok) {
			dispatch({ type: DMSActionType.DELETE_GROUPS, groupIds });
		}

		dispatch(removeItem('signs', SignsLoaderState.groupDelete));

		return apiRequestReturn;
	};

// using underscores in param names here to match the query params of the endpoint
/* eslint-disable @typescript-eslint/camelcase */
export const getLocationDetails =
	(
		route_designator: string,
		miles_along_route: number,
		end_miles?: number,
	): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({
			type: DMSActionType.GET_LOCATION_DETAILS,
			route_designator,
			miles_along_route,
			end_miles,
		});

		const url = new URL(ConfigDMSApi.locationDetails(route_designator), APIConfig.endpointURLBase);
		url.searchParams.set('miles_along_route', miles_along_route.toString());
		if (end_miles) {
			url.searchParams.set('end_miles', end_miles.toString());
		}

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const locationDetails: LocationDto = await apiRequestReturn.response.json();
				dispatch({
					type: DMSActionType.SET_LOCATION_DETAILS,
					locationDetails,
					route_designator,
					miles_along_route,
					end_miles,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const deleteCustomMessage =
	(messageIds: Array<number>): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.DELETE_CUSTOM_MESSAGE });

		const url = new URL(
			ConfigDMSApi.deleteCustomMessagesByIds(messageIds),
			APIConfig.endpointURLBase,
		);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages.login.route));
		} else if (apiRequestReturn.response.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: 'Custom message deleted successfully',
					},
					5000,
				),
			);
		}

		dispatch(addItem('signs', SignsLoaderState.customFetch));
		dispatch(getCustomMessages());

		return apiRequestReturn;
	};

export const getRouteExtent =
	(route: string, startMileMarker: number, endMileMarker: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_ROUTE_EXTENT });

		const url = new URL(
			ConfigDMSApi.routeExtent(route, startMileMarker, endMileMarker),
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const coords = await apiRequestReturn.response.json();
				dispatch({
					type: DMSActionType.SET_ROUTE_EXTENT,
					route,
					startMileMarker,
					endMileMarker,
					coords,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const deleteSigns =
	(signIds: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('sign', 'delete-sign'));
		dispatch({ type: DMSActionType.DELETE_SIGNS });
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigDMSApi.deleteSigns(signIds), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages.login.route));
		} else if (apiRequestReturn.response.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: `Sign ${signIds} Deleted Successfully`,
					},
					5000,
				),
			);
		}
		await dispatch(getSigns());
		dispatch(removeItem('sign', 'delete-sign'));
		return apiRequestReturn;
	};

export const addCustomMessageToSigns =
	(
		customMessageId: number,
		signIds: number[],
		startTime: number,
		endTime: number,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.ADD_CUSTOM_MESSAGE_TO_SIGNS });

		const url = new URL(
			ConfigDMSApi.addCustomMessageToSigns(customMessageId, signIds),
			APIConfig.endpointURLBase,
		);
		url.searchParams.set('start-time', startTime.toString());
		url.searchParams.set('end-time', endTime.toString());

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response.ok) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Custom message successfully added to sign queue' },
					5000,
				),
			);
		}

		await dispatch(getSigns());

		return apiRequestReturn;
	};

export const addCustomMessageToGroup =
	(customMessageId: number, start: number, end: number, groupId: number): ThunkActionRoot<void> =>
	(dispatch, getState): void => {
		dispatch({ type: DMSActionType.ADD_CUSTOM_MESSAGE_TO_GROUP });

		const { signs } = groupsSelector(getState()).find((group) => group.id === groupId);

		dispatch(
			addCustomMessageToSigns(
				customMessageId,
				signs.map((sign) => sign.id),
				start,
				end,
			),
		);
	};

let pollingSignsTimeout: ReturnType<typeof setInterval>;

export const pollSigns =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		const { signsLastPolled } = getState().dms;

		if (!signsLastPolled || Date.now() - signsLastPolled > ConfigDMS.SignPollingRate) {
			dispatch({ type: DMSActionType.SET_SIGNS, signIds: [] });
			dispatch(addItem('signs', SignsLoaderState.fetch));
			dispatch(addItem('signs', SignsLoaderState.customFetch));
			dispatch(addItem('signs', SignsLoaderState.groupFetch));
			await Promise.all([
				dispatch(getSigns()),
				dispatch(getCustomMessages()),
				dispatch(getGroups()),
				dispatch(getSignGraphics()),
			]);
		}

		clearInterval(pollingSignsTimeout);
		pollingSignsTimeout = setInterval(() => {
			dispatch(getSigns());
			dispatch(getGroups());
		}, ConfigDMS.SignPollingRate);
	};

export const stopPollSigns = (): ThunkActionRoot<void> => (): void => {
	clearInterval(pollingSignsTimeout);
};
