import { css } from 'lit';
import { get as _get, isEqual as _isEqual, set as _set } from 'lodash';
import { PreviewDto } from '../../../typings/api';
import { REMAction, REMActionType } from './rem-actions';
import { REMState, REM_STATE_INITIAL } from './rem-state';

//	TODO: memoize?
export const areTimelinesEquivalent = (
	eventTimeline1: REMState['eventTimeline'],
	eventTimeline2: REMState['eventTimeline'],
): boolean =>
	eventTimeline1?.id === eventTimeline2?.id &&
	//	easiest way to tell whether they're different is if their latest entry has a different timestamp
	eventTimeline1?.timeline?.entries?.[0].timestamp ===
		eventTimeline2?.timeline?.entries?.[0].timestamp;

export const unsaveableDraftPropPaths = [
	'nearbyCameras',
	'locationDetails',
	'locationDetails.county',
	'locationDetails.district',
	'locationDetails.subdistrict',
	'locationDetails.unit',
];

export const REMReducer = (state: REMState = REM_STATE_INITIAL, action: REMAction): REMState => {
	switch (action.type) {
		case REMActionType.SET_REM_EVENTS:
			return {
				...state,
				events: action.events,
				eventsLastPolled: Date.now(),
			};
		case REMActionType.SET_REM_DRAFT_EVENT: {
			return {
				...state,
				draftEvent: action.draftEvent,
				unsavedDraftEvent: false,
			};
		}
		case REMActionType.SET_REM_EVENT_FIELDS:
			return {
				...state,
				eventFields: action.eventFields,
			};
		case REMActionType.SET_REM_EVENT_EMAIL_RECIPIENTS:
			return {
				...state,
				emailGroupsRecipients: action.emailGroupsRecipients,
			};
		case REMActionType.ADD_REM_EVENT_EMAIL_GROUPS: {
			const newRecipients = { ...state.emailGroupsRecipients };
			if (action.emailGroups.length > 0) {
				newRecipients.emailGroups = [...(newRecipients.emailGroups ?? []), ...action.emailGroups];
			}
			return {
				...state,
				emailGroupsRecipients: newRecipients,
			};
		}
		case REMActionType.DELETE_REM_EVENT_EMAIL_GROUPS: {
			const newRecipients = { ...state.emailGroupsRecipients };
			newRecipients.emailGroups = newRecipients.emailGroups?.filter(
				(group) => !action.emailGroups.map((deleteGroup) => deleteGroup.id).includes(group.id),
			);
			return {
				...state,
				emailGroupsRecipients: newRecipients,
			};
		}
		case REMActionType.SET_REM_DRAFT_EVENT_PROP: {
			let { unsavedDraftEvent, draftEvent } = state;
			const currentValue: unknown = _get(draftEvent, action.path);
			if (_isEqual(currentValue, action.value) === false) {
				draftEvent = _set({ ...draftEvent }, action.path, action.value);
				if (unsaveableDraftPropPaths.includes(action.path) === false) {
					unsavedDraftEvent = true;
				}
			}
			return {
				...state,
				draftEvent,
				unsavedDraftEvent,
			};
		}
		case REMActionType.REM_EVENT_UPDATED:
			return {
				...state,
				unsavedDraftEvent: false,
			};
		case REMActionType.SET_REM_TABLE_COLUMNS:
			return {
				...state,
				table: {
					...state.table,
					columns: action.columns,
				},
			};
		case REMActionType.SET_REM_TABLE_SEARCH:
			return {
				...state,
				table: {
					...state.table,
					search: action.search,
				},
			};
		case REMActionType.SET_REM_TABLE_COLUMN_STATE:
			return {
				...state,
				table: {
					...state.table,
					columnState: action.state,
				},
			};
		case REMActionType.SET_REM_TABLE_FILTER_STATE:
			return {
				...state,
				table: {
					...state.table,
					filterState: action.state,
				},
			};
		case REMActionType.SET_REM_EVENT_TIMELINE:
			if (areTimelinesEquivalent(state.eventTimeline, action.eventTimeline)) {
				return state;
			}
			return {
				...state,
				eventTimeline: action.eventTimeline,
			};
		case REMActionType.SHOW_REM_EVENT_SECTION:
			return {
				...state,
				formSections: {
					...state.formSections,
					[action.section]: true,
				},
			};
		case REMActionType.SET_ROUTES_BY_LOCATION_META:
			return {
				...state,
				filteredRoutes: action.filteredRoutes,
			};
		case REMActionType.SET_ROUTES_FILTER_PARAM:
			return {
				...state,
				routeFilterParams: {
					...(state.routeFilterParams ?? { county: undefined, district: undefined }),
					[action.path]: action.value,
				},
			};
		case REMActionType.RESET_LOCATION_DETAILS:
			return {
				...state,
				draftEvent: {
					...state.draftEvent,
					//	wipe location data
					route: undefined,
					startMileMarker: undefined,
					endMileMarker: undefined,
					locationDetails: undefined,
					negativeLaneBlockage: undefined,
					negativeLaneBlockageType: undefined,
					positiveLaneBlockage: undefined,
					positiveLaneBlockageType: undefined,
					locationDescription: undefined,
					//	probably unnecessary
					lat: undefined,
					lon: undefined,
					nearbyCameras: undefined,
				},
				eventDMSPreviewMessages: undefined, //	DMS previews are invalidated if the location changes
			};
		case REMActionType.SET_DMS_PREVIEW:
			return {
				...state,
				eventDMSPreviewMessages: { ...action.eventDMSPreviewMessages },
			};
		case REMActionType.SET_DMS_PREVIEW_LOADING_IDS:
			return {
				...state,
				eventDMSSignLoadingIds: [...action.eventDMSSignLoadingIds],
				eventDMSPreviewMessages: action.eventDMSPreviewMessages
					? { ...action.eventDMSPreviewMessages }
					: state.eventDMSPreviewMessages,
				unsavedDraftEvent: action.unsavedDraftEvent ?? state.unsavedDraftEvent,
			};
		case REMActionType.REMOVE_DMS_FROM_REM_EVENT: {
			const eventDMSPreviewMessages = { ...state.eventDMSPreviewMessages } as Record<
				number,
				PreviewDto[] | null
			>;
			delete eventDMSPreviewMessages[action.signId];
			return {
				...state,
				eventDMSPreviewMessages,
				eventDMSSignLoadingIds: state.eventDMSSignLoadingIds?.filter(
					(signId) => signId !== action.signId,
				),
				unsavedDraftEvent: true,
			};
		}
		case REMActionType.SET_SIGN:
			return { ...state, currentSign: action.currentSign };
		case REMActionType.SET_SIGN_QUEUE:
			return { ...state, currentSignQueue: action.currentSignQueue };
		case REMActionType.SET_REM_READ_ONLY_MODE:
			return {
				...state,
				readOnlyMode: action.readOnlyMode,
			};
		case REMActionType.SET_REM_EVENT_CURRENT_EDITORS:
			return {
				...state,
				currentEditors: action.currentEditors,
			};
		case REMActionType.IS_MOBILE_SEARCH_MENU_VISIBLE:
			return {
				...state,
				isMobileSearchMenuVisible: action.isVisible,
			};
		case REMActionType.SET_MILE_MARKERS_VALID_FOR_ROUTE:
			return {
				...state,
				mileMarkersValidForRoute: action.mileMarkersValidForRoute,
			};
		//	intentional fallthrough for strong independant actions that don't need no reducer
		case REMActionType.ADD_NOTE_TO_EVENT_TIMELINE:
		case REMActionType.CREATE_REM_EVENT:
		case REMActionType.GET_DMS_FOR_REM:
		case REMActionType.GET_LOCATION_DETAILS:
		case REMActionType.GET_REM_EVENT_EMAIL_RECIPIENTS:
		case REMActionType.GET_REM_EVENT_FIELDS:
		case REMActionType.GET_REM_EVENT_FROM_EVENTS:
		case REMActionType.GET_REM_EVENT_TIMELINE:
		case REMActionType.GET_REM_EVENT:
		case REMActionType.GET_REM_EVENTS:
		case REMActionType.GET_REM_NEARBY_CAMERAS:
		case REMActionType.GET_ROUTES_BY_LOCATION_META:
		case REMActionType.GET_SIGN_QUEUE:
		case REMActionType.GET_SIGN:
		case REMActionType.UPDATE_REM_EVENT:
		case REMActionType.GET_REM_EVENT_CURRENT_EDITORS:
			return state;
		default:
			if (Object.values(REMActionType).includes(action.type)) {
				if (process.env.NODE_ENV === 'development') {
					console.warn('redux action unhandled in reducer:', action);
				}
			}
			return state;
	}
};
export default REMReducer;
