import { APIConfig } from '../config/ConfigIRISx';
import { ConfigLoginApi } from '../config/ConfigLogin';
import { isValidString } from '../utils/utils';

const Authorization = class {
	static async login(name, password) {
		this.logout(); //  so if it fails, you lose your previous JWT
		const url = new URL(ConfigLoginApi.AuthEndpoint, APIConfig.endpointURLBase);
		const request = new Request(url, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': Authorization.JWT,
			}),
			body: JSON.stringify({
				name,
				password,
			}),
		});

		const response = await fetch(request);

		const { ok, status } = response;
		if (!ok || !(status === 200)) {
			throw new Error(`Bad response: ${status}.`);
		}

		const data = await response.json();
		if (data.tokenType && data.accessToken) {
			this.JWT = `${data.tokenType} ${data.accessToken}`;
		} else {
			if (isValidString(data.tokenType) === false) {
				if (process.env.NODE_ENV === 'development') {
					console.error('Login failed - invalid tokenType in server response.');
				}
			}
			if (data.accessToken === undefined || data.accessToken === null) {
				if (process.env.NODE_ENV === 'development') {
					console.error('Login failed - accessToken absent from server response.');
				}
			}
		}

		return { ok, status, ...data };
	}

	//  link to data store
	static get JWT() {
		return window.localStorage.getItem('IRISx_JWT');
	}

	static set JWT(val) {
		return window.localStorage.setItem('IRISx_JWT', val);
	}

	static logout() {
		window.localStorage.removeItem('IRISx_JWT');
	}
};

export default Authorization;
