import { css } from 'lit';
import 'overlapping-marker-spiderfier-leaflet/dist/oms';
import { customElement, state as iState, property } from 'lit/decorators.js';
import { CSSResultArray, LitElement, PropertyValues, TemplateResult, html } from 'lit';
import { connect } from 'pwa-helpers';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import store, { RootState } from '../redux/redux-store';
import CSSReset from './css/lib/reset.scss';
import SharedStyles from './css/shared.scss';
import scss from '../utils/scssAsCss';
import { navigate } from '../redux/redux-routing';
import installRouter from '../utils/install-router';
import './common/irisx-page-nav';
import Authorization from '../rest/Authorization';
import { getUserPermissions } from '../redux/rem/redux-user';
import { selectBannersActive } from '../redux/redux-ui';
import { AppSection } from '../constants';
import { BannerLocation } from '../../typings/shared-types';
import { setOnline } from '../redux/redux-network';

@customElement('irisx-app')
export default class IrisxApp extends connect(store)(LitElement) {
	@property({ type: String }) private page?: AppSection;

	@iState() private loadingRouteProgress = 0;

	@property({ type: Boolean }) private isBodyNoScroll = false;

	@property({ type: Boolean }) private isLoadingRoute = false;

	@property({ type: Boolean }) private isBannerActive = false;

	public static override get styles(): CSSResultArray {
		return [
			CSSReset,
			SharedStyles,
			css`
:host {
  width: 100%;
  display: flex;
}

irisx-page-nav {
  position: relative;
  z-index: 2526;
}

.loading-route {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 3000;
  background-color: rgb(var(--brand-secondary));
  transform-origin: left;
  -webkit-transform: scaleX(0);
  transform: scaleX(1);
  transition-property: transform;
  transition-duration: 0.1s;
  transition-timing-function: ease;
}

.banner-active {
  top: calc(var(--topbar-height) + var(--header-height));
}
`,
		];
	}

	public override render(): TemplateResult {
		return html`
			<div
				class="loading-route"
				?hidden=${this.loadingRouteProgress === 0}
				style=${styleMap({
					transform: `scaleX(${this.loadingRouteProgress})`,
				})}
			></div>

			${this.page === AppSection.LOGIN ? html`<irisx-login></irisx-login> ` : undefined}
			${this.page &&
			[AppSection.CCTV, AppSection.DMS, AppSection.HH, AppSection.REM].includes(this.page)
				? html` <irisx-page-nav page=${this.page}></irisx-page-nav> `
				: undefined}
			${this.page === AppSection.CCTV ? html` <irisx-cctv></irisx-cctv> ` : undefined}
			${this.page === AppSection.DMS
				? html`
						<irisx-dms class=${classMap({ 'banner-active': this.isBannerActive })}></irisx-dms>
				  `
				: undefined}
			${this.page === AppSection.REM
				? html`
						<irisx-rem class=${classMap({ 'banner-active': this.isBannerActive })}></irisx-rem>
				  `
				: undefined}
			${this.page === AppSection.HH
				? html` <irisx-hh class=${classMap({ 'banner-active': this.isBannerActive })}></irisx-hh> `
				: undefined}
		`;
	}

	public constructor() {
		super();
		if (Authorization.JWT) {
			void store.dispatch(getUserPermissions());
		}
	}

	protected override firstUpdated(): void {
		installRouter((href) => {
			const path = window.decodeURIComponent(href);
			store.dispatch(navigate(path));
		});
	}

	protected override updated(changed: PropertyValues): void {
		if (changed.has('isBodyNoScroll') && this.isBodyNoScroll) {
			document.body.classList.add('noScroll');
		} else if (changed.has('isBodyNoScroll')) {
			document.body.classList.remove('noScroll');
		}

		if (changed.has('isLoadingRoute') && this.isLoadingRoute) {
			this.startRouteLoading();
		} else if (changed.has('isLoadingRoute')) {
			this.endRouteLoading();
		}
	}

	public override stateChanged(state: RootState): void {
		this.page = state.routing.page ?? undefined; //	is there any significance to null versus undefined for this prop?
		this.isBodyNoScroll = state.ui.isBodyNoScroll;
		this.isBannerActive = selectBannersActive(state, BannerLocation.MAIN);

		this.isLoadingRoute = state.ui.isLoadingRoute;
	}

	private routeLoaderInterval?: ReturnType<typeof setTimeout>;

	private startRouteLoading(): void {
		if (this.routeLoaderInterval !== undefined) {
			clearInterval(this.routeLoaderInterval);
		}
		this.routeLoaderInterval = setInterval(() => {
			this.loadingRouteProgress += 0.05;
		}, 100);
	}

	private endRouteLoading(): void {
		if (this.routeLoaderInterval !== undefined) {
			clearInterval(this.routeLoaderInterval);
		}
		if (this.loadingRouteProgress > 0.1) {
			this.loadingRouteProgress = 1;
		}
		setTimeout(() => {
			this.loadingRouteProgress = 0;
		}, 100);
	}

	private onWindowOffline(): void {
		store.dispatch(setOnline(false));
	}

	private onWindowOnline(): void {
		store.dispatch(setOnline(true));
	}

	public override connectedCallback(): void {
		super.connectedCallback();

		window.addEventListener('offline', this.onWindowOffline);
		window.addEventListener('online', this.onWindowOnline);
	}

	public override disconnectedCallback(): void {
		super.disconnectedCallback();

		window.removeEventListener('offline', this.onWindowOffline);
		window.removeEventListener('online', this.onWindowOnline);
	}
}
