import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import {
	DraftEvent,
	EventMessageDto,
	FrameDto,
	PreviewDto,
	PreviewDtoResponse,
	RoadEventDto,
	SaveEventMessagesDto,
	SignDto,
} from '../../../typings/api';
import { APIErrorJSON, NotificationErrorType } from '../../../typings/shared-types';
import ConfigIRISx, { APIConfig } from '../../config/ConfigIRISx';
import { ConfigREMApi } from '../../config/ConfigREM';
import { AppSection } from '../../constants';
import { isAPIErrorJSON } from '../../utils/type-guards';
import { multitagIsJustifyLineCenter, multitagIsJustifyPageMiddle } from '../../utils/utils';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { navigate } from '../redux-routing';
import { ThunkActionRoot, ThunkDispatchRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { REMActionType } from './rem-actions';
import { prepareDraftEventForServer } from './rem-actions-event';
import REMState from './rem-state';

export const frameExistsAndIsNotGraphic = (frame?: FrameDto): boolean =>
	frame !== null && frame !== undefined && frame.graphicId === undefined;

export const prepareDMSMessagesForClient = (
	eventDMSPreviewMessages: PreviewDto[],
): PreviewDtoResponse => {
	return Object.values(eventDMSPreviewMessages).reduce((acc, message) => {
		let justifyLineCenter = false;
		let justifyPageMiddle = false;
		if (frameExistsAndIsNotGraphic(message.frame1)) {
			//	message-wide formatting multitags only appear on the first frame, but apply to all (text) frames
			justifyLineCenter = multitagIsJustifyLineCenter(message.frame1.text);
			justifyPageMiddle = multitagIsJustifyPageMiddle(message.frame1.text);
		}
		[message.frame2, message.frame3].forEach((frame) => {
			if (frameExistsAndIsNotGraphic(frame)) {
				if (justifyLineCenter) {
					frame.text = `[jl3]${frame.text}`;
				}
				if (justifyPageMiddle) {
					frame.text = `[jp3]${frame.text}`;
				}
			}
		});
		if (message?.signId) {
			if (
				acc[message.signId] === undefined ||
				acc[message.signId] === null ||
				Array.isArray(acc[message.signId]) === false
			) {
				acc[message.signId] = [message];
			} else {
				acc[message.signId]!.push(message);
			}
		}
		return acc;
	}, {} as PreviewDtoResponse);
};

export interface GetDMSforREMEvent extends Action<typeof REMActionType.GET_DMS_FOR_REM> {
	event: RoadEventDto;
}

export interface SetDMSforREMEvent extends Action<typeof REMActionType.SET_DMS_PREVIEW> {
	eventDMSPreviewMessages: PreviewDtoResponse | null;
}

export interface SetDMSLoadingIds extends Action<typeof REMActionType.SET_DMS_PREVIEW_LOADING_IDS> {
	eventDMSSignLoadingIds: number[];
	eventDMSPreviewMessages?: PreviewDtoResponse;
	unsavedDraftEvent?: true;
}

export interface RemoveDMSFromREMEvent
	extends Action<typeof REMActionType.REMOVE_DMS_FROM_REM_EVENT> {
	signId: number;
}

export type GetSign = Action<typeof REMActionType.GET_SIGN>;
export interface SetSign extends Action<typeof REMActionType.SET_SIGN> {
	currentSign: REMState['currentSign'];
}
export type GetSignQueue = Action<typeof REMActionType.GET_SIGN_QUEUE>;
export interface SetSignQueue extends Action<typeof REMActionType.SET_SIGN_QUEUE> {
	currentSignQueue?: EventMessageDto[];
}

export const setDMSPreview = (eventDMSPreviewMessages?: PreviewDtoResponse): SetDMSforREMEvent => ({
	type: REMActionType.SET_DMS_PREVIEW,
	eventDMSPreviewMessages,
});

export const removeDMSPreview = (signId: number): RemoveDMSFromREMEvent => ({
	type: REMActionType.REMOVE_DMS_FROM_REM_EVENT,
	signId,
});

export const getDMSforREMEvent =
	(event: DraftEvent, forceNewPreviews?: true): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot, getState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_DMS_FOR_REM,
			event,
		});
		const id = getState().rem.draftEvent?.id;
		/*
	
		draft event with an id has dms already assigned:
			GET getDMSForRemEvent by event id in the URL

		unsaved draft event with no id yet:
			POST dmsPreviewsByREMEvent a DraftEvent in the body

	*/
		const getExistingPreviews = id && !forceNewPreviews;
		const url =
			getExistingPreviews && id !== undefined
				? new URL(ConfigREMApi.getDMSForRemEvent(id), APIConfig.endpointURLBase)
				: new URL(ConfigREMApi.dmsPreviewsByREMEvent(), APIConfig.endpointURLBase);
		const preparedDraftEvent = prepareDraftEventForServer(event);
		const method = getExistingPreviews ? HTTPMethod.GET : HTTPMethod.POST;
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: getExistingPreviews ? undefined : JSON.stringify(preparedDraftEvent),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}

		try {
			const response: PreviewDto[] | APIErrorJSON = await apiRequestReturn.response?.clone().json();
			if (isAPIErrorJSON(response)) {
				if (process.env.NODE_ENV === 'development') {
					console.error(`${response.error}: ${response.message}`);
				}
				dispatch(setDMSPreview(null));
			} else {
				const eventDMSPreviewMessages = prepareDMSMessagesForClient(response);
				dispatch(setDMSPreview(eventDMSPreviewMessages));
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing dms messaging preview for current event:`, error);
			}
		}
		return apiRequestReturn;
	};

export const determineDMSPreviews = (
	newSignIds: number[],
	allEventDMSPreviewMessages?: PreviewDtoResponse,
): SetDMSLoadingIds => {
	if (!allEventDMSPreviewMessages) {
		return {
			type: REMActionType.SET_DMS_PREVIEW_LOADING_IDS,
			eventDMSSignLoadingIds: newSignIds,
		};
	}

	const eventDMSPreviewMessages = Object.entries(allEventDMSPreviewMessages).reduce(
		(acc, [key, previews]) => {
			const signId = parseInt(key, 10);
			if (!previews?.length || newSignIds.includes(signId)) {
				acc[key] = previews;
			}
			return acc;
		},
		{} as PreviewDtoResponse,
	);

	const eventDMSSignLoadingIds = newSignIds.reduce((acc, signId) => {
		if (!allEventDMSPreviewMessages[String(signId)]) {
			acc.push(signId);
		}
		return acc;
	}, new Array<number>());

	return {
		type: REMActionType.SET_DMS_PREVIEW_LOADING_IDS,
		eventDMSSignLoadingIds,
		eventDMSPreviewMessages,
		unsavedDraftEvent: true,
	};
};
export const setDMSSignLoadingPreviewIds = (eventDMSSignLoadingIds: number[]): SetDMSLoadingIds => {
	return {
		type: REMActionType.SET_DMS_PREVIEW_LOADING_IDS,
		eventDMSSignLoadingIds,
	};
};

export const getDMSPreviews =
	(signIds: number[], draftEvent: DraftEvent): ThunkActionRoot =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiReturn = await APIRequest(
			new Request(
				new URL(ConfigREMApi.dmsPreviewsPerSign(signIds), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.POST,
					headers: new Headers({
						...getAPIHeaders(),
					}),
					body: JSON.stringify(prepareDraftEventForServer(draftEvent)),
				},
			),
		);
		if (apiReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		if (apiReturn.response?.status !== 200) {
			if (process.env.NODE_ENV === 'development') {
				console.error('error getting DMS messages for current event.');
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `There was an error getting the DMS messages for this incident.` },
					5000,
				),
			);
			return apiReturn;
		}
		const previews = await apiReturn.response.clone().json();

		dispatch(setDMSPreview(previews));
		dispatch(setDMSSignLoadingPreviewIds([]));

		return apiReturn;
	};

export const setSign = (currentSign?: SignDto): SetSign => ({
	type: REMActionType.SET_SIGN,
	currentSign,
});

export const getSign =
	(signId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_SIGN,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.getSignById(signId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const currentSign: SignDto = await apiRequestReturn.response?.clone().json();
			dispatch(setSign(currentSign));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing sign #${signId}:`, error);
			}
		}
		return apiRequestReturn;
	};

export const setSignQueue = (currentSignQueue?: PreviewDto[]): SetSignQueue => ({
	type: REMActionType.SET_SIGN_QUEUE,
	currentSignQueue,
});

export const getSignQueue =
	(signId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_SIGN_QUEUE,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.getSignQueueById(signId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const signQueue: PreviewDto[] = await apiRequestReturn.response?.clone().json();
			const currentSignQueue = Object.values(prepareDMSMessagesForClient(signQueue)).flat();
			dispatch(setSignQueue(currentSignQueue));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing queue for sign #${signId}:`, error);
			}
		}
		return apiRequestReturn;
	};

export const saveDMSForEvent =
	(eventId: number, signIds: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const saveEventMessagesDto: SaveEventMessagesDto = {
			eventId,
			signIds,
		};
		const url = new URL(ConfigREMApi.setDMSforRemEvent(), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(saveEventMessagesDto),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response?.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: `DMS messages saved for event #${eventId}`,
					},
					4000,
				),
			);
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `DMS messages could not be saved for event #"${eventId}"`,
						messages: [
							`Server response status ${apiRequestReturn.response?.status} ${
								apiRequestReturn.response?.statusText
							}${apiRequestReturn.apiError ? ` - ${apiRequestReturn.apiError}` : undefined}`,
						],
					},
					8000,
				),
			);
		}
		return apiRequestReturn;
	};
