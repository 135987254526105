import { css } from 'lit';
import { Action } from 'redux';
import { set as _set } from 'lodash';
import HTTPMethod from 'http-method-enum';
import APIRequest, { APIError, APIRequestReturn } from './APIRequest';
import { MonitorGroup } from '../../typings/shared-types';
import { CameraDto, ControllerDto } from '../../typings/api';
import { RootState, ThunkActionRoot } from './redux-store';
import { ConfigCCTVApi } from '../config/ConfigCCTV';
import ConfigIRISx, { APIConfig } from '../config/ConfigIRISx';
import Authorization from '../rest/Authorization';
import { navigate } from './redux-routing';
import { AppSection } from '../constants';

//	STATE

export enum CCTVAPIError {
	GetMonitors = 'GetMonitors',
	SetMonitors = 'SetMonitors',
	LayoutMonitors = 'LayoutMonitors',
	GeoSearch = 'GeoSearch',
	GetCameras = 'GetCameras',
}

export type CCTVState = {
	errors?: {
		[key in CCTVAPIError]?: {
			[key in APIError]?: boolean;
		};
	};
	currentMonitorGroup: MonitorGroup;
	monitors?: ControllerDto[];
	cameras?: CameraDto[];
};

export const CCTV_STATE_INITIAL: CCTVState = {
	errors: {},
	currentMonitorGroup: MonitorGroup.MY_MONITORS,
	monitors: undefined,
	cameras: undefined,
};

//	ACTION TYPES

export enum CCTVActionType {
	SET_ERROR_STATE = 'SET_ERROR_STATE',
	SET_CURRENT_MONITOR_GROUP = 'SET_CURRENT_MONITOR_GROUP',
	GET_MONITORS = 'GET_MONITORS',
	SET_MONITORS = 'SET_MONITORS',
	GET_CAMERAS = 'GET_CAMERAS',
	SET_CAMERAS = 'SET_CAMERAS',
}

interface SetErrorState extends Action<typeof CCTVActionType.SET_ERROR_STATE> {
	source: CCTVAPIError;
	key: APIError;
	value: boolean;
}

interface SetCurrentMonitorGroup extends Action<typeof CCTVActionType.SET_CURRENT_MONITOR_GROUP> {
	currentMonitorGroup: MonitorGroup;
}

type GetMonitors = Action<CCTVActionType.GET_MONITORS>;

interface SetMonitors extends Action<CCTVActionType.SET_MONITORS> {
	monitors: ControllerDto[];
}

type GetCameras = Action<CCTVActionType.GET_CAMERAS>;

interface SetCameras extends Action<CCTVActionType.SET_CAMERAS> {
	cameras: CameraDto[];
}

export type CCTVAction =
	| SetErrorState
	| SetCurrentMonitorGroup
	| GetMonitors
	| SetMonitors
	| GetCameras
	| SetCameras;

//	ACTIONS

export const setErrorState = (
	source: SetErrorState['source'],
	key: SetErrorState['key'],
	value: SetErrorState['value'],
): SetErrorState => ({
	type: CCTVActionType.SET_ERROR_STATE,
	source,
	key,
	value,
});

export const setCurrentMonitorGroup = (
	currentMonitorGroup: MonitorGroup,
): SetCurrentMonitorGroup => ({
	type: CCTVActionType.SET_CURRENT_MONITOR_GROUP,
	currentMonitorGroup,
});

export const getMonitors = (): ThunkActionRoot<Promise<APIRequestReturn>> => async (
	dispatch,
): Promise<APIRequestReturn> => {
	dispatch({
		type: CCTVActionType.GET_MONITORS,
	});
	const url = new URL(ConfigCCTVApi.getMonitorsEndpoint(), APIConfig.endpointURLBase);
	const apiRequestReturn = await APIRequest(
		new Request(url.href, {
			method: HTTPMethod.GET,
			headers: new Headers({
				Authorization: Authorization.JWT,
				Accept: 'application/json',
			}),
		}),
	);
	if (apiRequestReturn.response.status === 401) {
		dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
	} else if (apiRequestReturn.response.ok === true) {
		try {
			const monitors: ControllerDto[] = await apiRequestReturn.response.json();
			dispatch({
				type: CCTVActionType.SET_MONITORS,
				monitors,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing response from "${apiRequestReturn.request.url}"`, error);
			}
		}
	}
	return apiRequestReturn;
};

//	REDUCER

export const CCTVReducer = (
	state: CCTVState = CCTV_STATE_INITIAL,
	action: CCTVAction,
): CCTVState => {
	switch (action.type) {
		case CCTVActionType.SET_ERROR_STATE: {
			return {
				...state,
				errors: _set(state.errors, `${action.source}.${action.key}`, action.value),
			};
		}
		case CCTVActionType.SET_CURRENT_MONITOR_GROUP:
			return {
				...state,
				currentMonitorGroup: action.currentMonitorGroup,
			};
		case CCTVActionType.SET_MONITORS:
			return {
				...state,
				monitors: action.monitors,
			};
		case CCTVActionType.SET_CAMERAS:
			return {
				...state,
				cameras: action.cameras,
			};
		default:
			return state;
	}
};

//	'option' means "exclude this monitor from this user's personal list of monitors"
const filterIncludeRecommendedMonitorsOnly = (monitor: ControllerDto): boolean =>
	monitor.option === false;

export const selectMonitorsByGroup = (state: RootState): ControllerDto[] => {
	if (
		state.cctv.monitors !== undefined &&
		state.cctv.currentMonitorGroup === MonitorGroup.MY_MONITORS
	) {
		return state.cctv.monitors.filter(filterIncludeRecommendedMonitorsOnly);
	}
	return state.cctv.monitors;
};
