import { css } from 'lit';
import { ColumnState } from 'ag-grid-community';
import { Action } from 'redux';
import { AGGridFilterModel } from '../../../typings/shared-types';
import {
	GetDMSforREMEvent,
	GetSign,
	GetSignQueue,
	RemoveDMSFromREMEvent,
	SetDMSLoadingIds,
	SetDMSforREMEvent,
	SetSign,
	SetSignQueue,
} from './rem-actions-event-dms';
import {
	CreateREMEvent,
	GetREMEvent,
	GetREMEventFields,
	GetREMEventFromEvents,
	GetREMEvents,
	REMEventUpdated,
	SetDraftEventProp,
	SetREMDraftEvent,
	SetREMEvent,
	SetREMEventFields,
	SetREMEvents,
	SetREMReadOnlyMode,
	ShowREMEventSection,
	UpdateREMEvent,
} from './rem-actions-event';
import { GetREMEventCurrentEditors, SetREMEventCurrentEditors } from './rem-actions-event-editors';
import {
	AddEmailGroups,
	DeleteEmailGroups,
	GetEmailRecipients,
	SetEmailRecipients,
} from './rem-actions-event-email';
import {
	GetLocationDetails,
	GetNearbyCameras,
	GetRoutesByLocationMeta,
	ResetLocationDetails,
	SetMileMarkersValidForRoute,
	SetNearbyCameras,
	SetRoutesByLocationMeta,
	SetRoutesFilterParam,
} from './rem-actions-event-location';
import {
	AddNoteToEventTimeline,
	GetREMEventTimeline,
	SetREMEventTimeline,
} from './rem-actions-event-timeline';

export enum REMAPIAction {
	GetEvents = 'GetEvents',
	CreateEvent = 'CreateEvent',
	GetFields = 'GetFields',
}

//	ACTION TYPES

export enum REMActionType {
	GET_REM_EVENT = 'GET_REM_EVENT',
	GET_REM_EVENT_FROM_EVENTS = 'GET_REM_EVENT_FROM_EVENTS',
	CREATE_REM_EVENT = 'CREATE_REM_EVENT',
	SET_REM_EVENT = 'SET_REM_EVENT',
	UPDATE_REM_EVENT = 'UPDATE_REM_EVENT',
	REM_EVENT_UPDATED = 'REM_EVENT_UPDATED',
	GET_REM_EVENT_TIMELINE = 'GET_REM_EVENT_TIMELINE',
	SET_REM_EVENT_TIMELINE = 'SET_REM_EVENT_TIMELINE',
	ADD_NOTE_TO_EVENT_TIMELINE = 'ADD_NOTE_TO_EVENT_TIMELINE',

	GET_REM_EVENTS = 'GET_REM_EVENTS',
	SET_REM_EVENTS = 'SET_REM_EVENTS',

	GET_REM_EVENT_FIELDS = 'GET_REM_EVENT_FIELDS',
	SET_REM_EVENT_FIELDS = 'SET_REM_EVENT_FIELDS',
	GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS',
	SET_MILE_MARKERS_VALID_FOR_ROUTE = 'SET_MILE_MARKERS_VALID_FOR_ROUTE',
	GET_REM_NEARBY_CAMERAS = 'GET_REM_NEARBY_CAMERAS',
	SET_REM_NEARBY_CAMERAS = 'SET_REM_NEARBY_CAMERAS',
	GET_DMS_FOR_REM = 'GET_DEMS_PREVIEW',
	SET_DMS_PREVIEW = 'SET_DMS_PREVIEW',
	SET_DMS_PREVIEW_LOADING_IDS = 'SET_DMS_PREVIEW_LOADING_IDS',

	SET_ROUTES_FILTER_PARAM = 'SET_ROUTES_FILTER_PARAM',
	GET_ROUTES_BY_LOCATION_META = 'GET_ROUTES_BY_LOCATION_META',
	SET_ROUTES_BY_LOCATION_META = 'SET_ROUTES_BY_LOCATION_META',

	SET_REM_DRAFT_EVENT = 'SET_REM_DRAFT_EVENT',
	SET_REM_DRAFT_EVENT_PROP = 'SET_REM_DRAFT_EVENT_PROP',

	SET_REM_TABLE_COLUMNS = 'SET_REM_TABLE_COLUMNS',
	SET_REM_TABLE_SEARCH = 'SET_REM_TABLE_SEARCH',
	SET_REM_TABLE_COLUMN_STATE = 'SET_REM_TABLE_COLUMN_STATE',
	SET_REM_TABLE_FILTER_STATE = 'SET_REM_TABLE_FILTER_STATE',

	SHOW_REM_EVENT_SECTION = 'SHOW_REM_EVENT_SECTION',
	RESET_LOCATION_DETAILS = 'RESET_LOCATION_DETAILS',

	GET_REM_EVENT_EMAIL_RECIPIENTS = 'GET_REM_EVENT_EMAIL_RECIPIENTS',
	SET_REM_EVENT_EMAIL_RECIPIENTS = 'SET_REM_EVENT_EMAIL_RECIPIENTS',

	ADD_REM_EVENT_EMAIL_GROUPS = 'ADD_REM_EVENT_EMAIL_GROUPS',
	DELETE_REM_EVENT_EMAIL_GROUPS = 'DELETE_REM_EVENT_EMAIL_GROUPS',

	REMOVE_DMS_FROM_REM_EVENT = 'REMOVE_DMS_FROM_REM_EVENT',
	GET_SIGN = 'GET_SIGN',
	SET_SIGN = 'SET_SIGN',
	GET_SIGN_QUEUE = 'GET_SIGN_QUEUE',
	SET_SIGN_QUEUE = 'SET_SIGN_QUEUE',

	SET_REM_READ_ONLY_MODE = 'SET_REM_READ_ONLY_MODE',
	IS_MOBILE_SEARCH_MENU_VISIBLE = 'IS_MOBILE_SEARCH_MENU_VISIBLE',

	GET_REM_EVENT_CURRENT_EDITORS = 'GET_REM_EVENT_CURRENT_EDITORS',
	SET_REM_EVENT_CURRENT_EDITORS = 'SET_REM_EVENT_CURRENT_EDITORS',
}

interface SetREMTableColumns extends Action<typeof REMActionType.SET_REM_TABLE_COLUMNS> {
	columns: string[];
}

interface SetREMTableSearch extends Action<typeof REMActionType.SET_REM_TABLE_SEARCH> {
	search: string;
}

interface SetREMTableColumnState extends Action<typeof REMActionType.SET_REM_TABLE_COLUMN_STATE> {
	state: ColumnState[];
}

interface SetREMTableFilterState extends Action<typeof REMActionType.SET_REM_TABLE_FILTER_STATE> {
	state: AGGridFilterModel;
}

interface IsMobileSearchMenuVisible
	extends Action<typeof REMActionType.IS_MOBILE_SEARCH_MENU_VISIBLE> {
	isVisible: boolean;
}

export type REMAction =
	//	all events
	| GetREMEvents
	| SetREMEvents
	| IsMobileSearchMenuVisible
	//	single event
	| GetREMEventFromEvents
	| GetREMEvent
	| SetREMEvent
	| CreateREMEvent
	| UpdateREMEvent
	| REMEventUpdated
	| GetREMEventTimeline
	| SetREMEventTimeline
	| AddNoteToEventTimeline
	| GetREMEventCurrentEditors
	| SetREMEventCurrentEditors
	//	draft event
	| SetREMDraftEvent
	| SetDraftEventProp
	| ResetLocationDetails
	| ShowREMEventSection
	| SetREMReadOnlyMode
	//	form value validation + population
	| GetREMEventFields
	| SetREMEventFields
	| GetEmailRecipients
	| SetEmailRecipients
	| AddEmailGroups
	| DeleteEmailGroups
	| SetMileMarkersValidForRoute
	//	location data
	| GetLocationDetails
	| GetNearbyCameras
	| SetNearbyCameras
	| SetRoutesFilterParam
	| GetRoutesByLocationMeta
	| SetRoutesByLocationMeta
	//	sign data
	| GetDMSforREMEvent
	| SetDMSforREMEvent
	| SetDMSLoadingIds
	| RemoveDMSFromREMEvent
	| GetSign
	| SetSign
	| GetSignQueue
	| SetSignQueue
	//	table view
	| SetREMTableColumns
	| SetREMTableSearch
	| SetREMTableColumnState
	| SetREMTableFilterState;

export default REMAction;

//	ACTIONS

//	TODO: move notification banner stuff to redux-ui, move notification component to global location

export const setREMTableColumns = (columns: SetREMTableColumns['columns']): SetREMTableColumns => ({
	type: REMActionType.SET_REM_TABLE_COLUMNS,
	columns,
});

export const setREMTableSearch = (search: SetREMTableSearch['search']): SetREMTableSearch => ({
	type: REMActionType.SET_REM_TABLE_SEARCH,
	search,
});

export const setREMTableColumnState = (
	state: SetREMTableColumnState['state'],
): SetREMTableColumnState => ({
	type: REMActionType.SET_REM_TABLE_COLUMN_STATE,
	state,
});

export const setREMTableFilterState = (state: AGGridFilterModel): SetREMTableFilterState => ({
	type: REMActionType.SET_REM_TABLE_FILTER_STATE,
	state,
});

export const isMobileSearchMenuVisible = (isVisible: boolean): IsMobileSearchMenuVisible => ({
	type: REMActionType.IS_MOBILE_SEARCH_MENU_VISIBLE,
	isVisible,
});
