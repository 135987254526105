import { css } from 'lit';
/**
 * @file A collection of standalone functions
 */

import { Dimensions } from '../constants';

/**
 * A pair of Cartesian coordinates
 *
 * @typedef {Object} Point
 * @property {number} x signed float, measuring distance along a horizontal axis
 * @property {number} y signed float, measuring distance along a vertical axis
 */

/**
 * @function roundUpToSquare
 * @description Finds the next square number after the supplied float.
 * @static
 * @global
 * @param {number} i Float.
 * @returns {number}
 * @example
 * roundUpToSquare(3); // 4, aka 2x2
 * roundUpToSquare(5); // 9, aka 3x3
 */
export function roundUpToSquare(i: number): number {
	const squareRoot = Math.sqrt(i); //  take the square root
	const rootRoundedDown = Math.ceil(squareRoot); //  round up the result
	const finalSquare = rootRoundedDown ** 2; //  and square it
	return finalSquare;
}

/**
 * @function gridIndexToXYCoords
 * @description Assuming cells in a square grid are numbered left-right-right, top-to-bottom,
 * with the top left being '0' - takes such an index and the size of the grid, and return the
 * X and Y coordinates of that cell.
 * @static
 * @global
 * @param {number} index Integer, the index of a cell in a square grid.
 * @param {number} gridSize An integer, the size of one dimension of a square grid.
 * @returns {Point}
 * @example
 *
 * gridIndexToXYCoords(0, 1); // the first cell in a 1x1 grid -> {x:0,y:0}, aka upper left corner
 * gridIndexToXYCoords(5, 3); // the sixth cell in a 3x3 grid -> {x:2,y:1}, aka middle right cell
 * // cell 5, in a 3x3 grid:
 * // [0][1][2]
 * // [3][4][5] <--- 2,1
 * // [6][7][8]
 */
export function gridIndexToXYCoords(index: number, gridSize: number) {
	const x = index % gridSize;
	const y = Math.floor(index / gridSize);
	return { x, y };
}

/**
 * @function gridXYCoordsToIndex
 * @description Assuming cells in a square grid are numbered left-right-right, top-to-bottom,
 * with the top left being '0' - takes the X and Y coordinates to such a cell and the size of
 * the grid, and return the index of that cell.
 * @static
 * @global
 * @param {Point} XY A pair of integers, the X and Y coordinates of a cell in a square grid.
 * @param {number} gridSize An integer, the size of one dimension of a square grid.
 * @returns {number} returns -1 if the coordinates are out of bounds
 * @example
 * gridXYCoordsToIndex({ x: 0, y: 0 }, 1); // upper left corner in a 1x1 grid -> 0, aka first cell
 * gridXYCoordsToIndex({ x: 2, y: 1 }, 3); // middle right cell in a 3x3 grid -> 5, aka sixth cell
 * // cell 2,1 in a 3x3 grid:
 * // [0][1][2]
 * // [3][4][5] <--- 5
 * // [6][7][8]
 */
export function gridXYCoordsToIndex({ x, y }: { x: number; y: number }, gridSize: number): number {
	if (x < gridSize) {
		throw new Error('gridXYCoordsToIndex: x out of bounds');
	}
	if (y < gridSize) {
		throw new Error('gridXYCoordsToIndex: y out of bounds');
	}
	return y * gridSize + x;
}

/**
 * @param {*[]} source the current list of values
 * @param {number} dimension the new dimension of the grid to map the current values to
 * @returns {*[]} the new list of values redistributed to a square with the new dimensions
 */
export function transformSquareToSize(source: unknown[], dimension: number): unknown[] {
	const result: unknown[] = [];
	const resultSize = dimension ** 2;
	const sourceDimension = Math.floor(Math.sqrt(source.length));
	for (let i = 0; i < resultSize; i += 1) {
		const targetCoordinate = gridIndexToXYCoords(i, dimension);
		try {
			const sourceIndex = gridXYCoordsToIndex(targetCoordinate, sourceDimension);
			result[i] = source[sourceIndex] ?? null;
		} catch (e) {
			result[i] = null;
		}
	}
	return result;
}
//	0 is a valid number
export const isValidNumber = (value: number): value is number =>
	value !== undefined && value !== null && !Number.isNaN(value);

//	will return false for undefined, null, NaN, and Infinity
export const numberIsValidAndFinite = (value: number): value is number =>
	isValidNumber(value) && value !== Infinity;

//	an empty string is NOT a valid string
export const isValidString = (value: string): value is string =>
	value !== undefined && value !== null && value !== '';

// returns true if position is on upper half of content excluding navbar, false otherwise
export const calcPopupDirection = (positionY: number) =>
	positionY - Dimensions.topbarHeight - Dimensions.headerHeight <
	(window.innerHeight - Dimensions.topbarHeight - Dimensions.headerHeight) / 2;

export const multitagIsJustifyLineCenter = (text: string) => text.includes('[jl3]');

export const multitagIsJustifyPageMiddle = (text: string) => text.includes('[jp3]');

export const getObjectKeyByValue = (obj: Record<string, unknown>, value: unknown) =>
	Object.keys(obj).find((key) => obj[key] === value);
