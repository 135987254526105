import { css } from 'lit';
import { ColDef, ColumnState } from 'ag-grid-community';
import { APIError } from '../APIRequest';
import {
	CustomMessageDto,
	GraphicDto,
	GroupDto,
	LocationDto,
	RouteDto,
	SignDto,
	SignQueueDto,
} from '../../../typings/api';
import { ConfigDMSCustomMessagesTable, ConfigDMSTable } from '../../config/ConfigDMS';
import { AGGridFilterModel, LatLon } from '../../../typings/shared-types';

export type DmsEntities = { signs: Record<number, SignDto>; groups: Record<number, GroupDto> };
export type DMSState = {
	signsError?: APIError;
	groupsError?: APIError;

	signIds?: number[];
	groupIds?: number[];
	entities: DmsEntities;

	filterTypes?: string[];
	customMessages?: CustomMessageDto[];
	signsLastPolled?: number;
	signsTable?: {
		columns?: ColDef['colId'][];
		search?: string;
		columnState?: ColumnState[];
		filterState?: AGGridFilterModel;
	};
	messagesTable?: {
		columns?: ColDef['colId'][];
		search?: string;
		columnState?: ColumnState[];
		filterState?: AGGridFilterModel;
	};
	signGraphics?: GraphicDto[];
	signGraphicsTable?: {
		search?: string;
		columnState?: ColumnState[];
		filterState?: AGGridFilterModel;
	};
	uploadGraphicsTable?: {
		search?: string;
	};
	uploadCustomMessagesTable?: {
		search?: string;
	};
	signQueue: SignQueueDto;
	routes: RouteDto[];
	locationDetailsDict: Record<string, LocationDto>;
	routeExtentDict: Record<string, Array<LatLon>>;
};

export const DMS_STATE_INITIAL: DMSState = {
	entities: { groups: {}, signs: {} },
	signsError: undefined,
	customMessages: undefined,
	signsLastPolled: undefined,
	signsTable: {
		columns: ConfigDMSTable.defaultColumns,
		search: undefined,
		columnState: undefined,
		filterState: undefined,
	},
	messagesTable: {
		columns: ConfigDMSCustomMessagesTable.defaultColumns,
		search: undefined,
		columnState: undefined,
		filterState: undefined,
	},
	signGraphics: undefined,
	signGraphicsTable: {
		search: undefined,
		columnState: undefined,
		filterState: undefined,
	},
	uploadGraphicsTable: {
		search: undefined,
	},
	uploadCustomMessagesTable: {
		search: undefined,
	},
	signQueue: undefined,
	routes: undefined,
	locationDetailsDict: undefined,
	routeExtentDict: undefined,
};
