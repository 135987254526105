import { css } from 'lit';
export type LatLngBounds = {
	NorthLat: number;
	EastLon: number;
	SouthLat: number;
	WestLon: number;
};

export type LatLng = {
	lat: number;
	lng: number;
};

export type LatLon = {
	lat: number;
	lon: number;
};

export enum NotificationErrorType {
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	WARNING = 'WARNING',
}

export enum BannerLocation {
	MAIN = 'MAIN',
	MODAL = 'MODAL',
}

export type Banner = {
	id: number;
	type: NotificationErrorType;
	messaging: { title: string; messages: string[] };
	timeout: number;
	location: BannerLocation;
};

export type TimelineDayEntryChange = {
	subject: string;
	description: string;
};

export type TimelineDayEntry = {
	timeString: string; //	HH:MM ZZZ
	user: string;
	changes: TimelineDayEntryChange[];
};

export type TimelineDay = {
	dateString: string; //	"MM/DD/YYYY"
	entries: TimelineDayEntry[];
};

export type GeoSearchResult = {
	label: string;
	lat: number;
	lng: number;
};

export enum MonitorGroup {
	MY_MONITORS = 'MY_MONITORS',
	ALL_MONITORS = 'ALL_MONITORS',
}

export type APIErrorJSON = {
	timestamp: string;
	status: number;
	error: string;
	message: string;
	path: string;
};

export type AGGridFilterModelEntry = {
	filterType: string;
	type: string;
	filter: string;
};
export type AGGridFilterModel = { [key: string]: AGGridFilterModelEntry };
export type MinMaxTuple = [number, number];

export type CartesianCoordinates = {
	x: number;
	y: number;
};
