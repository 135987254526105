import { css } from 'lit';
import { Action } from 'redux';
import { BannerLocation, NotificationErrorType } from '../../typings/shared-types';
import { ThunkActionRoot } from './redux-store';
import { clearNotificationBanners, showMainBanner } from './redux-ui';

// STATE
export interface NetworkState {
	online: boolean;
}

export const NETWORK_STATE_INITIAL = {
	online: true,
};

// ACTION TYPES

export enum NetworkActionType {
	SET_ONLINE = 'SET_ONLINE',
}

interface SetOnline extends Action<typeof NetworkActionType.SET_ONLINE> {
	status: boolean;
}

export type NetworkAction = SetOnline;

// ACTIONS

export const setOnline = (status: boolean): ThunkActionRoot<void> => (dispatch): void => {
	dispatch({ type: NetworkActionType.SET_ONLINE, status });

	if (status) {
		dispatch(clearNotificationBanners(BannerLocation.MAIN));
		dispatch(
			showMainBanner(
				NotificationErrorType.SUCCESS,
				{
					title: 'Internet connection restored',
				},
				5000,
			),
		);
	} else if (!status) {
		dispatch(
			showMainBanner(NotificationErrorType.ERROR, {
				title:
					'No internet connection. Any changes you make will not be saved. Please reconnect and try again.',
			}),
		);
	}
};

// REDUCER

export const NetworkReducer = (
	state: NetworkState = NETWORK_STATE_INITIAL,
	action: NetworkAction,
): NetworkState => {
	switch (action.type) {
		case NetworkActionType.SET_ONLINE:
			return {
				...state,
				online: action.status,
			};
		default:
			return state;
	}
};
