import { css } from 'lit';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { ThunkResult, ThunkReturn } from './redux-store';

export enum LoaderAnimActionType {
	ADD_ITEM = 'ADD_ITEM',
	REMOVE_ITEM = 'REMOVE_ITEM',
	CLEAR_ITEMS = 'CLEAR_ITEMS',
}

//	shared action type signiture
type LoaderAnimActionBase = {
	dispatchingElement?: string;
	item?: string;
};

interface AddItem extends LoaderAnimActionBase, Action<typeof LoaderAnimActionType.ADD_ITEM> {
	dispatchingElement: string;
	item: string;
}

interface RemoveItem extends LoaderAnimActionBase, Action<typeof LoaderAnimActionType.REMOVE_ITEM> {
	dispatchingElement: string;
	item: string;
}

interface ClearItems extends LoaderAnimActionBase, Action<typeof LoaderAnimActionType.CLEAR_ITEMS> {
	item: string;
}

export type LoaderAnimAction = AddItem | RemoveItem | ClearItems;

export interface LoaderAnimState {
	dispatchingElements: DispatchingElements;
}

export const LOADER_ANIM_STATE_INITIAL: LoaderAnimState = {
	dispatchingElements: {},
};

type DispatchingElements = {
	[dispatchingElement: string]: string[]; //	collection of item slugs keyed to elements that dispatched them
};

//	ACTIONS

export const loaderAnimActionBase =
	//	shared action function signiture
	(type: LoaderAnimActionType): Function => (
		dispatchingElement?: string,
		item?: string,
	): ThunkResult<void> => (dispatch: ThunkReturn): void => {
		dispatch({
			type,
			dispatchingElement,
			item,
		});
	};

export const addItem = loaderAnimActionBase(LoaderAnimActionType.ADD_ITEM);
export const removeItem = loaderAnimActionBase(LoaderAnimActionType.REMOVE_ITEM);
export const clearItems = loaderAnimActionBase(LoaderAnimActionType.CLEAR_ITEMS);

//	REDUCER

export const LoaderAnimReducer = (
	state: LoaderAnimState = LOADER_ANIM_STATE_INITIAL,
	action: LoaderAnimAction,
): LoaderAnimState => {
	switch (action.type) {
		case LoaderAnimActionType.ADD_ITEM: {
			const dispatchingElements = { ...state.dispatchingElements };
			//	if this is the first item added, initilize the queue
			if (dispatchingElements[action.dispatchingElement] === undefined) {
				dispatchingElements[action.dispatchingElement] = [];
			}
			dispatchingElements[action.dispatchingElement].push(action.item);
			return {
				...state,
				dispatchingElements,
			};
		}
		case LoaderAnimActionType.REMOVE_ITEM: {
			const dispatchingElements = { ...state.dispatchingElements };
			if (dispatchingElements === undefined) {
				return state; //	no queue exists for this element
			}
			const items = dispatchingElements[action.dispatchingElement];
			if (!items || items.length < 1) {
				return state; //	the queue for this element is empty
			}
			const removeItemIndex = items.indexOf(action.item);
			if (removeItemIndex === -1) {
				return state; //	the queue for this element does not contain the item to be removed
			}
			items.splice(removeItemIndex, 1);
			return {
				...state,
				dispatchingElements,
			};
		}

		case LoaderAnimActionType.CLEAR_ITEMS: {
			let dispatchingElements: DispatchingElements = { ...state.dispatchingElements };
			if (action.dispatchingElement === undefined && action.item === undefined) {
				//  no element && no item = clear all items for all elements
				dispatchingElements = {};
			} else if (action.dispatchingElement && action.item === undefined) {
				//  element && no item = clear all items for matching element
				dispatchingElements[action.dispatchingElement] = [];
			} else if (
				action.dispatchingElement &&
				dispatchingElements[action.dispatchingElement] &&
				action.item
			) {
				//	element && item = clear matching items for that element
				dispatchingElements[action.dispatchingElement] = dispatchingElements[
					action.dispatchingElement
				].filter((item) => item === action.item);
			} else {
				//	should be no-op 🤞
				return state;
			}
			return {
				...state,
				dispatchingElements,
			};
		}
		default:
			return state;
	}
};

// SELECTORS

export const countItems = createSelector(
	[(state: LoaderAnimState): DispatchingElements => state.dispatchingElements],
	(dispatchingElements) =>
		Object.values(dispatchingElements).reduce((total, items) => total + items.length, 0),
);
