import { css } from 'lit';
import { differenceWith as _differenceWith } from 'lodash';
import { createSelector } from 'reselect';
import {
	CardinalDir,
	ChangeSetItem,
	EventLinkageDto,
	LaneImpactValue,
	PreviewDtoResponse,
	RoadEventDto,
	TimelineEntry,
} from '../../../typings/api';
import { components } from '../../../typings/irisx-api.gen';
import { MinMaxTuple, TimelineDay, TimelineDayEntryChange } from '../../../typings/shared-types';
import { ComboBoxOption } from '../../components/common/combo-box';
import { ConfigREMEventForm } from '../../config/ConfigREM';
import {
	TimelineSortOrder,
	resolveChangeSetItemToTimelineDayEntryChange,
} from '../../config/ConfigREMTimeline';
import { Direction, FullCardinalByKey, LOCALE, REMSectionKey } from '../../constants';
import formatTimezoneSup from '../../utils/format-timezone-sup';
import { isREMDraftEvent } from '../../utils/type-guards';
import { isValidNumber, isValidString, numberIsValidAndFinite } from '../../utils/utils';
import { signsSelector } from '../dms/dms-selectors';
import { RootState } from '../redux-store';

export const selectLaneCount = (state: RootState, direction: Direction): number | undefined =>
	state.rem.draftEvent?.locationDetails?.lanes?.[`${direction}`]?.laneCount;

export const selectCardinalDir = (state: RootState, direction: Direction): CardinalDir =>
	state.rem.draftEvent?.locationDetails?.lanes?.[`${direction}`]?.cardinalDir as CardinalDir;

export const selectRouteIdentifiersToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined => {
	const filteredRouteIdentifiers =
		state.rem.filteredRoutes?.map((filteredRoute) => filteredRoute.routeIdentifier) ?? undefined;
	return state.rem.eventFields?.routes?.reduce((comboBoxOptions: ComboBoxOption[], route) => {
		if (
			route.routeIdentifier !== undefined &&
			(filteredRouteIdentifiers === undefined ||
				filteredRouteIdentifiers.includes(route.routeIdentifier))
		) {
			comboBoxOptions.push({
				value: route.routeIdentifier,
			});
		}
		return comboBoxOptions;
	}, []);
};

export const selectEventTypesToComboBoxOptions = (state: RootState): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.eventTypes?.map(
		(eventType) =>
			({
				// ID number is not needed for this combobox, as the label string is what is passed to the backend
				value: eventType.name,
				label: eventType.name,
			} as ComboBoxOption),
	);

// eslint-disable-next-line jsdoc/require-jsdoc
function selectEventIDsToComboBoxOptionsComparator(a: RoadEventDto, b: EventLinkageDto): boolean {
	if (a.id === b.linkedEventId) {
		return true;
	}
	return false;
}
export const selectEventIDsToComboBoxOptions = (
	state: RootState,
	exclude?: EventLinkageDto[],
): ComboBoxOption[] | undefined => {
	const directionsForEvent = (event: RoadEventDto): string[] => {
		const directions: string[] = [];
		if (event.negativeLaneBlockage !== null) {
			const negativeDirection = event.locationDetails.lanes?.negative.cardinalDir
				? FullCardinalByKey[event.locationDetails.lanes.negative.cardinalDir]
				: undefined;
			if (negativeDirection !== undefined) {
				directions.push(negativeDirection);
			}
		}
		if (event.positiveLaneBlockage !== null) {
			const positiveDirection = event.locationDetails.lanes?.positive.cardinalDir
				? FullCardinalByKey[event.locationDetails.lanes.positive.cardinalDir]
				: undefined;
			if (positiveDirection !== undefined) {
				directions.push(positiveDirection);
			}
		}

		return directions;
	};

	const eventToComboboxOption = (event: RoadEventDto): ComboBoxOption => {
		return {
			value: event.id.toString(),
			label: `${event.eventType} on ${event.route} ${directionsForEvent(event).join('/')} MM ${
				event.startMileMarker
			} ${event.endMileMarker ? `- ${event.endMileMarker}` : ''} [${event.id.toString()}]`,
		};
	};

	if (exclude) {
		const filteredArray = _differenceWith(
			state.rem.events ?? [],
			exclude,
			selectEventIDsToComboBoxOptionsComparator,
		);
		return filteredArray.map((event) => eventToComboboxOption(event));
	}
	return state.rem.events?.map((event) => eventToComboboxOption(event));
};

export const selectRespondingUnitTypesToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem?.eventFields?.respondingUnitTypes?.map(
		(item) =>
			({
				value: item.name,
				label: item.name,
				recommended: false,
			} as ComboBoxOption),
	);

export const selectRespondingUnitDispositionsToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.respondingUnitDispositionTypes?.map(
		(disposition) =>
			({
				value: disposition.name,
				label: `${disposition?.name ?? '(no name)'} - ${
					disposition?.description ?? '(no description)'
				}`,
				recommended: false,
			} as ComboBoxOption),
	);

export const selectRespondingUnitMembersToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.respondingUnitTypes?.[0]?.members?.map(
		(unit) =>
			({
				value: unit.name,
				label: unit.name,
				recommended: false,
			} as ComboBoxOption),
	);

export const selectEventSourcesToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.eventSources?.map((source) => ({
		value: source,
		label: source,
		recommended: false,
	}));

export const selectRouteMileRanges = (state: RootState): MinMaxTuple[] | undefined =>
	state.rem.eventFields?.routes
		?.find((routeDTO) => routeDTO.routeIdentifier === state.rem.draftEvent?.route)
		?.mileRanges?.map((mileRange) => [mileRange[0], mileRange[1]]);

export const selectEventTimelineByDay = (state: RootState): TimelineDay[] | undefined =>
	(state.rem.eventTimeline?.timeline?.entries as TimelineEntry[])?.reduce(
		(timelineDays: TimelineDay[], timelineEntry: TimelineEntry): TimelineDay[] => {
			//	is there already a container for the day this change occurred on?
			const dateString = new Date(timelineEntry.timestamp).toLocaleDateString(
				LOCALE,
				ConfigREMEventForm.timelineDateFormatOptions,
			);
			const lastTimelineDay = timelineDays[timelineDays.length - 1];
			let timelineDay: TimelineDay;
			if (lastTimelineDay?.dateString === dateString) {
				timelineDay = lastTimelineDay;
			} else {
				timelineDay = {
					dateString,
					entries: [],
				};
				timelineDays.push(timelineDay);
			}
			//	what changes occured on this day?
			const timeString = formatTimezoneSup(
				new Date(timelineEntry.timestamp).toLocaleTimeString(
					LOCALE,
					ConfigREMEventForm.timelineTimeFormatOptions,
				),
			);
			const user = timelineEntry.changeSets?.[0].changeSetItems?.[0].createdBy ?? 'unknown user';
			const changes: TimelineDayEntryChange[] = [];
			timelineEntry.changeSets?.forEach((changeSet) => {
				changeSet.changeSetItems?.forEach((changeSetItem) => {
					if (changeSet.name !== undefined) {
						const timelineDayEntryChange = resolveChangeSetItemToTimelineDayEntryChange(
							changeSet.name,
							changeSetItem as ChangeSetItem,
						);
						if (changeSetItem.fieldName?.includes('linkedEvent')) {
							if ((changeSetItem as ChangeSetItem).value !== state.rem.eventTimeline?.id) {
								changes.push(timelineDayEntryChange);
							}
						} else if (timelineDayEntryChange !== undefined) {
							changes.push(timelineDayEntryChange);
						}
					}
				});
			});

			if (changes.length === 0) {
				if (process.env.NODE_ENV === 'development') {
					console.warn(
						`no ChangeSetItems could be resolved to TimelineDayEntryChanges for "${dateString} ${timeString}"`,
					);
				}
			}

			changes.sort((a, b) => {
				return TimelineSortOrder.indexOf(a.subject) - TimelineSortOrder.indexOf(b.subject);
			});

			timelineDay.entries.push({
				timeString,
				user,
				changes,
			});
			return timelineDays;
		},
		[] as TimelineDay[],
	);

export const selectHasSufficientDataForDMSPreview = (state: RootState): boolean =>
	//	required: event type, route, mile marker,
	(state.rem.draftEvent?.eventType !== undefined &&
		state.rem.draftEvent?.route !== undefined &&
		state.rem.draftEvent?.startMileMarker !== undefined &&
		//	and at least one lane slowed / closed
		//	positive lane impact
		((state.rem.draftEvent?.positiveLaneBlockageType !== undefined &&
			state.rem.draftEvent?.positiveLaneBlockageType !== LaneImpactValue['N/A'] &&
			state.rem.draftEvent?.positiveLaneBlockage?.lanesAffected &&
			state.rem.draftEvent?.positiveLaneBlockage.lanesAffected.length > 0) ||
			//	negative lane impact
			(state.rem.draftEvent?.negativeLaneBlockageType !== undefined &&
				state.rem.draftEvent?.negativeLaneBlockageType !== LaneImpactValue['N/A'] &&
				state.rem.draftEvent?.negativeLaneBlockage?.lanesAffected &&
				state.rem.draftEvent?.negativeLaneBlockage.lanesAffected.length > 0))) ??
	false;

export const selectShowSigns = (state: RootState): boolean =>
	((state.rem.draftEvent?.eventType !== undefined &&
		(
			ConfigREMEventForm.defaultSectionsPerEventType as Record<
				string,
				Record<REMSectionKey, boolean>
			>
		)?.[state.rem.draftEvent?.eventType]?.[REMSectionKey.DMS]) ||
		//	preview messages are null if the server returned an error when requesting them
		state.rem.eventDMSPreviewMessages !== null ||
		(state.rem.eventDMSPreviewMessages !== undefined &&
			Object.keys(state.rem.eventDMSPreviewMessages)?.length > 0) ||
		state.rem.formSections[REMSectionKey.DMS]) ??
	false;

export const selectShowVehicles = (state: RootState): boolean =>
	((state.rem.draftEvent?.eventType !== undefined &&
		(
			ConfigREMEventForm.defaultSectionsPerEventType as Record<
				string,
				Record<REMSectionKey, boolean>
			>
		)?.[state.rem.draftEvent?.eventType]?.[REMSectionKey.VEHICLES]) ||
		(state.rem.draftEvent?.vehicles !== undefined && state.rem.draftEvent?.vehicles?.length > 0) ||
		state.rem.formSections[REMSectionKey.VEHICLES]) ??
	false;

export const selectShowRespondingUnits = (state: RootState): boolean =>
	((state.rem.draftEvent?.eventType !== undefined &&
		(
			ConfigREMEventForm.defaultSectionsPerEventType as Record<
				string,
				Record<REMSectionKey, boolean>
			>
		)?.[state.rem.draftEvent?.eventType]?.[REMSectionKey.RESPONDING_UNITS]) ||
		(state.rem.draftEvent?.respondingUnits !== undefined &&
			state.rem.draftEvent?.respondingUnits?.length > 0) ||
		state.rem.formSections[REMSectionKey.RESPONDING_UNITS]) ??
	false;

export const selectIsVerified = (state: RootState): boolean =>
	numberIsValidAndFinite(state.rem.draftEvent?.verified);

export const selectAllowCreate = (state: RootState): boolean =>
	//	if it doesn't have an id yet, it hasn't been submitted to the server yet
	isREMDraftEvent(state.rem.draftEvent) && state.rem.draftEvent.id === undefined;

export const selectAllowUpdate = (state: RootState): boolean =>
	//	if the draft event has changes from the serverside version of the event
	isREMDraftEvent(state.rem.draftEvent) &&
	state.rem.draftEvent.id !== undefined &&
	state.rem.unsavedDraftEvent &&
	isREMDraftEvent(state.rem.draftEvent);

export const selectAllowComplete = (state: RootState): boolean =>
	//	if it has an id, then it can be completed
	isREMDraftEvent(state.rem.draftEvent) && state.rem.draftEvent.id !== undefined;
//	&& state.user.authority.permissions.includes(UserPermissions.REM_CAN_DELETE_EVENTS);

export const selectHasRecipientsFromEmailGroupsRecipients = (state: RootState): boolean =>
	//	if either email groups or individual recipients have been added
	(state.rem.draftEvent?.emailGroupsRecipients?.emailGroups !== undefined &&
		state.rem.draftEvent?.emailGroupsRecipients?.emailGroups?.length > 0) ||
	(state.rem.draftEvent?.emailGroupsRecipients?.emailRecipients !== undefined &&
		state.rem.draftEvent?.emailGroupsRecipients?.emailRecipients?.length > 0);

export const selectLocationHasValidLanes = (state: RootState): boolean =>
	(state.rem.draftEvent?.locationDetails?.lanes?.positive !== undefined &&
		state.rem.draftEvent?.locationDetails?.lanes?.positive > 0) ||
	(state.rem.draftEvent?.locationDetails?.lanes?.negative !== undefined &&
		state.rem.draftEvent?.locationDetails?.lanes?.negative > 0);

export const selectCountiesAsComboBoxOptions = (state: RootState): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.counties?.map((value) => ({
		value,
	}));

export const selectDistrictsAsComboBoxOptions = (state: RootState): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.districts?.map((value) => ({
		value,
	}));

export const selectHasValidLocationData = (state: RootState): boolean =>
	isValidString(state.rem.draftEvent?.route) &&
	isValidNumber(state.rem.draftEvent?.startMileMarker) &&
	state.rem.draftEvent?.locationDetails !== undefined;

export const selectUnaddedEmailRecipientGroupsAsComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.emailGroupsRecipients?.emailGroups?.reduce(
		(options, existingEmailGroup): ComboBoxOption[] => {
			if (
				state.rem.draftEvent?.emailGroupsRecipients?.emailGroups?.find(
					(draftEventEmailGroup) => draftEventEmailGroup.name === existingEmailGroup.name,
				) === undefined &&
				existingEmailGroup.name !== undefined
			) {
				options.push({
					value: existingEmailGroup.name,
				});
			}
			return options;
		},
		[] as ComboBoxOption[],
	);

export const selectShowReadOnly = (state: RootState): boolean =>
	state.routing.page === 'rem' &&
	(state.rem.readOnlyMode === true ||
		state.user.authority?.userGroups?.includes('irisx_guest') === true);

export const signsForRemDmsPreview = createSelector(
	[(state: RootState): number[] | undefined => state.rem?.eventDMSSignLoadingIds, signsSelector],
	(loadingIds, signs): components['schemas']['SignDto'][] => {
		if (!loadingIds || !signs) {
			return [];
		}
		return loadingIds.reduce((acc, signId) => {
			const foundSign = signs.find((sign) => sign.id === signId);
			acc.push(foundSign || { id: signId });
			return acc;
		}, new Array<components['schemas']['SignDto']>());
	},
);

export const selectedSigns = createSelector(
	[
		(state: RootState): number[] | undefined => state.rem?.eventDMSSignLoadingIds,
		(state: RootState): PreviewDtoResponse | undefined => state.rem?.eventDMSPreviewMessages,
	],
	(eventDMSSignLoadingIds, eventDMSPreviewMessages): number[] => {
		return [
			...new Set([
				...(eventDMSPreviewMessages
					? Object.keys(eventDMSPreviewMessages).map((keyId) => parseInt(keyId, 10))
					: []),
				...(eventDMSSignLoadingIds || []),
			]),
		];
	},
);

export const eventDMSPreviewMessagesWithErrors = createSelector(
	[
		signsSelector,
		(state: RootState): PreviewDtoResponse | undefined => state.rem?.eventDMSPreviewMessages,
	],
	(signs, eventDMSPreviewMessages): PreviewDtoResponse | undefined => {
		if (!eventDMSPreviewMessages) {
			return undefined;
		}
		return Object.entries(eventDMSPreviewMessages).reduce((acc, [key, previews]) => {
			const signId = parseInt(key, 10);
			if (previews?.length) {
				acc[key] = previews;
			} else {
				const sign = signs?.find((aSign) => aSign.id === signId);
				acc[key] = [{ signName: sign?.name, signId }];
			}
			return acc;
		}, {} as PreviewDtoResponse);
	},
);

export const selectHasSufficientDataForLocationDetails = (state: RootState): boolean =>
	isValidString(state.rem.draftEvent?.route) &&
	numberIsValidAndFinite(state.rem.draftEvent?.startMileMarker);

export const selectSignIdsFromEventDMSMessages = (state: RootState): number[] =>
	state.rem?.eventDMSPreviewMessages
		? Object.keys(state.rem.eventDMSPreviewMessages).map((previewMessageKey) =>
				parseInt(previewMessageKey, 10),
		  )
		: [];
