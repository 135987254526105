import { css } from 'lit';
export const ConfigCCTV = {
	Grids: [1, 4, 9, 16], //  controls which grid layout options are listed for monitors

	MonitorsPollingRate: 2000, //  refresh monitor list every X miliseconds

	//  camera thumbnails will be constructed thus: [CamThumbRoot]+[Camera.name]+[CamThumbExt]
	CamThumbRoot: 'http://content.trafficwise.org/cctv/',
	CamThumbExt: '.jpg',

	CameraPollingRate: 60000, //  refresh camera list every X miliseconds

	Map: {
		//  Leaflet map will center on this point, at the provided zoom level
		//  https://leafletjs.com/reference-1.4.0.html#map-setview
		DefaultLat: 39.76632525654491,
		DefaultLon: -86.15615844726564,
		DefaultZoom: 12,
		MinZoom: 7,
		MaxZoom: 18,
		SpiderifyDistancePx: 10,
		SpiderifyLegThicknessPx: 3,
		SpiderifyLegColorNormal: 'rgba(var(--brand-grey-dark),0.6)',
		SpiderifyLegColorFocus: 'rgba(var(--brand-grey-dark),1)',
		//  map location search will prioritize results by proximity to this point:
		GeoSearch: {
			APIRoot: 'https://photon.komoot.io/api/',
			ResultsLanguage: 'en',
			SearchLocation: { lat: 39.76632525654491, lon: -86.15615844726564 },
		},
		Locations: [
			//  TODO: need real values for these
			//  Leaflet map will pan + zoom to fit this area in the map view
			//  https://leafletjs.com/reference-1.4.0.html#map-flytobounds
			{
				name: 'Central Indiana',
				NorthLat: 40,
				EastLon: -86,
				SouthLat: 39.5,
				WestLon: -86.5,
			},
			{
				name: 'Northwest Indiana',
				NorthLat: 41.749158,
				EastLon: -87.526292,
				SouthLat: 41.174395,
				WestLon: -86.848747,
			},
			{
				name: 'Southern Indiana',
				NorthLat: 37.914615,
				EastLon: -84.88947,
				SouthLat: 39.137788,
				WestLon: -86.612867,
			},
			{
				name: 'Southwest Indiana',
				NorthLat: 38.269401,
				EastLon: -87.367076,
				SouthLat: 37.916643,
				WestLon: -87.945329,
			},
		],
	},
	Table: {
		Pagination: [5, 15, 50, 100],
	},
};

export const ConfigCCTVApi = {
	//  CAMERAS
	getCamerasEndpoint(): string {
		return 'cameras';
	},
	//  MONSTREAM CONTROLLERS
	getMonitorsEndpoint(): string {
		return `controllers`;
	}, //  GET
	getSetMonitorEndpoint(name: string): string {
		return `controllers/${name}/cameras`;
	}, //  POST
	getSetMonitorLayoutEndpoint(name: string): string {
		return `controllers/${name}/layout`;
	}, //  POST
	getCameraThumbUrl(id: string): string {
		return `cameras/${id}/stillshot`;
	},
	endpointTimeoutMs: 10000,
};

export default ConfigCCTV;
