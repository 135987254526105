import { css } from 'lit';
import { Action } from 'redux';

export enum MobileStateActionType {
	SET_MOBILE_STATE = 'SET_MOBILE_STATE',
}

interface SetMobileState extends Action<typeof MobileStateActionType.SET_MOBILE_STATE> {
	mobilestate: MobileState;
}

export type MobileStateAction = SetMobileState;

export interface MobileStateState {
	mobilestate?: MobileState;
}

export const MOBILE_STATE_STATE_INITIAL: MobileStateState = {
	mobilestate: undefined,
};

export enum MobileState {
	MONITORS = 'MONITORS',
	CAMERAS = 'CAMERAS',
	FILTERS = 'FILTERS',
}

//	ACTIONS

export const setMobileState = (mobilestate: MobileState): SetMobileState => ({
	type: MobileStateActionType.SET_MOBILE_STATE,
	mobilestate,
});

//	REDUCER

export const MobileStateReducer = (
	state: MobileStateState = MOBILE_STATE_STATE_INITIAL,
	action: MobileStateAction,
): MobileStateState => {
	switch (action.type) {
		case MobileStateActionType.SET_MOBILE_STATE:
			return {
				...state,
				mobilestate: action.mobilestate,
			};
		default:
			return state;
	}
};
