import { css } from 'lit';
import { Action } from 'redux';
import { MatchFunction, Path } from 'path-to-regexp';
import UniversalRouter, {
	Route,
	RouteContext,
	RouteError,
	RouteParams,
	RouteResult,
	Routes,
} from 'universal-router';
import { ThunkDispatch } from 'redux-thunk';
import { URLSearchParams } from 'url';
import { RootAction, RootState, ThunkActionRoot } from './redux-store';
import { clearItems } from './redux-loaderAnim';
import ConfigIRISx from '../config/ConfigIRISx';
import Authorization from '../rest/Authorization';
import hasOwnProperty from '../utils/has-own-property';
import {
	getCustomMessages,
	getGroups,
	getRoutes,
	getSignGraphics,
	pollSigns,
} from './dms/dms-actions';
import { AppSection, CCTVView, DMSView, HHView, ModalSection, REMView } from '../constants';
import {
	startPollingREMEvent,
	startPollingREMEvents,
} from '../components/irisx-rem/RemPollingManager';
import { ConfigREMMap, ConfigREMTable } from '../config/ConfigREM';
import { setLoadingRoute } from './redux-ui';
import { pollHH } from './hh/redux-hh';
import { startPollingHH } from '../components/irisx-hh/HHPollingManager';
import { ConfigHHTable } from '../config/ConfigHH';
import { getBreakpointName } from './redux-ui';
import { getEventFields } from './rem/rem-actions-event';
import { getEmailRecipients } from './rem/rem-actions-event-email';

//	STATE

export interface RoutingState {
	page?: AppSection | null;
	group?: string | null;
	view?: string | null;
	action?: string | null;
	id?: number | null;
	modalView?: ModalSection | null;
	modalId?: number | null;
}

export const ROUTING_STATE_INITIAL: RoutingState = {
	// page: undefined,
};

//	ACTION TYPING

export enum RoutingType {
	SET_PAGE = 'SET_PAGE',
	SET_MODAL = 'SET_MODAL',
}

interface Navigate extends Action<typeof RoutingType.SET_PAGE> {
	page?: AppSection;
	group?: string;
	view?: string;
	id?: number;
	action?: string | null;
}

interface NavigateModal extends Action<typeof RoutingType.SET_MODAL> {
	modalView?: ModalSection;
	modalId?: number;
}

export type RoutingAction = Navigate | NavigateModal;

export interface SafeRoute<R, C, T> {
	path?: Path;
	name?: string;
	parent?: Route<R, C> | null;
	children?: Array<SafeRoute<R, C, T>> | null;
	action?: (context: RouteContext<R, C> & C, params: T) => RouteResult<R>;
	match?: MatchFunction<RouteParams>;
}

export interface OwRouteContext {
	dispatch: ThunkDispatch<RootState, undefined, RootAction>;
	getState: () => RootState;
	searchParams: URLSearchParams;
}

export type RouteReturn =
	| { redirect?: string }
	/** continue to try to match the next path */
	| null
	| void
	/** Do no further processing of route */
	| false
	/** do not continue to try to match */
	| true;

type CCTVParams = { group: string; view: string };

type Params = CCTVParams & { id: string; action: string };

const NO_ID_INDICATOR = '_';

//	ACTIONS

export const routes: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		action({ pathname }): RouteReturn {
			if (!pathname.includes(ConfigIRISx.Pages[AppSection.LOGIN].route) && !Authorization.JWT) {
				return { redirect: ConfigIRISx.Pages[AppSection.LOGIN].route };
			}
			return null;
		},
	},
	{
		path: '',
		action(): RouteReturn {
			const defaultRoute =
				Object.values(ConfigIRISx.Pages).find(
					//	TODO: this is a bit silly, hasOwnProperty is only necessary because we haven't strictly defined typings for ConfigIRISx.Pages
					(Page) => hasOwnProperty(Page, 'default') && Page.default === true,
				)?.route ?? '/';
			const firstRoutePart = `/${defaultRoute.split('/')[1]}`;

			return { redirect: firstRoutePart };
		},
	},
	{
		path: ConfigIRISx.Pages[AppSection.LOGIN].route,
		async action({ dispatch }): Promise<RouteReturn> {
			Authorization.logout();
			const pageConfig = ConfigIRISx.Pages[AppSection.LOGIN];

			document.title = `${ConfigIRISx.AppTitle} - ${pageConfig.pageTitle}`;
			await import(`../components/irisx-login/irisx-login`);
			dispatch({ type: RoutingType.SET_PAGE, page: AppSection.LOGIN, view: null });
			return true;
		},
	},
	{
		path: ConfigIRISx.Pages.cctv.route,
		action(): RouteReturn {
			return { redirect: `${ConfigIRISx.Pages.cctv.route}/${NO_ID_INDICATOR}` };
		},
	},
	{
		path: `${ConfigIRISx.Pages.cctv.route}/:group`,
		async action(): Promise<RouteReturn> {
			const pageConfig = ConfigIRISx.Pages[AppSection.CCTV];

			document.title = `${ConfigIRISx.AppTitle} - ${pageConfig.pageTitle}`;
			await import(`../components/irisx-cctv/irisx-cctv`);
		},
		children: [
			{
				path: '',
				action({ getState }, { group }): RouteReturn {
					const bp = getBreakpointName(getState());
					const defaultViewSegment =
						ConfigIRISx.Pages.cctv.defaultRouteSegments?.view?.[bp] || CCTVView.map;
					return { redirect: `${ConfigIRISx.Pages.cctv.route}/${group}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${CCTVView.map}`,
				action({ pathname, dispatch, getState }, { group }): RouteReturn {
					if (group !== NO_ID_INDICATOR && getState().routing.group === NO_ID_INDICATOR) {
						// Replace the loading step with the new group id.
						window.history.replaceState({}, '', pathname);
					}
					dispatch({
						type: RoutingType.SET_PAGE,
						page: AppSection.CCTV,
						view: CCTVView.map,
						group,
					});
					return true;
				},
			},
			{
				path: `/${CCTVView.table}`,
				action({ pathname, dispatch, getState }, { group }): RouteReturn {
					if (group !== NO_ID_INDICATOR && getState().routing.group === NO_ID_INDICATOR) {
						// Replace the loading step with the new group id.
						window.history.replaceState({}, '', pathname);
					}
					dispatch({
						type: RoutingType.SET_PAGE,
						page: AppSection.CCTV,
						view: CCTVView.table,
						group,
					});
					return true;
				},
			},
		],
	},
	{
		path: ConfigIRISx.Pages.dms.route,
		async action({ dispatch }): Promise<RouteReturn> {
			const pageConfig = ConfigIRISx.Pages[AppSection.DMS];

			document.title = `${ConfigIRISx.AppTitle} - ${pageConfig.pageTitle}`;
			await Promise.all([import(`../components/irisx-dms/irisx-dms`), dispatch(pollSigns())]);
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const defaultViewSegment =
						ConfigIRISx.Pages.dms.defaultRouteSegments?.view?.[getBreakpointName(getState())] ||
						DMSView.table;
					return { redirect: `${ConfigIRISx.Pages.dms.route}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${DMSView.map}`,
				action({ dispatch }): RouteReturn {
					dispatch({ type: RoutingType.SET_PAGE, page: AppSection.DMS, view: DMSView.map });
					return true;
				},
			},
			{
				path: `/${DMSView.table}`,
				action({ dispatch }): RouteReturn {
					dispatch({ type: RoutingType.SET_PAGE, page: AppSection.DMS, view: DMSView.table });
					return true;
				},
			},
			{
				path: `/${DMSView.group}`,
				action({ dispatch }): RouteReturn {
					dispatch({ type: RoutingType.SET_PAGE, page: AppSection.DMS, view: DMSView.group });
					return true;
				},
			},
			{
				path: `/${DMSView['image-library']}`,
				action({ dispatch }): RouteReturn {
					dispatch({
						type: RoutingType.SET_PAGE,
						page: AppSection.DMS,
						view: DMSView['image-library'],
					});
					return true;
				},
			},
			{
				path: `/${DMSView.sign}`,
				children: [
					{
						path: '/new',
						async action({ dispatch }): Promise<RouteReturn> {
							await Promise.all([dispatch(getRoutes()), dispatch(getGroups())]);

							dispatch({ type: RoutingType.SET_PAGE, page: AppSection.DMS, view: DMSView.sign });
							return true;
						},
					},
					{
						path: '/:id?',
						async action({ dispatch }, { id: rawId }): Promise<RouteReturn> {
							await Promise.all([dispatch(getRoutes()), dispatch(getGroups())]);

							const id = parseInt(rawId, 10);
							if (Number.isNaN(id)) {
								return { redirect: `${ConfigIRISx.Pages.dms.route}/${DMSView.sign}/new` };
							}
							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.DMS,
								view: DMSView.sign,
								id,
							});
							return true;
						},
					},
				],
			},
			{
				path: `/${DMSView['sign-queue']}/:id?`,
				action({ dispatch }, params): RouteReturn {
					const id = parseInt(params.id, 10);

					dispatch({
						type: RoutingType.SET_PAGE,
						page: AppSection.DMS,
						view: DMSView['sign-queue'],
						id,
					});
					return true;
				},
			},
			{
				path: `/${DMSView['custom-messages']}`,
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([dispatch(getCustomMessages()), dispatch(getSignGraphics())]);
					dispatch({
						type: RoutingType.SET_PAGE,
						page: AppSection.DMS,
						view: DMSView['custom-messages'],
					});
					return true;
				},
			},
			{
				path: `/${DMSView['custom-message']}`,
				children: [
					{
						path: '/new',
						action({ dispatch }): RouteReturn {
							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.DMS,
								view: DMSView['custom-message'],
							});
							return true;
						},
					},
					{
						path: '/:id?',
						async action({ dispatch }, { id: rawId }): Promise<RouteReturn> {
							const id = parseInt(rawId, 10);
							if (Number.isNaN(id)) {
								return {
									redirect: `${ConfigIRISx.Pages.dms.route}/${DMSView['custom-message']}/new`,
								};
							}
							await dispatch(getCustomMessages([id]));
							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.DMS,
								view: DMSView['custom-message'],
								id,
							});
							return true;
						},
					},
				],
			},
		],
	},
	{
		path: ConfigIRISx.Pages.rem.route,
		action(): RouteReturn {
			const pageConfig = ConfigIRISx.Pages[AppSection.REM];
			document.title = `${ConfigIRISx.AppTitle} - ${pageConfig.pageTitle}`;
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const defaultViewSegment =
						ConfigIRISx.Pages.rem.defaultRouteSegments?.view?.[getBreakpointName(getState())] ||
						REMView.table;
					return { redirect: `${ConfigIRISx.Pages.rem.route}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${REMView.map}`,
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([
						dispatch(startPollingREMEvents(ConfigREMMap.pollingDelayMs)),
						import(`../components/irisx-rem/irisx-rem`),
					]);
					dispatch({ type: RoutingType.SET_PAGE, page: AppSection.REM, view: REMView.map });
					return true;
				},
			},
			{
				path: `/${REMView.table}`,
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([
						dispatch(startPollingREMEvents(ConfigREMMap.pollingDelayMs)),
						import(`../components/irisx-rem/irisx-rem`),
					]);
					dispatch({ type: RoutingType.SET_PAGE, page: AppSection.REM, view: REMView.table });
					return true;
				},
			},
			{
				path: `/${REMView.event}`,
				children: [
					{
						path: '/new',
						async action({ dispatch, getState }): Promise<RouteReturn> {
							if (getState().user.authority?.userGroups.includes('irisx_guest')) {
								//	readonly users don't have the ability to create new events
								return { redirect: `${ConfigIRISx.Pages.rem.route}` };
							}
							await Promise.all([
								import(`../components/irisx-rem/irisx-rem`),
								dispatch(getEventFields()),
								dispatch(getEmailRecipients()),
							]);
							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.REM,
								view: REMView.event,
							});
							return true;
						},
					},
					{
						path: '/:id?',
						async action({ dispatch, searchParams }, params): Promise<RouteReturn> {
							const id = parseInt(params.id, 10);
							if (Number.isNaN(id)) {
								return { redirect: `${ConfigIRISx.Pages.rem.route}/${REMView.event}/new` };
							}

							await Promise.all([
								import(`../components/irisx-rem/irisx-rem`),
								dispatch(getEventFields()),
								dispatch(getEmailRecipients()),
								startPollingREMEvent(id, ConfigREMTable.pollingDelayMs),
							]);
							if (searchParams.has('read-only')) {
								//	ideally this should be dispatch(setREMReadOnlyMode())
								//	but circular dependency plugin throws a false positive over it so 🤷‍♂️
								dispatch({ type: 'SET_REM_READ_ONLY_MODE', readOnlyMode: true });
							} else {
								dispatch({ type: 'SET_REM_READ_ONLY_MODE', readOnlyMode: false });
							}
							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.REM,
								view: REMView.event,
								id,
							});
							return true;
						},
					},
				],
			},
		],
	},
	{
		path: ConfigIRISx.Pages[AppSection.HH].route,
		action(): RouteReturn {
			const pageConfig = ConfigIRISx.Pages[AppSection.HH];

			document.title = `${ConfigIRISx.AppTitle} - ${pageConfig.pageTitle}`;
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const defaultViewSegment =
						ConfigIRISx.Pages[AppSection.HH].defaultRouteSegments?.view?.[
							getBreakpointName(getState())
						] || HHView.table;
					return { redirect: `${ConfigIRISx.Pages[AppSection.HH].route}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${HHView.table}`,
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([dispatch(pollHH()), import(`../components/irisx-hh/irisx-hh`)]);
					dispatch({ type: RoutingType.SET_PAGE, page: AppSection.HH, view: HHView.table });
					return true;
				},
			},
			{
				path: `/${HHView.helper}`,
				children: [
					{
						path: '/:id?/:action?',
						async action({ dispatch }, params): Promise<RouteReturn> {
							const id = parseInt(params.id, 10);
							const { action } = params;

							await Promise.all([
								dispatch(pollHH()),
								startPollingHH(id, ConfigHHTable.pollingDelayMs),
								import(`../components/irisx-hh/irisx-hh`),
							]);

							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.HH,
								view: HHView.helper,
								id,
								action,
							});
							return true;
						},
					},
				],
			},
		],
	},
	{
		path: '/logout',
		action(): RouteReturn {
			Authorization.logout();
			return { redirect: ConfigIRISx.Pages[AppSection.LOGIN].route };
		},
	},
];

const router = new UniversalRouter(routes as Routes<RouteReturn, OwRouteContext>, {
	errorHandler: (error): RouteReturn => {
		if (process.env.NODE_ENV === 'development') {
			console.error('router error:', error);
		}
		return { redirect: '' };
	},
});

export const hashRoutes: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: '',
		action({ dispatch }): RouteReturn {
			dispatch({ type: RoutingType.SET_MODAL, modalView: null });
			return true;
		},
	},
	{
		path: ModalSection.GROUP,
		children: [
			{
				path: '/new',
				action({ dispatch }): RouteReturn {
					dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.GROUP });
					return true;
				},
			},
			{
				path: '/:id',
				action({ dispatch }, { id }): RouteReturn {
					const modalId = parseInt(id, 10);
					dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.GROUP, modalId });
					return true;
				},
			},
		],
	},
	{
		path: `${ModalSection.GROUP_MESSAGE}/:id`,
		action({ dispatch }): RouteReturn {
			dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.GROUP_MESSAGE });
			return true;
		},
	},
];

const hashRouter = new UniversalRouter(hashRoutes, {
	errorHandler: (error: RouteError): RouteReturn => {
		if (process.env.NODE_ENV === 'development') {
			console.error('router error:', error);
		}
		return { redirect: '' };
	},
});

export const navigate =
	(href: string): ThunkActionRoot<void> =>
	async (dispatch, getState): Promise<void> => {
		const origin = window.location.origin || `${window.location.protocol}//${window.location.host}`;
		const normHref = new URL(!href.startsWith(origin) ? `${origin}${href}` : href);
		const { searchParams } = normHref;

		dispatch(clearItems()); // clear loaders

		dispatch(setLoadingRoute(true));

		let hashRet: RouteReturn;
		let hashPath = '';
		if (normHref.hash.substring(1)) {
			do {
				hashPath =
					typeof hashRet === 'object' && 'redirect' in hashRet
						? hashRet.redirect
						: normHref.hash.substring(1);
				// eslint-disable-next-line no-await-in-loop
				hashRet = await hashRouter.resolve({
					pathname: hashPath,
					searchParams,
					dispatch,
					getState,
				});
			} while (typeof hashRet === 'object' && 'redirect' in hashRet);
		} else {
			dispatch({ type: RoutingType.SET_MODAL, modalView: null });
		}

		let ret: RouteReturn;
		let path: string;
		if (!href.startsWith('#')) {
			do {
				path = typeof ret === 'object' && 'redirect' in ret ? ret.redirect : normHref.pathname;
				// eslint-disable-next-line no-await-in-loop
				ret = await router.resolve({ pathname: path, searchParams, dispatch, getState });
			} while (typeof ret === 'object' && 'redirect' in ret);
		}

		const { hash, pathname } = window.location;
		if (path && `${path}#${hashPath}` !== pathname + hash) {
			window.history.pushState({}, '', `${path}${hashPath ? `#${hashPath}` : ``}`);
		} else if (hashPath !== hash.substring(1)) {
			window.history.pushState({}, '', `${pathname}${hashPath ? `#${hashPath}` : ``}`);
		}

		dispatch(setLoadingRoute(false));
	};

//	REDUCER

export const RoutingReducer = (
	state: RoutingState = ROUTING_STATE_INITIAL,
	action: RoutingAction,
): RoutingState => {
	switch (action.type) {
		case RoutingType.SET_PAGE:
			return {
				...state,
				page: action.page === undefined ? state.page : action.page,
				view: action.view === undefined ? state.view : action.view,
				group: action.group === undefined ? state.group : action.group,
				id: action.id ?? null,
				action: action.action ?? null,
			};
		case RoutingType.SET_MODAL:
			return {
				...state,
				modalView: action.modalView === undefined ? state.modalView : action.modalView,
				modalId: action.modalId || null,
			};
		default:
			return state;
	}
};
