import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { CurrentEditorsDto } from '../../../typings/api';
import ConfigIRISx, { APIConfig } from '../../config/ConfigIRISx';
import { ConfigREMApi } from '../../config/ConfigREM';
import { AppSection } from '../../constants';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { navigate } from '../redux-routing';
import { ThunkActionRoot, ThunkDispatchRoot } from '../redux-store';
import { REMActionType } from './rem-actions';
import REMState from './rem-state';

export interface GetREMEventCurrentEditors
	extends Action<typeof REMActionType.GET_REM_EVENT_CURRENT_EDITORS> {
	eventId: number;
}

export interface SetREMEventCurrentEditors
	extends Action<typeof REMActionType.SET_REM_EVENT_CURRENT_EDITORS> {
	currentEditors: REMState['currentEditors'];
}

export const getREMEventCurrentEditors =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_EVENT_CURRENT_EDITORS,
			eventId,
		});
		const url = new URL(ConfigREMApi.currentEditors(eventId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const currentEditors: CurrentEditorsDto = await apiRequestReturn.response?.clone().json();
			dispatch({
				type: REMActionType.SET_REM_EVENT_CURRENT_EDITORS,
				currentEditors,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing current editors of event #${eventId} from server:`, error);
			}
		}
		return apiRequestReturn;
	};
export const setREMEventCurrentEditors =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot): Promise<APIRequestReturn> => {
		const url = new URL(ConfigREMApi.currentEditors(eventId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const currentEditors: CurrentEditorsDto = await apiRequestReturn.response?.clone().json();
			dispatch({
				type: REMActionType.SET_REM_EVENT_CURRENT_EDITORS,
				currentEditors,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing current editors of event #${eventId} from server:`, error);
			}
		}
		return apiRequestReturn;
	};
