import { css } from 'lit';
import IrisxHHTableEditButton from '../components/irisx-hh/irisx-hh-table-edit-button';
import IrisxHHTablePatrolButton from '../components/irisx-hh/irisx-hh-table-patrol-button';
import ConfigCCTV from './ConfigCCTV';

interface ConfigHH {
	Table: {
		Pagination: number[];
	};
}

export const ConfigHH: ConfigHH = {
	Table: {
		Pagination: [5, 15, 50, 100],
	},
};

export enum PatrolActions {
	END = 'end',
	START = 'start',
	EDIT = 'edit',
}

export const ConfigHHApi = {
	Endpoint: 'highway-helper',
	FieldsEndpoint: 'highway-helper/fields',
	PollingRate: ConfigCCTV.CameraPollingRate, //  refresh camera list every X miliseconds
	HighwayHelperById: (hhid: number): string => `highway-helper/${hhid}`,
	TimelineById: (hhid: number): string => `highway-helper/${hhid}/timeline`,
	endpointTimeoutMs: 60000,
	dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
	dateOptions: {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	},
};

export const ConfigHHTable = {
	pagination: [5, 15, 50, 100],
	defaultPageSize: 15,
	pollingDelayMs: 10000,
	defaultColumns: [
		'name',
		'status',
		'disposition',
		'commission',
		'zone',
		'GPS',
		'REM',
		'dashcam',
		'ptzcam',
		'edit',
		'patrol',
	],

	editCellRenderer: (params): HTMLElement => {
		const element = document.createElement('irisx-hh-table-edit-button') as IrisxHHTableEditButton;
		element.data = params.data;
		return element;
	},

	patrolCellRenderer: (params): HTMLElement => {
		const element = document.createElement(
			'irisx-hh-table-patrol-button',
		) as IrisxHHTablePatrolButton;
		element.data = params.data;
		return element;
	},
};
