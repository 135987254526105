import { css } from 'lit';
/* eslint-disable camelcase */
import { ColDef } from 'ag-grid-community';
import { DMSStatusType, RoadEventDto } from '../../typings/api';
import { EventAttribute } from '../components/irisx-rem/irisx-rem-attributes';
import {
	LOCALE,
	LaneImpact,
	MILLISECONDS_PER_SECOND,
	REMSectionKey,
	RelativeDateTime,
	RelativeDuration,
	remEventColumns,
} from '../constants';
import formatTimezoneSup from '../utils/format-timezone-sup';
import { FilterType, MapRegion } from './config.d';

export const ConfigREM = {
	filterTypes: [
		{
			value: 'events',
			label: 'REM Events',
			default: true,
		},
		{
			value: 'signs',
			label: 'DMS Signs',
			default: false,
		},
	] as FilterType[],
};

export enum EventIconPath {
	EVENT = 'img/icon-event-fill-solid.svg',
	CRASH = 'img/event-type-icon-crash.svg',
	INFO = 'img/event-type-icon-info.svg',
	WARNING = 'img/event-type-icon-warning.svg',
}

export const EventIcons: Record<string, string> = {
	'ABANDONED VEHICLE': EventIconPath.EVENT,
	'AMBER ALERT': EventIconPath.EVENT,
	'ASSIST MOTORIST': EventIconPath.INFO,
	'ASSIST OTHER AGENCY': EventIconPath.INFO,
	'CONSTRUCTION': EventIconPath.EVENT,
	'CRASH FATAL': EventIconPath.CRASH,
	'CRASH PD': EventIconPath.CRASH,
	'CRASH PI': EventIconPath.CRASH,
	'CRASH SITE CLEANUP': EventIconPath.CRASH,
	'DEBRIS IN THE ROAD': EventIconPath.EVENT,
	'DUPLICATE EVENT/NOT USED': EventIconPath.INFO,
	'EOC ACTIVATION': EventIconPath.EVENT,
	'FACILITY CLOSURE': EventIconPath.EVENT,
	'FIRE': EventIconPath.EVENT,
	'HEAVY TRAFFIC': EventIconPath.EVENT,
	'HIGH WATER': EventIconPath.EVENT,
	'LOCAL EVENT': EventIconPath.EVENT,
	'MAINTENANCE': EventIconPath.EVENT,
	'MAINTENANCE REQUEST': EventIconPath.INFO,
	'MEDICAL EMERGENCY': EventIconPath.EVENT,
	'PEDESTRIAN': EventIconPath.EVENT,
	'SLIDE OFF': EventIconPath.EVENT,
	'SLOW TRAFFIC': EventIconPath.EVENT,
	'STALLED VEHICLE': EventIconPath.EVENT,
	'SUPERLOAD': EventIconPath.EVENT,
	'TRAFFIC HAZARD': EventIconPath.EVENT,
	'TRAINING': EventIconPath.INFO,
	'TROUBLE TICKET': EventIconPath.INFO,
};

export const ConfigREMMap = {
	//  Leaflet map will center on this point, at the provided zoom level
	//  https://leafletjs.com/reference-1.4.0.html#map-setview
	defaultCenter: {
		lat: 39.76632525654491,
		lng: -86.15615844726564,
	},
	defaultZoom: 12,
	MinZoom: 7,
	MaxZoom: 18,
	//  map location search will prioritize results by proximity to this point:
	GeoSearch: {
		APIRoot: 'https://photon.komoot.de/api/', //	documentation at photon.komoot.de
		ResultsLanguage: 'en',
		SearchLocation: { lat: 39.76632525654491, lng: -86.15615844726564 },
	},
	regions: [
		//  Leaflet map will pan + zoom to fit this area in the map view
		//  https://leafletjs.com/reference-1.4.0.html#map-flytobounds
		{
			name: 'Central Indiana',
			bounds: { NorthLat: 40, EastLon: -86, SouthLat: 39.5, WestLon: -86.5 },
		},
		{
			name: 'Northwest Indiana',
			bounds: {
				NorthLat: 41.749158,
				EastLon: -87.526292,
				SouthLat: 41.174395,
				WestLon: -86.848747,
			},
		},
		{
			name: 'Southern Indiana',
			bounds: { NorthLat: 37.914615, EastLon: -84.88947, SouthLat: 39.137788, WestLon: -86.612867 },
		},
		{
			name: 'Southwest Indiana',
			bounds: {
				NorthLat: 38.269401,
				EastLon: -87.367076,
				SouthLat: 37.916643,
				WestLon: -87.945329,
			},
		},
	] as MapRegion[],
	pollingDelayMs: 10000,
};

export const ConfigREMTable = {
	heartbeatFrequencyMs: 3000,
	pagination: [15, 25, 50, 100],
	defaultPageSize: 15,
	pollingDelayMs: 10000,
	defaultColumns: [
		'type',
		'id',
		'route',
		'milemarker',
		'direction',
		'county',
		'district',
		'dateCreated',
		'respondingUnits',
		'attributes',
		'lastUpdated',
	] as typeof remEventColumns[number][],
	dateCellRenderer: ({ data, colDef }: { data: RoadEventDto; colDef: ColDef }): string => {
		let result = '--';
		try {
			const date = new Date(data[colDef.field] as number);
			result = formatTimezoneSup(date.toLocaleString(LOCALE, { timeZoneName: 'short' }));
		} catch (error) {
			//	it's okay if data is missing
		}
		return result;
	},
};

export const ConfigREMApi = {
	fields: (): string => `events/fields`,
	email: (): string => `events/email`,
	deleteEmail: (): string => `events/email/delete`,
	events: (): string => `events`,
	event: (eventId: number): string => `events/${eventId}`,
	eventTimeline: (eventId: number): string => `events/${eventId}/timeline`,
	createOrUpdate: (): string => `events`,
	routesByCountyDistrict: (): string => `locations/route`,
	countyDistrict: (route_designator: string): string => `locations/route/${route_designator}`,
	nearbyCameras: (route_designator: string): string =>
		`locations/route/${route_designator}/cameras`,
	dmsPreviewsByREMEvent: (): string => `sign/previews`,
	dmsPreviewsPerSign: (signIds: number[]): string => `sign/preview/selected/${signIds.join(',')}`,
	dmsSignsForEvent: (): string => `sign/save/previews`,
	getSignById: (signId: number): string => `sign/id/${signId}`,
	getSignQueueById: (signId: number, signType: DMSStatusType = DMSStatusType.ACTIVE): string =>
		`sign/queue/${signType}/${signId}`,
	setDMSforRemEvent: (): string => `/api/sign/save/messages`,
	getDMSForRemEvent: (eventId: number, signType?: DMSStatusType): string =>
		`sign/messages/${eventId}/${signType ?? ''}`,
	removeDMSByREMEvent: (signId: number): string => `sign/trash/${signId}`,
	currentEditors: (eventId: number): string => `/api/events/${eventId}/currentEditors`,
	requestTimeoutMs: 30000,
	dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
};

export const ConfigREMEventForm = {
	defaultSectionsPerEventType: {
		'ABANDONED VEHICLE': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'AMBER ALERT': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'ASSIST MOTORIST': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'ASSIST OTHER AGENCY': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'CONSTRUCTION': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'CRASH FATAL': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'CRASH PD': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'CRASH PI': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'CRASH SITE CLEANUP': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'DEBRIS IN THE ROAD': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'EOC ACTIVATION': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'FACILITY CLOSURE': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'FIRE': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'HEAVY TRAFFIC': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'HIGH WATER': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'LOCAL EVENT': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'MAINTENANCE': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'MAINTENANCE REQUEST': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'MEDICAL EMERGENCY': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'PEDESTRIAN': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'SLIDE OFF': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'SLOW TRAFFIC': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
		'STALLED VEHICLE': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'SUPERLOAD': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'TRAFFIC HAZARD': {
			[REMSectionKey.DMS]: true,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'TRAINING': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: true,
			[REMSectionKey.RESPONDING_UNITS]: true,
		},
		'TROUBLE TICKET': {
			[REMSectionKey.DMS]: false,
			[REMSectionKey.VEHICLES]: false,
			[REMSectionKey.RESPONDING_UNITS]: false,
		},
	},
	location: {
		/*
			<input pattern="" /> regex is slightly different than javascript
			equivalent pattern in javascript: /^(?:\d{1,3}(?:\.\d{1})?)$/gm
		*/
		mileMarkerPattern: '[0-9]{1,3}(?:\\.[0-9]{1})?',
		mileMarkerInvalidPattern: 'Value must be formatted "mmm.t"',
		mileMarkerValueFitted: 'Mile marker entry snapped to nearest valid value.',
		mileMarkerEndValueInvalid: 'Invalid end value removed.',
	},
	affectedLanes: {
		defaultLaneBlockageType: LaneImpact.NA,
	},
	//	scheduling formats
	wholeDatestampFormatOptions: {
		//	for displaying the current date and time in full
		hour: '2-digit',
		minute: '2-digit',
		month: 'short',
		day: '2-digit',
		year: 'numeric',
		timeZoneName: 'short',
	} as Intl.DateTimeFormatOptions,
	schedulingDateFormatOptions: {
		//	for populating the date selector inputs
	},
	schedulingTimeFormatOptions: {
		//	for populating the time selector inputs
		hour12: false,
		hour: '2-digit',
		minute: '2-digit',
	} as Intl.DateTimeFormatOptions,
	timelineDateFormatOptions: {
		//	for the daily timeline entry headlines
		month: 'short',
		day: '2-digit',
		year: 'numeric',
	} as Intl.DateTimeFormatOptions,
	timelineTimeFormatOptions: {
		//	for the time of the individual changes
		hour: '2-digit',
		minute: '2-digit',
		timeZoneName: 'short',
	} as Intl.DateTimeFormatOptions,
	dateAndTimePickerFormatOptions: {
		//	for displaying the current date and time in full for the datepicker input
		hour: '2-digit',
		minute: '2-digit',
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour12: false,
	} as Intl.DateTimeFormatOptions,
};

export const RelativeDateTimeEntries = {
	[RelativeDateTime.IMMEDIATELY]: 'Immediately',
	[RelativeDateTime.TOMORROW]: 'Tomorrow',
	[RelativeDateTime.WEEK]: 'Next Week',
	[RelativeDateTime.MONTH]: 'Next Month',
};

export const RelativeDurationEntries = {
	[RelativeDuration.INDEFINITELY]: 'Indefinitely',
	[RelativeDuration.HOUR]: 'One Hour',
	[RelativeDuration.DAY]: 'One Day',
	[RelativeDuration.WEEK]: 'One Week',
};

export enum EventAttributeValue {
	'Work Zone' = 'Work Zone',
	'CMV' = 'CMV',
	'Hazmat' = 'Hazmat',
}

export const EventAttributes: EventAttribute[] = [
	{
		value: EventAttributeValue['Work Zone'],
		label: EventAttributeValue['Work Zone'],
	},
	{
		value: EventAttributeValue.CMV,
		label: EventAttributeValue.CMV,
	},
	{
		value: EventAttributeValue.Hazmat,
		label: EventAttributeValue.Hazmat,
	},
];

export const RespondingUnitsSuggestions: Record<string, Record<string, string[]>> = {
	'ABANDONED VEHICLE': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'AVT - ABANDONED VEHICLE TAGGED',
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'TFC - TRAFFIC CONTROL',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TOC - TOWED FROM SCENE, OWNER CALLED', 'TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'ASSIST MOTORIST': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'ASM - AWAKEN SLEEPING MOTORIST',
			'CMO - CALLED TO MAKE OWN ARRANGEMENTS',
			'DDO - DISREGUARDED, MOTORIST DROVE OFF',
			'DNN - DISREGUARD, NOT NEEDED',
			'DNR - DID NOT RESPOND',
			'EFR - ESCORTED FROM ROADWAY',
			'FMD - FUEL MOTORIST VEHICLE (DIESEL)',
			'FMG - FUEL MOTORIST VEHICLE (GAS)',
			'INF - INFORMATION / DIRECTIONS GIVEN',
			'JMP - JUMP STARTED VEHICLE',
			'MCP - MOTORIST ON CELL PHONE',
			'MVR - MINOR VEHICLE REPAIR',
			'OTR - OTHER DISPOSITON',
			'RAS - REFUSED ASSISTANCE',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'SLD - SECURE LOAD',
			'TAA - TIRE ADDED AIR ONLY',
			'TCH - CHANGED TIRE & ADDED AIR TO SPARE',
			'TFC - TRAFFIC CONTROL',
			'TOC - TOWED FROM SCENE, OWNER CALLED',
			'TPC - TOWED FROM SCENE, POLICE CALLED',
			'TRP - TIRE REPAIR (PLUG TIRE)',
			'UTL - UNABLE TO LOCATE ',
			'WCK - WELFARE CHECK',
			'WPV - WATER / COOLANT PROVIDED IN VEHICLE',
		],
	},
	'ASSIST OTHER AGENCY': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'DNN - DISREGUARD, NOT NEEDED',
			'TFC - TRAFFIC CONTROL',
		],
	},
	'CRASH FATAL': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'FAA - FIRST AID ADMINISTERED',
			'HAZ - HARARDOUS RESPONSE OR CONTAINMENT / OIL DRY',
			'NRE - NO REPORT (ONLY OBSERVED) EVENT (IN PROGRESS)',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'TFC - TRAFFIC CONTROL',
		],
		'CORONER, MEDICAL': ['RTK - REPORT TAKEN'],
		'EMS,EMERGENCY MEDICAL SERVICES': ['FAA - FIRST AID ADMINISTERED'],
		'FIRE DEPARTMENT': [
			'FAA - FIRST AID ADMINISTERED',
			'FSP - FIRE SUPRESSION',
			'TFC - TRAFFIC CONTROL',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'INDOT MAINTENANCE': ['TFC - TRAFFIC CONTROL'],
		'WRECKER': ['TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'CRASH PD': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'DNN - DISREGUARD, NOT NEEDED',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'TFC - TRAFFIC CONTROL',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TOC - TOWED FROM SCENE, OWNER CALLED', 'TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'CRASH PI': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'DNN - DISREGUARD, NOT NEEDED',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'TFC - TRAFFIC CONTROL',
		],
		'EMS,EMERGENCY MEDICAL SERVICES': ['FAA - FIRST AID ADMINISTERED'],
		'FIRE DEPARTMENT': [
			'FAA - FIRST AID ADMINISTERED',
			'FSP - FIRE SUPRESSION',
			'TFC - TRAFFIC CONTROL',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TOC - TOWED FROM SCENE, OWNER CALLED', 'TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'DEBRIS IN THE ROAD': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'DNN - DISREGUARD, NOT NEEDED',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'RTA - REFERRED TO ANOTHER AGENCY',
			'TFC - TRAFFIC CONTROL',
			'UTL - UNABLE TO LOCATE ',
		],
	},
	'FIRE': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': ['FSP - FIRE SUPRESSION', 'TFC - TRAFFIC CONTROL'],
		'FIRE DEPARTMENT': ['FSP - FIRE SUPRESSION', 'TFC - TRAFFIC CONTROL'],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'IN SERVICE': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'CD2 - SHORT BREAK, < 15 MINUTES',
			'CD3 - LONG BREAK, LUNCH',
			'FCM - FUEL COMMISSION',
			'FDC - FILL DIESEL CAN',
			'FGC - FILL GAS CAN',
			'SVO - OUT OF SERVICE / UNAVAILABLE (10-7)',
			'SVI - IN SERVICE (10-8)',
			'T07 - OUT OF SERVICE / UNAVAILABLE (10-7)',
			'T08 - ON PATROL (10-8)',
			'T41 - BEGIN TOUR OF DUTY (10-41)',
			'T42 - END TOUR OF DUTY (10-42)',
			'TRN - TRAINING EVENT (PROVIDING OR RECEIVING)',
		],
	},
	'MEDICAL EMERGENCY': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': ['FAA - FIRST AID ADMINISTERED', 'TFC - TRAFFIC CONTROL'],
		'EMS,EMERGENCY MEDICAL SERVICES': ['FAA - FIRST AID ADMINISTERED'],
		'FIRE DEPARTMENT': [
			'FAA - FIRST AID ADMINISTERED',
			'FSP - FIRE SUPRESSION',
			'TFC - TRAFFIC CONTROL',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TOC - TOWED FROM SCENE, OWNER CALLED', 'TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'PEDESTRIAN': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'EFR - ESCORTED FROM ROADWAY',
			'INF - INFORMATION / DIRECTIONS GIVEN',
			'RTA - REFERRED TO ANOTHER AGENCY',
		],
	},
	'SLIDE OFF': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'DFV - DISPATCHED FOR FIELD VERIFICATION',
			'DNN - DISREGUARD, NOT NEEDED',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'RTA - REFERRED TO ANOTHER AGENCY',
			'TFC - TRAFFIC CONTROL',
			'WCK - WELFARE CHECK',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TOC - TOWED FROM SCENE, OWNER CALLED', 'TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
	'STALLED VEHICLE': {
		'PENDING UNIT': ['PND - PENDING DISPOSITION'],
		'HOOSIER HELPER': [
			'JMP - JUMP STARTED VEHICLE',
			'MVR - MINOR VEHICLE REPAIR',
			'RRW - RELOCATED TO / FROM ROADWAY',
			'TFC - TRAFFIC CONTROL',
		],
		'ISP, STATE POLICE': ['RTK - REPORT TAKEN'],
		'WRECKER': ['TOC - TOWED FROM SCENE, OWNER CALLED', 'TPC - TOWED FROM SCENE, POLICE CALLED'],
	},
};

export const REMSignPollingDelayMs = 10 * MILLISECONDS_PER_SECOND;

//	if any of these props change, the location details need to be refreshed
export const draftPropPathsThatInvalidateLocationDetails = [
	'route',
	'startMileMarker',
	'endMileMarker',
	'county',
	'district',
];

//	if any of these properties change, the DMS previews need to be reloaded
export const draftPropPathsThatInvalidateDMS = [
	'eventType',
	'route',
	'startMileMarker',
	'endMileMarker',
	'positiveLaneBlockage',
	'negativeLaneBlockage',
	'positiveLaneBlockageType',
	'positiveLaneBlockage.lanesAffected',
	'positiveLaneBlockage.enteranceRampAffected',
	'positiveLaneBlockage.exitRampAffected',
	'positiveLaneBlockage.insideShoulderAffected',
	'positiveLaneBlockage.outsideShoulderAffected',
	'negativeLaneBlockageType',
	'negativeLaneBlockage.lanesAffected',
	'negativeLaneBlockage.enteranceRampAffected',
	'negativeLaneBlockage.exitRampAffected',
	'negativeLaneBlockage.insideShoulderAffected',
	'negativeLaneBlockage.outsideShoulderAffected',
];

export default ConfigREM;
