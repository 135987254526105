import { css } from 'lit';
import { get as _get } from 'lodash';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import {
	DraftEvent,
	RoadEventDto,
	RoadEventFieldDto,
	RoadEventTimelineDto,
	getEmptyDraftEvent,
	getEmptyLaneBlockage,
} from '../../../typings/api';
import { APIErrorJSON, NotificationErrorType } from '../../../typings/shared-types';
import ConfigIRISx, { APIConfig } from '../../config/ConfigIRISx';
import {
	ConfigREMApi,
	ConfigREMEventForm,
	draftPropPathsThatInvalidateDMS,
	draftPropPathsThatInvalidateLocationDetails,
} from '../../config/ConfigREM';
import { AppSection, LaneImpact, REMSectionKey } from '../../constants';
import { ModifyDeep } from '../../utils/ModifyDeep';
import { isAPIErrorJSON } from '../../utils/type-guards';
import { isValidString } from '../../utils/utils';
import _isNotEqual from '../../utils/_isNotEqual';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import { ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { REMActionType } from './rem-actions';
import {
	getDMSforREMEvent,
	saveDMSForEvent,
	setDMSSignLoadingPreviewIds,
} from './rem-actions-event-dms';
import { handleNewLocationDetailsForREMEvent } from './rem-actions-event-location';
import { setREMEventTimeline } from './rem-actions-event-timeline';
import {
	selectHasSufficientDataForDMSPreview,
	selectHasSufficientDataForLocationDetails,
} from './rem-selectors';
import REMState from './rem-state';

type PreparedLocalDraftEventMod = {
	nearbyCameras: undefined;
	locationDetails: undefined;
	lat?: number;
	lon?: number;
};

type PreparedLocalDraftEvent = ModifyDeep<DraftEvent, PreparedLocalDraftEventMod>;

export const prepareDraftEventForServer = (draftEvent: DraftEvent): PreparedLocalDraftEvent => ({
	...draftEvent,
	//	vehicles whose details have yet to be filled out are allowed on the front-end, but break the backend
	vehicles: draftEvent.vehicles?.filter(
		(vehicle) => isValidString(vehicle.licensePlate) || isValidString(vehicle.description),
	),
	//	responding units without a unitType and disposition are allowed on the front-end, but break the backend
	respondingUnits: draftEvent.respondingUnits?.filter(
		(respondingUnit) =>
			isValidString(respondingUnit.unitType) &&
			isValidString(respondingUnit.dispositions?.[0].dispositionType),
	),
	//	strip out empty lane blockage entries - they break automatic DMS messaging generation
	positiveLaneBlockage: _isNotEqual(draftEvent.positiveLaneBlockage, getEmptyLaneBlockage())
		? draftEvent.positiveLaneBlockage
		: undefined,
	negativeLaneBlockage: _isNotEqual(draftEvent.negativeLaneBlockage, getEmptyLaneBlockage())
		? draftEvent.negativeLaneBlockage
		: undefined,
	//	these are always defined by the server, the frontend can't change them
	nearbyCameras: undefined,
	locationDetails: undefined,
	negativeLaneBlockageType:
		draftEvent.negativeLaneBlockageType === LaneImpact.NA
			? null
			: draftEvent.negativeLaneBlockageType,
	positiveLaneBlockageType:
		draftEvent.positiveLaneBlockageType === LaneImpact.NA
			? null
			: draftEvent.positiveLaneBlockageType,
});

export const prepareServerEventForClient = (event: RoadEventDto): DraftEvent => {
	const positiveLaneBlockage = event.positiveLaneBlockage ?? getEmptyLaneBlockage();
	positiveLaneBlockage.lanesAffected = positiveLaneBlockage.lanesAffected ?? [];
	const positiveLaneBlockageType =
		event.positiveLaneBlockageType ?? ConfigREMEventForm.affectedLanes.defaultLaneBlockageType;

	const negativeLaneBlockage = event.negativeLaneBlockage ?? getEmptyLaneBlockage();
	negativeLaneBlockage.lanesAffected = negativeLaneBlockage.lanesAffected ?? [];
	const negativeLaneBlockageType =
		event.negativeLaneBlockageType ?? ConfigREMEventForm.affectedLanes.defaultLaneBlockageType;

	return {
		...event,
		positiveLaneBlockage,
		positiveLaneBlockageType,
		negativeLaneBlockage,
		negativeLaneBlockageType,
	};
};

export type GetREMEvents = Action<typeof REMActionType.GET_REM_EVENTS>;
export interface SetREMEvents extends Action<typeof REMActionType.SET_REM_EVENTS> {
	events: REMState['events'];
}

export interface GetREMEventFromEvents
	extends Action<typeof REMActionType.GET_REM_EVENT_FROM_EVENTS> {
	eventId: number;
}

export interface GetREMEvent extends Action<typeof REMActionType.GET_REM_EVENT> {
	eventId: number;
}

export interface SetREMEvent extends Action<typeof REMActionType.SET_REM_EVENT> {
	event: RoadEventDto;
}

export type GetREMEventFields = Action<typeof REMActionType.GET_REM_EVENT_FIELDS>;

export interface SetREMEventFields extends Action<typeof REMActionType.SET_REM_EVENT_FIELDS> {
	eventFields: REMState['eventFields'];
}

export interface CreateREMEvent extends Action<typeof REMActionType.CREATE_REM_EVENT> {
	draftEvent: REMState['draftEvent'];
}
export interface SetREMDraftEvent extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT> {
	draftEvent: REMState['draftEvent'];
}

export interface UpdateREMEvent extends Action<typeof REMActionType.UPDATE_REM_EVENT> {
	draftEvent: REMState['draftEvent'];
}

export interface SetDraftEventProp extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT_PROP> {
	path: string;
	value: unknown;
}

export type REMEventUpdated = Action<typeof REMActionType.REM_EVENT_UPDATED>;

export interface ShowREMEventSection extends Action<typeof REMActionType.SHOW_REM_EVENT_SECTION> {
	section: REMSectionKey;
}

export interface SetREMReadOnlyMode extends Action<typeof REMActionType.SET_REM_READ_ONLY_MODE> {
	readOnlyMode: REMState['readOnlyMode'];
}

export const getEventFields =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: REMActionType.GET_REM_EVENT_FIELDS });
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.fields(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const eventFields: RoadEventFieldDto = await apiRequestReturn.response?.clone().json();
			dispatch({
				type: REMActionType.SET_REM_EVENT_FIELDS,
				eventFields,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing event fields:`, error);
			}
		}
		return apiRequestReturn;
	};

export const setREMDraftEvent = (draftEvent?: DraftEvent): SetREMDraftEvent => ({
	type: REMActionType.SET_REM_DRAFT_EVENT,
	draftEvent,
});

export const startNewREMDraftEvent =
	(): ThunkActionRoot<REMState['draftEvent']> =>
	(dispatch): REMState['draftEvent'] => {
		const emptyDraftEvent = getEmptyDraftEvent();
		dispatch(setREMDraftEvent(emptyDraftEvent));
		return emptyDraftEvent;
	};

export const getREMEvents =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_EVENTS,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.events(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		let events: RoadEventDto[];
		try {
			events = await apiRequestReturn.response?.clone().json();
			if (events.length === 0) {
				apiRequestReturn.apiError = APIError.ResponseEmpty;
				if (process.env.NODE_ENV === 'development') {
					console.warn('warning: events endpoint returned an empty result');
				}
			}
			dispatch({
				type: REMActionType.SET_REM_EVENTS,
				events,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing REM events:`, error);
			}
		}
		return apiRequestReturn;
	};

//	TODO: would this be more appropriate as a selector?
export const getREMEventFromEvents =
	(eventId: number): ThunkActionRoot<void> =>
	(dispatch, getState): void => {
		dispatch({ type: REMActionType.GET_REM_EVENT_FROM_EVENTS, eventId });
		const { events } = getState().rem;
		if (events) {
			const targetEvent = events?.find((event) => event.id === eventId);
			if (targetEvent) {
				const { draftEvent } = getState().rem;
				if (!draftEvent?.id) {
					dispatch(setREMDraftEvent(prepareServerEventForClient(targetEvent)));
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (process.env.NODE_ENV === 'development') {
					console.warn(`unable to preload event data for event #${eventId}`);
				}
			}
		}
	};

export const getREMEvent =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_EVENT,
			eventId,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.event(eventId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const event: RoadEventDto = await apiRequestReturn.response?.clone().json();
			const { draftEvent } = getState().rem;
			if (!draftEvent?.id) {
				dispatch(setREMDraftEvent(prepareServerEventForClient(event)));
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing event #${eventId}:`, error);
			}
		}
		return apiRequestReturn;
	};

//	first check the event timeline, to see if there's a new entry -
//	and if there is, then pull the latest event record
export const refreshREMEvent =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		//	TODO: refactor the polling logic a bit to simplify sequence of requests
		dispatch({
			type: REMActionType.GET_REM_EVENT_TIMELINE,
			eventId,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.eventTimeline(eventId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}

		let eventShouldPoll = true;
		let invalidTimeline = false;
		let eventTimeline: REMState['eventTimeline'] | APIErrorJSON;

		//	try parsing response

		try {
			eventTimeline = await apiRequestReturn.response?.clone().json();
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing event timeline for event #${eventId}:`, error);
			}
			invalidTimeline = true;
		}

		//	is response useable?

		if (eventTimeline === undefined || isAPIErrorJSON(eventTimeline)) {
			apiRequestReturn.apiError = APIError.ServerError;
			if (process.env.NODE_ENV === 'development') {
				console.error(`server error fetching timeline for event #${eventId}:`, eventTimeline);
			}
			invalidTimeline = true;
		} else if (eventTimeline?.timeline?.entries?.length === 0) {
			apiRequestReturn.apiError = APIError.ResponseEmpty;
			if (process.env.NODE_ENV === 'development') {
				console.warn(`warning: timeline endpoint returned an empty result for event #${eventId}`);
			}
			invalidTimeline = true;
		}

		//	are there new timeline entries?

		const { eventTimeline: previousEventTimeline } = getState().rem;
		if (
			previousEventTimeline &&
			previousEventTimeline.timeline?.entries?.[0].timestamp ===
				(eventTimeline as RoadEventTimelineDto).timeline?.entries?.[0].timestamp
		) {
			eventShouldPoll = false; //	most recent timestamp is the same, so don't bother polling
		}

		//	set timeline data accordingly

		if (invalidTimeline) {
			dispatch(setREMEventTimeline(undefined));
		} else {
			dispatch(setREMEventTimeline(eventTimeline as REMState['eventTimeline'])); //	update state with new timeline data
		}

		//	and fetch updated event record if warranted

		const { unsavedDraftEvent } = getState().rem;

		if (eventShouldPoll && !unsavedDraftEvent) {
			await dispatch(getREMEvent(eventId));

			const { draftEvent } = getState().rem;
			if (draftEvent && draftEvent.id) {
				await dispatch(getDMSforREMEvent(draftEvent));
			}
		}

		return apiRequestReturn;
	};

export const createREMEvent =
	(draftEvent: DraftEvent, signIds?: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.CREATE_REM_EVENT,
			draftEvent,
		});
		dispatch(addItem('api', 'rem-create-event'));
		const preparedDraftEvent = prepareDraftEventForServer(draftEvent);
		const apiRequestReturn = await APIRequest(
			new Request(
				new Request(new URL(ConfigREMApi.createOrUpdate(), APIConfig.endpointURLBase).href, {
					method: HTTPMethod.POST,
					headers: new Headers({
						...getAPIHeaders(),
					}),
					body: JSON.stringify(preparedDraftEvent),
				}),
			),
		);

		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}

		let event: RoadEventDto;
		try {
			event = await apiRequestReturn.response?.clone().json();
		} catch (error) {
			//	TODO: APIError value to handle this case
			if (process.env.NODE_ENV === 'development') {
				console.error('error parsing server response for saving event:', error);
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `There was an error creating the incident: ${error}` },
					5000,
				),
			);
			dispatch(removeItem('api', 'rem-create-event'));
			return apiRequestReturn;
		}

		await dispatch(getREMEvents());
		dispatch(setREMDraftEvent(prepareServerEventForClient(event)));
		dispatch({ type: REMActionType.REM_EVENT_UPDATED });
		dispatch(
			showMainBanner(
				NotificationErrorType.SUCCESS,
				{ title: `Incident #${event.id} created successfully` },
				5000,
			),
		);

		if (signIds !== undefined && signIds?.length > 0 && event?.id) {
			await dispatch(saveDMSForEvent(event.id, signIds));
		}

		dispatch(navigate(`${ConfigIRISx.Pages.rem.route}/event/${event.id}`));
		dispatch(refreshREMEvent(event.id));
		dispatch(removeItem('api', 'rem-create-event'));
		return apiRequestReturn;
	};

export const updateREMEvent =
	(draftEvent: DraftEvent, signIds?: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.UPDATE_REM_EVENT,
			draftEvent,
		});
		const preparedDraftEvent = prepareDraftEventForServer(draftEvent);
		const eventApiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.createOrUpdate(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(preparedDraftEvent),
			}),
		);
		if (eventApiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}

		if (signIds !== undefined && signIds?.length > 0 && draftEvent?.id) {
			await dispatch(saveDMSForEvent(draftEvent.id, signIds));
		}
		try {
			const event: RoadEventDto = await eventApiRequestReturn?.response?.clone().json();
			await dispatch(getREMEvents());
			dispatch(setREMDraftEvent(prepareServerEventForClient(event)));
			dispatch({ type: REMActionType.REM_EVENT_UPDATED });
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: `Incident updated successfully` },
					5000,
				),
			);
		} catch (error) {
			//	TODO: APIError value to handle this case
			if (process.env.NODE_ENV === 'development') {
				console.error(`error updating incident #${draftEvent.id}: `, error);
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Error updating incident: #${draftEvent.id}`, messages: [`${error}`] },
					5000,
				),
			);
		}
		return eventApiRequestReturn;
	};

export const setREMDraftEventProp =
	(path: string, value: unknown): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		let state = getState();
		if (state.rem?.draftEvent && _get(state.rem.draftEvent, path) !== value) {
			dispatch({
				type: REMActionType.SET_REM_DRAFT_EVENT_PROP,
				path,
				value,
			});
			state = getState();

			if (
				draftPropPathsThatInvalidateLocationDetails.includes(path) &&
				state.rem.mileMarkersValidForRoute === true &&
				selectHasSufficientDataForLocationDetails(state)
			) {
				const { route, startMileMarker, endMileMarker } = state.rem.draftEvent ?? {};
				if (process.env.NODE_ENV === 'development') {
					console.warn('location details refreshed due to', path, 'set to', value);
				}
				// handleNewLocationDetailsForREMEvent is hoisted
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				await handleNewLocationDetailsForREMEvent(dispatch, route, startMileMarker, endMileMarker);
				state = getState();
			}

			if (
				draftPropPathsThatInvalidateDMS.includes(path) &&
				state.rem.mileMarkersValidForRoute === true &&
				selectHasSufficientDataForDMSPreview(state)
			) {
				if (process.env.NODE_ENV === 'development') {
					console.warn('dms preview refreshed due to', path, 'set to', value);
				}

				dispatch(setDMSSignLoadingPreviewIds([]));
				if (state.rem.draftEvent !== undefined) {
					await dispatch(getDMSforREMEvent(state.rem.draftEvent, true));
				}
				state = getState();
			}
		}
	};

export const showREMEventSection = (section: REMSectionKey): ShowREMEventSection => ({
	type: REMActionType.SHOW_REM_EVENT_SECTION,
	section,
});

export const setREMReadOnlyMode = (readOnlyMode: REMState['readOnlyMode']): SetREMReadOnlyMode => ({
	type: REMActionType.SET_REM_READ_ONLY_MODE,
	readOnlyMode,
});
