import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { isEqual as _isEqual } from 'lodash';
import { LocationDto, RoadEventDto, getEmptyLaneBlockage } from '../../../typings/api';
import ConfigIRISx, { APIConfig } from '../../config/ConfigIRISx';
import { ConfigREMApi } from '../../config/ConfigREM';
import { AppSection, LaneImpact } from '../../constants';
import { isValidString } from '../../utils/utils';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { navigate } from '../redux-routing';
import { GetState, ThunkActionRoot, ThunkDispatchRoot } from '../redux-store';
import { REMActionType } from './rem-actions';
import { setREMDraftEventProp } from './rem-actions-event';
import REMState from './rem-state';

export interface SetRoutesFilterParam extends Action<typeof REMActionType.SET_ROUTES_FILTER_PARAM> {
	path: string;
	value?: string;
}

export type ResetLocationDetails = Action<typeof REMActionType.RESET_LOCATION_DETAILS>;

export type GetRoutesByLocationMeta = Action<typeof REMActionType.GET_ROUTES_BY_LOCATION_META>;

export interface SetMileMarkersValidForRoute
	extends Action<typeof REMActionType.SET_MILE_MARKERS_VALID_FOR_ROUTE> {
	mileMarkersValidForRoute: boolean;
}

export interface SetRoutesByLocationMeta
	extends Action<typeof REMActionType.SET_ROUTES_BY_LOCATION_META> {
	filteredRoutes: REMState['filteredRoutes'];
}

/* eslint-disable camelcase */
export interface GetLocationDetails extends Action<typeof REMActionType.GET_LOCATION_DETAILS> {
	route_designator: RoadEventDto['route'];
	miles_along_route: RoadEventDto['startMileMarker'];
	end_miles: RoadEventDto['endMileMarker'];
}
/* eslint-enable camelcase */
/* eslint-disable camelcase */

export interface GetNearbyCameras extends Action<typeof REMActionType.GET_REM_NEARBY_CAMERAS> {
	route_designator: RoadEventDto['route'];
	miles_along_route: RoadEventDto['startMileMarker'];
}
/* eslint-enable camelcase */

export interface SetNearbyCameras extends Action<typeof REMActionType.SET_REM_NEARBY_CAMERAS> {
	nearbyCameras: RoadEventDto['nearbyCameras'];
}

export const resetLocationDetails = (): ResetLocationDetails => ({
	type: REMActionType.RESET_LOCATION_DETAILS,
});

export const getRoutesByLocationMeta =
	(county?: string, district?: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_ROUTES_BY_LOCATION_META,
			county,
			district,
		});
		const url = new URL(ConfigREMApi.routesByCountyDistrict(), APIConfig.endpointURLBase);
		if (isValidString(county) && county !== undefined) {
			url.searchParams.set('county', county);
		}
		if (isValidString(district) && district !== undefined) {
			url.searchParams.set('district', district);
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const filteredRoutes: REMState['filteredRoutes'] = await apiRequestReturn.response
				?.clone()
				.json();
			dispatch({
				type: REMActionType.SET_ROUTES_BY_LOCATION_META,
				filteredRoutes,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(
					`error fetching routes in county "${county}" & district "${district}"`,
					error,
				);
			}
		}
		return apiRequestReturn;
	};

export const setRoutesFilterParam =
	(path: string, value?: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	(dispatch: ThunkDispatchRoot, getState: GetState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.SET_ROUTES_FILTER_PARAM,
			path,
			value,
		});
		const routeFilterParams = {
			county: undefined,
			district: undefined,
			...(getState().rem.routeFilterParams ?? {}),
			[path]: value,
		};
		const { county, district } = routeFilterParams;
		return dispatch(getRoutesByLocationMeta(county, district));
	};

/* eslint-disable camelcase */
export const getLocationDetails =
	(
		route_designator: RoadEventDto['route'],
		miles_along_route: RoadEventDto['startMileMarker'],
		end_miles?: RoadEventDto['endMileMarker'],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_LOCATION_DETAILS,
			route_designator,
			miles_along_route,
			end_miles,
		});

		const url = new URL(ConfigREMApi.countyDistrict(route_designator), APIConfig.endpointURLBase);
		url.searchParams.set('miles_along_route', miles_along_route.toString());
		if (end_miles) {
			url.searchParams.set('end_miles', end_miles.toString());
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}

		try {
			const locationDetails: LocationDto = await apiRequestReturn.response?.clone().json();
			const { draftEvent } = getState().rem;
			//	reset the affected lane data if the details have changed
			if (
				draftEvent?.locationDetails !== undefined &&
				_isEqual(draftEvent.locationDetails, locationDetails) === false
			) {
				//	pos
				dispatch(setREMDraftEventProp('positiveLaneBlockage', getEmptyLaneBlockage()));
				dispatch(setREMDraftEventProp('positiveLaneBlockageType', LaneImpact.NA));
				//	neg
				dispatch(setREMDraftEventProp('negativeLaneBlockage', getEmptyLaneBlockage()));
				dispatch(setREMDraftEventProp('negativeLaneBlockageType', LaneImpact.NA));
			}
			dispatch(setREMDraftEventProp('locationDetails', locationDetails));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(
					`error parsing event location for route ${route_designator} at milemarker ${miles_along_route}:`,
					error,
				);
			}
		}
		return apiRequestReturn;
	};
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export const getNearbyCameras =
	(
		route_designator: RoadEventDto['route'],
		miles_along_route: RoadEventDto['startMileMarker'],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_NEARBY_CAMERAS,
			route_designator,
			miles_along_route,
		});

		const url = new URL(ConfigREMApi.nearbyCameras(route_designator), APIConfig.endpointURLBase);
		url.searchParams.set('miles_along_route', miles_along_route.toString());
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const nearbyCameras: RoadEventDto['nearbyCameras'] = await apiRequestReturn.response
				?.clone()
				.json();
			dispatch(setREMDraftEventProp('nearbyCameras', nearbyCameras));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(
					`error parsing nearby cameras for route ${route_designator} at milemarker ${miles_along_route}:`,
					error,
				);
			}
		}
		return apiRequestReturn;
	};
/* eslint-enable camelcase */

export const setMileMarkersValidForRoute = (
	mileMarkersValidForRoute: boolean,
): SetMileMarkersValidForRoute => ({
	type: REMActionType.SET_MILE_MARKERS_VALID_FOR_ROUTE,
	mileMarkersValidForRoute,
});

// eslint-disable-next-line jsdoc/require-jsdoc
export async function handleNewLocationDetailsForREMEvent(
	dispatch: ThunkDispatchRoot,
	route?: string,
	startMileMarker?: number,
	endMileMarker?: number,
): Promise<void> {
	//	if we're looking up a location we no longer need to filer routes
	dispatch(setRoutesFilterParam('county', undefined));
	dispatch(setRoutesFilterParam('district', undefined));
	//	next, show the results of the location lookup
	if (route !== undefined && startMileMarker !== undefined) {
		await dispatch(getLocationDetails(route, startMileMarker, endMileMarker));
	}
	if (route !== undefined && startMileMarker !== undefined) {
		await dispatch(getNearbyCameras(route, startMileMarker));
	}
}
