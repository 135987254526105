import { css } from 'lit';
import { APIRequestReturn } from '../../redux/APIRequest';
import { getSignQueue } from '../../redux/dms/dms-actions';
import store, { ThunkActionRoot } from '../../redux/redux-store';
import { getREMEvents, refreshREMEvent } from '../../redux/rem/rem-actions-event';
import { getSign } from '../../redux/rem/rem-actions-event-dms';
import { setREMEventCurrentEditors } from '../../redux/rem/rem-actions-event-editors';

//	get all events

let REMEventsPollingTimeout: number;
let REMEventsLastAPIRequestReturn: APIRequestReturn;

export const stopPollingREMEvents = (autoAbort = true): void => {
	window.clearInterval(REMEventsPollingTimeout);
	if (autoAbort && REMEventsLastAPIRequestReturn) {
		REMEventsLastAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingREMEvents =
	(pollingDelayMs: number, pollImmediately = false): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		const { eventsLastPolled } = getState().rem;

		if (pollImmediately || !eventsLastPolled || Date.now() - eventsLastPolled > pollingDelayMs) {
			REMEventsLastAPIRequestReturn = await dispatch(getREMEvents());
		}
		window.clearInterval(REMEventsPollingTimeout);

		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		REMEventsPollingTimeout = window.setInterval(async (): Promise<void> => {
			REMEventsLastAPIRequestReturn = await dispatch(getREMEvents());
		}, pollingDelayMs);
	};

//	get one event

let REMEventPollingEnabled = false;
let REMEventPollingTimeout: number | undefined;
let REMEventPollingDelayMs: number;
let REMEventLastAPIRequestReturn: APIRequestReturn;
let REMEventPollingId: number;

export const pollREMEvent = async (): Promise<void> => {
	const apiRequestReturn = await store.dispatch(refreshREMEvent(REMEventPollingId));
	REMEventLastAPIRequestReturn = apiRequestReturn;
	if (REMEventPollingEnabled === true) {
		REMEventPollingTimeout = window.setTimeout(() => {
			void pollREMEvent();
		}, REMEventPollingDelayMs);
	}
};

export const stopPollingREMEvent = (autoAbort = true): void => {
	REMEventPollingEnabled = false;
	window.clearTimeout(REMEventPollingTimeout);
	REMEventPollingTimeout = undefined;
	if (autoAbort && REMEventLastAPIRequestReturn) {
		REMEventLastAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingREMEvent = (
	eventId: number,
	pollingDelayMs: number,
	pollImmediately = true,
): void => {
	if (REMEventPollingEnabled === true) {
		return; //	no overlapping polling!
	}
	REMEventPollingEnabled = true;
	REMEventPollingDelayMs = pollingDelayMs;
	REMEventPollingId = eventId;
	if (pollImmediately) {
		void pollREMEvent();
	} else {
		REMEventPollingTimeout = window.setTimeout(() => {
			void pollREMEvent();
		}, REMEventPollingDelayMs);
	}
};

//	get one sign

let REMSignPollingEnabled = false;
let REMSignPollingTimeout: number | undefined;
let REMSignPollingDelayMs: number;
let REMSignLastAPIRequestReturn: APIRequestReturn;
let REMSignPollingId: number;

export const pollREMSign = async (): Promise<void> => {
	//	TODO: why is api return request reused this way
	let apiRequestReturn = await store.dispatch(getSign(REMSignPollingId));
	apiRequestReturn = await store.dispatch(getSignQueue(REMSignPollingId));
	REMSignLastAPIRequestReturn = apiRequestReturn;
	if (REMSignPollingEnabled === true) {
		REMSignPollingTimeout = window.setTimeout(() => {
			void pollREMSign();
		}, REMSignPollingDelayMs);
	}
};

export const stopPollingREMSign = (autoAbort = true): void => {
	REMSignPollingEnabled = false;
	window.clearTimeout(REMSignPollingTimeout);
	REMSignPollingTimeout = undefined;
	if (autoAbort && REMSignLastAPIRequestReturn) {
		REMSignLastAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingREMSign = (
	signId: number,
	pollingDelayMs: number,
	pollImmediately = true,
): void => {
	if (REMSignPollingEnabled === true) {
		return; //	no overlapping polling!
	}
	REMSignPollingEnabled = true;
	REMSignPollingDelayMs = pollingDelayMs;
	REMSignPollingId = signId;
	if (pollImmediately) {
		void pollREMSign();
	} else {
		REMSignPollingTimeout = window.setTimeout(() => {
			void pollREMSign();
		}, REMSignPollingDelayMs);
	}
};

//	register current user

//	register current user

let REMHeartbeatEnabled = false;
let REMHeartbeatTimeout: number | undefined;
let REMHeartbeatDelayMs: number;
let REMHeartbeatLastAPIRequestReturn: APIRequestReturn;
let REMHeartbeatEventId: number;

export const sendREMHeartbeat = async (): Promise<void> => {
	const apiRequestReturn = await store.dispatch(setREMEventCurrentEditors(REMHeartbeatEventId));
	REMHeartbeatLastAPIRequestReturn = apiRequestReturn;
	if (REMHeartbeatEnabled === true) {
		REMHeartbeatTimeout = window.setTimeout(() => {
			void sendREMHeartbeat();
		}, REMHeartbeatDelayMs);
	}
};

export const stopREMHeartbeat = (autoAbort = true): void => {
	REMHeartbeatEnabled = false;
	window.clearTimeout(REMHeartbeatTimeout);
	REMHeartbeatTimeout = undefined;
	if (autoAbort && REMHeartbeatLastAPIRequestReturn) {
		REMHeartbeatLastAPIRequestReturn.abortController?.abort();
	}
};

export const startREMHeartbeat = (eventId: number, delay: number): void => {
	if (REMHeartbeatEnabled === true) {
		return; //	no overlapping polling!
	}
	REMHeartbeatEnabled = true;
	REMHeartbeatDelayMs = delay;
	REMHeartbeatEventId = eventId;
	void sendREMHeartbeat();
};
