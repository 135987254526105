import { css } from 'lit';
import { APIRequestReturn } from '../../redux/APIRequest';
import { refreshHH } from '../../redux/hh/redux-hh';
import store from '../../redux/redux-store';

let HHPollingEnabled = false;
let HHPollingTimeout: number;
let HHPollingDelayMs: number;
let HHLastAPIRequestReturn: APIRequestReturn;
let HHPollingId: number;

export const pollHH = async (): Promise<void> => {
	const apiRequestReturn = await store.dispatch(refreshHH(HHPollingId));
	HHLastAPIRequestReturn = apiRequestReturn;

	if (HHPollingEnabled) {
		HHPollingTimeout = window.setTimeout(() => {
			pollHH();
		}, HHPollingDelayMs);
	}
};

export const stopPollingHH = (autoAbort = true): void => {
	HHPollingEnabled = false;
	window.clearTimeout(HHPollingTimeout);
	HHPollingTimeout = undefined;

	if (autoAbort && HHLastAPIRequestReturn) {
		HHLastAPIRequestReturn.abortController.abort();
	}
};

export const startPollingHH = async (
	hhId: number,
	pollingDelayMs: number,
	pollImmediately = true,
): Promise<void> => {
	if (HHPollingEnabled) {
		return;
	}

	HHPollingEnabled = true;
	HHPollingDelayMs = pollingDelayMs;
	HHPollingId = hhId;

	if (pollImmediately) {
		await pollHH();
	} else {
		HHPollingTimeout = window.setTimeout(() => {
			pollHH();
		}, HHPollingDelayMs);
	}
};
