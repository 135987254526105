import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { EmailGroupDto, EmailGroupsRecipientsDto } from '../../../typings/api';
import ConfigIRISx, { APIConfig } from '../../config/ConfigIRISx';
import { ConfigREMApi } from '../../config/ConfigREM';
import { AppSection } from '../../constants';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { navigate } from '../redux-routing';
import { ThunkActionRoot } from '../redux-store';
import { REMActionType } from './rem-actions';
import REMState from './rem-state';

export type GetEmailRecipients = Action<typeof REMActionType.GET_REM_EVENT_EMAIL_RECIPIENTS>;

export interface SetEmailRecipients
	extends Action<typeof REMActionType.SET_REM_EVENT_EMAIL_RECIPIENTS> {
	emailGroupsRecipients: REMState['emailGroupsRecipients'];
}

export interface AddEmailGroups extends Action<typeof REMActionType.ADD_REM_EVENT_EMAIL_GROUPS> {
	emailGroups: EmailGroupDto[];
}

export interface DeleteEmailGroups
	extends Action<typeof REMActionType.DELETE_REM_EVENT_EMAIL_GROUPS> {
	emailGroups: EmailGroupDto[];
}

export const getEmailRecipients =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: REMActionType.GET_REM_EVENT_EMAIL_RECIPIENTS });
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.email(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const emailGroupsRecipients: EmailGroupsRecipientsDto = await apiRequestReturn.response
				?.clone()
				.json();
			dispatch({
				type: REMActionType.SET_REM_EVENT_EMAIL_RECIPIENTS,
				emailGroupsRecipients,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing event update recipient emails:`, error);
			}
		}
		return apiRequestReturn;
	};

export const updateEventEmailGroups =
	(emailGroups: EmailGroupDto[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		let apiRequestReturn: APIRequestReturn;
		const newEmailGroupsRecipients = {
			...getState().rem.emailGroupsRecipients,
			emailGroups,
		};

		apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.email(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newEmailGroupsRecipients),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		try {
			const emailGroupsRecipients: EmailGroupsRecipientsDto = (await apiRequestReturn.response
				?.clone()
				.json()) as EmailGroupsRecipientsDto;
			dispatch({
				type: REMActionType.ADD_REM_EVENT_EMAIL_GROUPS,
				emailGroups: emailGroupsRecipients.emailGroups,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (process.env.NODE_ENV === 'development') {
				console.error(`error parsing update recipient emails:`, error);
			}
		}
		return apiRequestReturn;
	};

export const deleteEventEmailGroups =
	(emailGroups: EmailGroupDto[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const newEmailGroupsRecipients = {
			emailGroups,
			emailRecipients: [],
		};

		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.deleteEmail(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newEmailGroupsRecipients),
			}),
		);
		if (apiRequestReturn.response?.status === 401) {
			dispatch(navigate(ConfigIRISx.Pages[AppSection.LOGIN].route));
		}
		dispatch({
			type: REMActionType.DELETE_REM_EVENT_EMAIL_GROUPS,
			emailGroups,
		});

		return apiRequestReturn;
	};
