import { css } from 'lit';
import { Store } from 'redux';
import { RootAction, RootState } from './redux-store';

//	TODO: type this?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const middlewareErrorCatcher = (store: Store): any => (
	next: (arg0: RootAction) => RootState,
): Function => (action: RootAction, state: RootState = store.getState()): RootState => {
	try {
		return next(action);
	} catch (err) {
		if (process.env.NODE_ENV === 'development') {
			// intentional console error
			// eslint-disable-next-line no-console
			console.error(err, action, state);
		}
		return next(action);
	}
};

export default middlewareErrorCatcher;
