import { css } from 'lit';
import { ChangeSetItem, LaneBlockage, RecordStatus, RespondingUnitMember } from '../../typings/api';
import { TimelineDayEntryChange } from '../../typings/shared-types';
import { LOCALE } from '../constants';
import { isValidString, numberIsValidAndFinite } from '../utils/utils';
import { ConfigREMEventForm } from './ConfigREM';

const laneBlockageToTimelineEntry = (laneBlockage?: LaneBlockage): string => {
	if (laneBlockage) {
		if (laneBlockage.lanesAffected.length > 0) {
			const lanesAffected = [...laneBlockage.lanesAffected].sort();
			const numLanes = lanesAffected.length;

			return `Lane${numLanes > 1 ? 's' : ''} ${
				numLanes > 1
					? `${lanesAffected.slice(0, numLanes - 1).join(', ')} and ${lanesAffected[numLanes - 1]}`
					: lanesAffected[0]
			}${laneBlockage.insideShoulderAffected ? ', inside shoulder' : ''}${
				laneBlockage.outsideShoulderAffected ? ', outside shoulder' : ''
			}${laneBlockage.entranceRampAffected ? ', entrance ramp' : ''}${
				laneBlockage.exitRampAffected ? ', exit ramp' : ''
			}.`;
		}
	}

	return 'No lanes impacted';
};

export const resolveChangeSetItemToTimelineDayEntryChange = (
	changeSetName: string,
	changeSetItem: ChangeSetItem,
): TimelineDayEntryChange => {
	switch (changeSetItem.fieldName) {
		case 'mileStart':
			return {
				subject: 'Mile Marker Start',
				description: changeSetItem.value.toString(),
			};
		case 'mileEnd':
			return {
				subject: 'Mile Marker End',
				description: changeSetItem.value?.toString() ?? 'Removed.',
			};
		case 'licensePlate':
			return {
				subject: 'Vehicle License Plate',
				description: changeSetItem.value.toString(),
			};
		case 'description':
			return {
				subject: 'Vehicle Description',
				description: changeSetItem.value.toString(),
			};
		case 'priority':
			return {
				subject: 'Priority',
				description: changeSetItem.value.toString(),
			};
		case 'routeDesignator':
			return {
				subject: 'Route',
				description: changeSetItem.value.toString(),
			};
		case 'county': {
			const values = changeSetItem.value as string[];
			return {
				subject: `Count${values.length === 1 ? `y` : `ies`}`,
				description: values.join(', '),
			};
		}
		case 'district': {
			const values = changeSetItem.value as string[];
			return {
				subject: `District${values.length === 1 ? `` : `s`}`,
				description: values.join(', '),
			};
		}
		case 'subdistrict': {
			const values = changeSetItem.value as string[];
			return {
				subject: `Sub-District${values.length === 1 ? `` : `s`}`,
				description: values.join(', '),
			};
		}
		case 'unit': {
			const values = changeSetItem.value as string[];
			return {
				subject: `Unit${values.length === 1 ? `` : `s`}`,
				description: values.join(', '),
			};
		}
		case 'eventType':
			return {
				subject: 'Headline',
				description: changeSetItem.value.toString(),
			};
		case 'eventSource':
			return {
				subject: 'Event Source',
				description: changeSetItem.value.toString(),
			};
		case 'reporterName':
			return {
				subject: 'Reporter Name',
				description: changeSetItem.value.toString(),
			};
		case 'reporterPhoneNumber':
			return {
				subject: 'Reporter Phone Number',
				description: changeSetItem.value.toString(),
			};
		case 'verified':
			return {
				subject: 'Visual Verification',
				description: `By user "${changeSetItem.createdBy.toString()}"`,
			};
		case 'eventStart':
			return {
				subject: 'Scheduled Start',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value as number).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
			};
		case 'eventEnd':
			return {
				subject: 'Scheduled End',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value as number).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
			};
		case 'positiveLaneBlockageType':
			return {
				subject: 'Positive Direction Impact Type',
				description: changeSetItem?.value?.toString() ?? 'Removed.',
			};
		case 'negativeLaneBlockageType':
			return {
				subject: 'Negative Direction Impact Type',
				description: changeSetItem?.value?.toString() ?? 'Removed.',
			};
		case 'positiveLaneBlockage': {
			const laneBlockage = changeSetItem.value as LaneBlockage;
			return {
				subject: 'Positive Direction Impacts',
				description: laneBlockageToTimelineEntry(laneBlockage),
			};
		}
		case 'negativeLaneBlockage': {
			const laneBlockage = changeSetItem.value as LaneBlockage;
			return {
				subject: 'Negative Direction Impacts',
				description: laneBlockageToTimelineEntry(laneBlockage),
			};
		}

		case 'locationDescription':
			return {
				subject: 'Location Notes',
				description: changeSetItem.value.toString(),
			};

		case 'respondingUnitType': {
			return {
				subject: 'Responding Unit Type',
				description: changeSetItem.value?.toString() ?? 'Removed',
			};
		}

		case 'respondingUnitMember': {
			return {
				subject: 'Responding Unit Member',
				description: (changeSetItem.value as RespondingUnitMember).name.toString(),
			};
		}

		case 'dispositionType': {
			return {
				subject: 'Responding Unit Disposition',
				description: changeSetItem.value.toString(),
			};
		}

		case 'assigned': {
			return {
				subject: 'Responding Unit Assigned',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value as number).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
			};
		}

		case 'arrived': {
			return {
				subject: 'Responding Unit Arrived',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value as number).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
			};
		}

		case 'completed': {
			return {
				subject: 'Responding Unit Completed',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value as number).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
			};
		}

		case 'linkedEventA': {
			return {
				subject: 'Linking',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `Created link to event ID ${changeSetItem.value as number}`
					: `Link to event ID ${changeSetItem.value as number} Removed.`,
			};
		}

		case 'linkedEventB': {
			return {
				subject: 'Linking',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `Created link to event ID ${changeSetItem.value as number}`
					: `Link to event ID ${changeSetItem.value as number} Removed.`,
			};
		}

		case 'value':
			return {
				subject: 'Event Attribute',
				description: changeSetItem.value.toString(),
			};

		case 'text': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Email Notification Message',
						description: `"${changeSetItem.value as string}"`,
				  }
				: undefined;
		}

		case 'notes': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Operator note appended:',
						description: `"${changeSetItem.value as string}"`,
				  }
				: undefined;
		}

		case 'emailAddress':
			return {
				subject: 'Individual Notification Recipient Modified',
				description: `Added "${changeSetItem.value.toString()}"`,
			};

		case 'name':
			return {
				subject: 'Group Notification Recipient Modified',
				description: `Added "${changeSetItem.value.toString()}"`,
			};
		//	record status changes record additions / deletions for collections of data entries
		case 'recordStatus':
			switch (changeSetName) {
				case 'vehicles':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Vehicles',
							description: 'Entry deleted.',
						};
					}
					//	ignore vehicle record statuses besides deleted entries
					return undefined;
				case 'event':
					//	ignore event record creation / deletion
					break;

				case 'respondingUnitDispositions':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Responding Unit Disposition',
							description: 'Entry deleted.',
						};
					}
					//	ignore respondingUnitDisposition record statuses besides deleted entries
					return undefined;

				case 'respondingUnits':
					if (changeSetItem.fieldName === 'recordStatus') {
						return {
							subject: 'Responding Unit Status',
							description: changeSetItem.value.toString(),
						};
					}
					//	ignore respondingUnit record statuses besides deleted entries
					return undefined;

				case 'attributes':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Event Attribute',
							description: 'Attribute deleted',
						};
					}
					//	ignore attribute record statuses besides deleted entries
					return undefined;

				case 'eventLinkages':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Linking',
							description: 'Link to event removed',
						};
					}

					break;

				case 'timelineAnnotations':
					//	no timeline entry for timeline annotation status changing
					break;

				//	TODO: include *which* individual or group was removed, pending API update to support

				case 'emailRecipients':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Notification Recipients Modified',
							description: 'Deleted individual recipipent.',
						};
					}
					break;

				case 'emailGroups':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Group Notification Recipient Modified',
							description: 'Deleted recipient group.',
						};
					}
					break;
				default:
					if (process.env.NODE_ENV === 'development') {
						console.warn(
							`unexpected '${changeSetName}' changeset name "${changeSetItem.fieldName}" in`,
							changeSetItem,
						);
					}
					break;
			}
			break;
		//	this meta information doesn't need to be shown on the timeline
		case 'createdBy':
		case 'eventStatus':
		case 'verifiedBy':
			//	ignore this entry
			break;
		default:
			if (process.env.NODE_ENV === 'development') {
				console.warn(`unexpected '${changeSetName}' field name:`, changeSetItem);
			}
	}
	return undefined;
};

export const TimelineSortOrder = [
	'Author',
	'Headline',
	'Event Source',
	'Reporter Name',
	'Reporter Phone Number',
	'Route',
	'Mile Marker Start',
	'Mile Marker End',
	'Positive Direction Impacts',
	'Positive Direction Impact Type',
	'Negative Direction Impacts',
	'Negative Direction Impact Type',
	'County',
	'District',
	'Sub-District',
	'Unit',
	'Location Notes',
	'locationDescription',
	'notes',
	'Visual Verification',
	'Scheduled Start',
	'Scheduled End',
	'Vehicles',
	'Vehicle License Plate',
	'Vehicle Description',
	'Responding Unit Type',
	'Responding Unit Status',
	'Responding Unit Disposition',
	'Responding Unit Member',
	'Responding Unit Assigned',
	'Responding Unit Arrived',
	'Responding Unit Completed',
	'Event Attribute',
	'Priority',
	'Linking',
	'Email Notification Message',
];
