import { css } from 'lit';
import { isEqual as _isEqual } from 'lodash';
/* eslint-disable no-underscore-dangle */
/**
 * this is mostly a convenience function to be passed into hasChanged
 *
 * @param {*} a the first value
 * @param {*} b the second value
 * @returns {boolean} true if they're not equal, false if they are equal
 */
export default function _isNotEqual(a: unknown, b: unknown): boolean {
	return _isEqual(a, b) === false;
}
/* eslint-enable no-underscore-dangle */
