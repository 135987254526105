import { css } from 'lit';
/* eslint-disable no-shadow, no-use-before-define */
import { ModifyDeep } from '../src/utils/ModifyDeep';
import { RequireAtLeastOne } from '../src/utils/require-at-least-one';
import { components } from './irisx-api.gen';

export enum EventStatus {
	COMPLETED = 'COMPLETED',
	NORMAL = 'NORMAL',
	EXPIRED = 'EXPIRED',
}

//	salient API type defs

type AttributeDto = components['schemas']['AttributeDto'];
type RoadEventDtoBase = components['schemas']['RoadEventDto'];
export interface RoadEventDto extends RoadEventDtoBase {
	id: number;
	createdBy: string;
	updatedBy: string;
	created: number;
	updated: number;
	priorityLevel: number;
	lat: number;
	lon: number;
	startMileMarker: number;
	eventType: string;
	eventStatus: EventStatus;
	route: string;
	eventSource: string;
	locationDetails: LocationDto;
	vehicles: VehicleDto[];
	attributes: AttributeDto[];
	respondingUnits: RespondingUnitDto[];
	linkedEvents: EventLinkageDto[];
	emailGroupsRecipients: EmailGroupsRecipientsDto;
	emailEnabled: boolean;
	//	property for clientside convenience, doesn't ever show up in server version of dto
	nearbyCameras?: CameraDto[];
}
export type DraftEvent = Partial<RoadEventDto>;

export type RoadEventTimelineDto = components['schemas']['RoadEventTimelineDto'];
export type ChangeSetItemApi = components['schemas']['ChangeSetItem'];
type TimelineEntryBase = components['schemas']['TimelineEntry'];
export interface TimelineEntry extends TimelineEntryBase {
	timestamp: number;
}

type ChangeSetItemBase = components['schemas']['ChangeSetItem'];
type ChangeSetItemMod = {
	//	value and previousValue could be a single value or a collection, depending on fieldName
	name: string;
	id: number;
	//	this type varies from entry to entry
	value?: any;
	previousValue?: any;
};
export type ChangeSetItem = ModifyDeep<ChangeSetItemBase, ChangeSetItemMod>;

export type RoadEventFieldDto = components['schemas']['RoadEventFieldDto'];
type EventTypeBase = components['schemas']['EventType'];
export interface EventType extends EventTypeBase {
	name: string; //	required
}
export type EventPriorityType = components['schemas']['EventPriorityType'];
export type EventIconType = components['schemas']['EventIconType'];
export type EventLinkageDto = components['schemas']['EventLinkageDto'];
export type VehicleDto = RequireAtLeastOne<
	components['schemas']['VehicleDto'],
	'licensePlate' | 'description'
>;
export type RouteDto = components['schemas']['RouteDto'];
export type ControllerDto = components['schemas']['ControllerDto'];
export type CameraDto = components['schemas']['CameraDto'];
export type GroupDto = components['schemas']['GroupDto'];

type SignDtoBase = components['schemas']['SignDto'];
export interface SignDto extends SignDtoBase {
	groups: GroupDto[];
	id: number; //	required
	type: DMSSignType; //	required
	lat: number;
	lon: number;
}
type SignDtoNewMod = {
	//	not yet known
	id?: number;
	type?: DMSSignType;
};
export type SignDtoNew = ModifyDeep<SignDto, SignDtoNewMod>;

export type HighwayHelperFieldDto = components['schemas']['HighwayHelperFieldDto'];
export type HighwayHelperDto = components['schemas']['HighwayHelperDto'];
export type HighwayHelperTimelineDto = components['schemas']['HighwayHelperTimelineDto'];
export type LocationDto = components['schemas']['LocationDto'];
export type LaneBlockage = components['schemas']['LaneBlockage'];
export type LaneBlockageType = components['schemas']['LaneBlockageType'];
export type RespondingUnitDto = components['schemas']['RespondingUnitDto'];
export type RespondingUnitDispositionDto = components['schemas']['RespondingUnitDispositionDto'];
export type RespondingUnitDispositionType = components['schemas']['RespondingUnitDispositionType'];

export type DispositionTypeDtoBase = components['schemas']['DispositionTypeDto'];
export interface DispositionTypeDto extends DispositionTypeDtoBase {
	name: string; //	required
	description: string;
}

export type RespondingUnitMember = components['schemas']['RespondingUnitMember'];
export type EmailGroupsRecipientsDto = components['schemas']['EmailGroupsRecipientsDto'];
export type EmailRecipientDto = components['schemas']['EmailRecipientDto'];
export type EmailGroupDto = components['schemas']['EmailGroupDto'];
export type SignQueueDto = components['schemas']['SignQueueDto'];
export type MessageDto = components['schemas']['MessageDto'];
export type CreatePreviewDto = components['schemas']['CreatePreviewDto'];
export type CustomMessageDto = components['schemas']['CustomMessageDto'];
export type EventMessageDto = components['schemas']['EventMessageDto'];
export type SaveEventMessagesDto = components['schemas']['SaveEventMessagesDto'];
export type PreviewDto = components['schemas']['PreviewDto'];
export type PreviewDtoResponse = { [key: string]: components['schemas']['PreviewDto'][] | null };
export type FrameDto = components['schemas']['FrameDto'];
type GraphicDtoBase = components['schemas']['GraphicDto'];
export interface GraphicDto extends GraphicDtoBase {
	//	enforce required fields
	title: string;
	image: string;
	delete: boolean;
}

export type AuthenticationDto = components['schemas']['AuthenticationDto'];
type AuthorityDtoBase = components['schemas']['AuthorityDto'];
export interface AuthorityDto extends AuthorityDtoBase {
	permissions: UserPermissions[];
}
export type CurrentEditorsDto = components['schemas']['CurrentEditorsDto'];
export type EditorNotificationDto = components['schemas']['EditorNotificationDto'];
export type AreaOfInfluenceDto = components['schemas']['AreaOfInfluenceDto'];

export const getEmptyLaneBlockage = (): LaneBlockage => ({
	lanesAffected: [],
	entranceRampAffected: false,
	exitRampAffected: false,
	insideShoulderAffected: false,
	outsideShoulderAffected: false,
});

export enum RecordStatus {
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED',
}

export enum CardinalDir {
	N = 'N',
	E = 'E',
	S = 'S',
	W = 'W',
}

export enum LaneImpactValue {
	'N/A' = 'N/A',
	'SLOW' = 'SLOW',
	'CLOSED' = 'CLOSED',
}

export const getEmptyDraftEvent = (): DraftEvent => ({
	eventType: undefined,
	eventSource: undefined,
	route: undefined,
	startMileMarker: undefined,
	dateStart: Date.now(),
});

//	these have to be manually added to the schema
export enum UserPermissions {
	CCTV_ACCESS = 'CCTV_ACCESS',
	CCTV_CAN_CAST = 'CCTV_CAN_CAST',
	DMS_ACCESS = 'DMS_ACCESS',
	DMS_CAN_MANAGE_CUSTOM_LIBRARY = 'DMS_CAN_MANAGE_CUSTOM_LIBRARY',
	DMS_CAN_MANAGE_SIGN_GROUPS = 'DMS_CAN_MANAGE_SIGN_GROUPS',
	DMS_CAN_MANAGE_SIGN_INVENTORY = 'DMS_CAN_MANAGE_SIGN_INVENTORY',
	REM_ACCESS = 'REM_ACCESS',
	REM_CAN_CREATE_EVENTS_FULL = 'REM_CAN_CREATE_EVENTS_FULL',
	REM_CAN_CREATE_EVENTS_LIMITED = 'REM_CAN_CREATE_EVENTS_LIMITED',
	REM_CAN_DELETE_EVENTS = 'REM_CAN_DELETE_EVENTS',
	REM_CAN_EDIT_EVENTS = 'REM_CAN_EDIT_EVENTS',
}

export enum DMSStatusType {
	'PREVIEW' = 'PREVIEW',
	'PENDING' = 'PENDING',
	'ACTIVE' = 'ACTIVE',
	'ENDED' = 'ENDED',
	'REPLACED' = 'REPLACED',
}

export enum DMSSignType {
	'TTS' = 'TTS',
	'DMS' = 'DMS',
	'TPIMS' = 'TPIMS',
	'VSL' = 'VSL',
	'PDMS' = 'PDMS',
}
